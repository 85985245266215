import React, { Fragment } from 'react'
//import { Accordion } from "react-bootstrap";

//import { toast } from 'react-toastify'
//import StartStopPage from './StartStopPage';
import { RestUrls } from "../../Components/-Helpers-/config";
import './training.scss';
import TraningPage from './TraningPage';
//import ReportsPage from './ReportsPage';

export default function TrainingIndex(props) {

  const {siteInfo} = props;

  //console.log(props);

  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
  const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=2";
  //const m_topPic = RestUrls.pagesPictures + "/register/m_clockTop.jpg?v=1";
  const isMobile = siteInfo.isMobile;

  


  return (
    <div className="DataSection trainingPageIndex">
        <img className="topPic full_width" src={isMobile ? m_topPic : topPic} alt="top"  />

            <Fragment>
                
                <TraningPage />
                
            </Fragment>
        
    </div>
  )
}

