import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import { RestUrls } from "../../../Components/-Helpers-/config";
import loader from "../../../img/preLoader.gif";
import DocsTromSherut from "./DocsTromSherut";
//import { toast } from 'react-toastify'
import { getFromApiSherutLeumi } from "./fileFunctions";
import "./files.scss";
import IshurimDocs from "./IshurimDocs";
import ServiceDocs from "./ServiceDocs";

export default function FilesPage(props) {
  const { siteInfo } = props;

  //console.log(props);

  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
  const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=2";
  const isMobile = siteInfo.isMobile;

  //for open accordion

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(false);
  const [infoUser, setInfoUser] = useState(false);

  const [showIshurim, setShowIshurim] = useState(false);

  let userJ = JSON.parse(localStorage.getItem("userData"));

  if (!loading && !responseData) {
    const sendObj = {
      IDNumber: userJ.IDNO,
      SessionKey: userJ.SessionKey,
    };

    getFromApiSherutLeumi(
      "/v1/volunteer/forms/list",
      sendObj,
      setLoading,
      setResponseData
    );
    //console.log('Loading');
    console.log("CHECK", responseData.data?.Forms);
  }

  if (!loading && !infoUser) {
    const sendObj = {
      IDNumber: userJ.IDNO,
      SessionKey: userJ.SessionKey,
    };

    getFromApiSherutLeumi(
      "/v1/volunteer/info",
      sendObj,
      setLoading,
      setInfoUser
    );
    //console.log('Loading');
  }

  //console.log('responseData >',responseData);
  //console.log('infoUser > ', infoUser )

  const serviceStatus = infoUser?.data?.SeviceStatus;

  //console.log('serviceStatus > ', serviceStatus)

  return (
    <div className="DataSection filesPage">
      <img
        className="topPic full_width"
        src={isMobile ? m_topPic : topPic}
        alt="top"
      />

      <header>
        <h1>העלאת מסמכים</h1>
        {/* <p>תודה שבחרת להתנדב איתנו!</p> */}
      </header>

      <img
        src={loader}
        alt="loader"
        className={
          !loading
            ? "loader"
            : "loader active animate__animated animate__fadeIn"
        }
      />

      {infoUser?.data && (
        <div className="AccordionCont">
          <>
            <Accordion className="accordions acc1" defaultActiveKey="0">
              <Accordion.Item eventKey="01">
                <Accordion.Header>
                  <span>טרום שירות</span>
                </Accordion.Header>

                <Accordion.Body>
                  {responseData?.data?.Forms && (
                    <DocsTromSherut forms={responseData.data.Forms} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* test by tal and saheem */}
            {/* <Accordion className="accordions acc1" defaultActiveKey="0">
              <Accordion.Item eventKey="02">
                <Accordion.Header>
                  <span>מהלך שירות</span>
                </Accordion.Header>

                <Accordion.Body>
                  {responseData?.data?.Forms && (
                    <ServiceDocs forms={responseData.data.Forms} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}

            {/*end test by tal and saheem */}

            {serviceStatus &&
              serviceStatus !== "Searching" &&
              serviceStatus !== "Candidate" &&
              serviceStatus !== "PreService" && (
                <Accordion className="accordions acc1" defaultActiveKey="0">
                  <Accordion.Item eventKey="02">
                    <Accordion.Header>
                      <span>מהלך שירות</span>
                    </Accordion.Header>

                    <Accordion.Body>
                      {responseData?.data?.Forms && (
                        <ServiceDocs forms={responseData.data.Forms} />
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}

            {serviceStatus &&
              serviceStatus !== "Searching" &&
              serviceStatus !== "Candidate" && (
                <Accordion className="accordions acc1" defaultActiveKey="0">
                  <Accordion.Item eventKey="02">
                    <Accordion.Header onClick={() => setShowIshurim(true)}>
                      <span>אישורים</span>
                    </Accordion.Header>

                    <Accordion.Body>
                      {showIshurim && <IshurimDocs infoUser={infoUser.data} />}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
          </>

          {/* <img
            src={loader}
            alt="loader"
            className={
              !this.state.loading
                ? "loader"
                : "loader active animate__animated animate__fadeIn"
            }
          /> */}
        </div>
      )}
    </div>
  );
}
