import { map } from 'lodash';
import React, { Fragment } from 'react'
import { Accordion } from 'react-bootstrap';
//import { toast } from 'react-toastify';

/* OPEN Down */
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import EditMyDay from './EditMyDay';
/* --- OPEN --- */

import xClose from "../../img/icons/clockPage/xClose.svg";
import OpenCLoseTextAreaReport from './OpenCLoseTextAreaReport';
import { checkShowDays } from './clockFunctions';

export default function CardDayReport(props) {


    const {item, editMode,  monthData, reloadData } = props;
    //console.log('item', item);

    /* OPEN Down */
    const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }

    setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (

        

        <Box
            className='boxDownModal'
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            /* onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)} */
        >
            <img className='closeDown' src={xClose} alt='close' onClick={toggleDrawer(anchor, false)} />

            < EditMyDay item={item} closeMe = { toggleDrawer('bottom', false) }  monthData = { monthData } reloadData= { reloadData } />

        </Box>
    );

    const anchor = 'bottom';
    /* END OPEN Down */


    if(!checkShowDays(monthData,item)) {  //NOT SHOW FUTURE DAYS
        return false;
    }

     
  return (
    <div className='CardDayReportItem'>

        {editMode ? <button className={`changeValuesBtn ${item.isOk ? 'green' : 'red'} ${item.reportsCount < 1 && 'noOpen'} ${item.isAbsence ? 'isAbsence' : ''}` }
                        onClick={toggleDrawer(anchor, true)} >

            <div className='headerContReport'>
                <div className='colRight'>
                    <p>{item.title}</p>
                    <CheckStatusText item = {item} />
                </div>
                <div className='leftRight'>
                    <span>דיווח ידני</span>
                </div>
            </div>
        </button> : 
        
        //ShowOnly
        <Accordion defaultActiveKey="0">

            <Accordion.Item eventKey="01">

                <Accordion.Header className={`${item.isOk ? 'green' : 'red'} ${item.reportsCount < 1 && 'noOpen'} ${item.isAbsence ? 'isAbsence' : ''}` }>
                    <div className='headerContReport'>
                        <div className='colRight'>
                            <p>{item.title}</p>
                            <CheckStatusText item = {item} />
                        </div>
                        <div className='leftRight'>
                            <span>צפייה</span>
                        </div>
                    </div>
                    
                </Accordion.Header>

                <Accordion.Body>

                    {map(item.items, dayItem => {

                        return(<DayItem key={ dayItem.id } dayItem= { dayItem } />)

                    })}

                    
                </Accordion.Body>

            </Accordion.Item>

        </Accordion>}

        <Drawer
          className="reportDownModal"
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer('bottom', false)}
        >
          
          {list(anchor)}
        </Drawer>
    </div>



  )
}

function DayItem(props) {  //SHOW ONLY!!!  SHOW ONLY!!!  SHOW ONLY!!!  SHOW ONLY!!!

    const {dayItem} = props

    //console.log('dayItem', dayItem);

    return(
        <Fragment>

            <OpenCLoseTextAreaReport dayItem={dayItem} />

            <ul className='inOutItems'>
                       
                <li>
                    <span className={dayItem?.in ? '' : 'error'} >שעת כניסה</span> 
                    {dayItem?.in ? <p>{dayItem.in}</p> : <p className='error'>--:--</p> }
                </li>
                <li>-</li>
                <li>
                    <span className={dayItem?.out ? '' : 'error'} >שעת יציאה</span> 
                    {dayItem?.out ? <p>{dayItem.out}</p> : <p className='error'>--:--</p> }
                </li>
                
            </ul>

        </Fragment>
        
    );

}

function CheckStatusText(props) {

    const {item} = props;

    if(item.isAbsence) {

        return (<p>קיימת סיבת העדרות: <strong>{item.isAbsence}</strong></p>)

    }

    else if(item.reportsCount < 1) {
        return (<p style={{color: 'red'}}>אין דיווחים</p>)
    }

    else if(!item.isOk) {
        return (<p style={{color: 'red'}}>חסרה כניסה / יציאה</p>)
    }

    else {
        return (<p>כמות דיווחים שבוצעה: <strong>{item.reportsCount}</strong></p>)
    }

}

