import React, { Component } from 'react'
import favorites from "../../../img/sherut-leumi/svg/sherutPlaces/card/favorites_on.svg";

export default class FavoriteButton extends Component {
    render() {

        let activeClass = this.props.is_all ? 'active ' : '';
        let disabledClass = this.props.is_disabled ? '' : ' disabled';

        const showNumber = !this.props.is_all ? `(${this.props.allResults})` : '';

        return (
            <div className="changePage">

                <div className='buttonsCont'>
                    <button className={!this.props.is_all ? 'active' : ''} onClick={() => this.props.showFavoritesOnly(false) } >הצג הכל {showNumber}</button>
                    <button disabled={!this.props.is_disabled} className={ activeClass + disabledClass } onClick={() => this.props.showFavoritesOnly(true) } >
                        <img src={ favorites } alt='מועדפים' />
                        <span>הצגת מועדפים</span>
                    </button>
                </div>

            </div>
        )
    }
}
