import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from 'react-router-dom'; 

export default class ModalDefaul extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalShow : true
        }
    }
    
    modalShow = (value) => {
        
        this.setState({ modalShow : value });

        if(this.props.callBack) {
            this.props.callBack();
        }
        
    }

    render() {

        let params = this.props.params;
        let text = params.text;
        let title = params.title;

        return (
            <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite alertModal ' + this.props.variant }
                animation={false}
                onHide={() => this.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.state.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <Button className="closeBtn" onClick={() => this.modalShow(false)} variant="secondary" >X</Button>

                    <div className="Message">
                        <h2>{title}</h2>
                        <p>{text}</p>

                        {text === 'קיים משתמש עם אותה ת.ז במערכת' ? <div className="singIn errorIsUser" style={{textAlign: 'center'}} >
                            
                            <NavLink
                                className="jumpPage" 
                                role="menuitem" onClick={() => scroll.scrollTo(0)}
                                to={'/login'} >
                                <span>לכניסה למערכת <strong>לחצו כאן</strong></span>
                            </NavLink> <br/>
                            <NavLink
                                className="jumpPage" 
                                role="menuitem" onClick={() => scroll.scrollTo(0)}
                                to={'/reSendPass'} >
                                <span>שחזור סיסמה <strong>לחצו כאן</strong></span>
                            </NavLink> <br/>
                            <br/>
                        </div> : false }

                    </div>

                   


                    <div className="closeBtnCont">
                        <Button className="closeBtnBig" onClick={() => this.modalShow(false)} >סגירה</Button>
                    </div>

                </Modal.Body>
            </Modal>
        )
    }
}
