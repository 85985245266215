import { Grid } from '@material-ui/core';
import React,{ useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import loader from "../../../img/preLoader.gif";

import link from '../../../img/sherut-leumi/svg/files/link.svg';
import { getFromApiSherutLeumi } from './fileFunctions';

export default function OpenPDFfiles(props) {

    const {texts, noContent, setNoContent} = props;

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);  
    
    const [showModal, setShowModal] = useState(false);

    const gotoUrl = () => { 

        console.log('open URL');

        let url = '';

        if(texts?.url) {
          
          url = texts.url;

        } else if (responseData?.data?.Html) {

          url = '#1';

        } else {

          url = '#';

        }

        //const url = texts.url;

      window.open(url,'_blank')

    }

    let userJ = JSON.parse(localStorage.getItem("userData"));

  if(!loading && !responseData && (!texts?.url && texts?.formId) ) {

    const sendObj = {

        idno: userJ.IDNO,
        SessionKey: userJ.SessionKey,
        FormID : texts?.formId
    }

    //console.log(sendObj);
    getFromApiSherutLeumi('/v2/volunteer/data/getDocument', sendObj, setLoading, setResponseData);
    //console.log('Loading');

  }

  //console.log(responseData?.data);

  if(!responseData?.data?.Html && !texts.url) {
    return false;
  } else if(noContent) {
    setNoContent(false);
  }
    
  
  return (
    <>

  <Grid item md={3} >
      <div className='fileItem'>

          <img
                src={loader}
                alt="loader"
                className={
                !loading
                    ? "loader"
                    : "loader active animate__animated animate__fadeIn"
                }
          />
        
          <div className='header' >
              <h3>
                <img src={link} alt='link' />
                <span>{texts.title}</span>
              </h3>
              {/* <p>{texts.filesType}</p> */}
          </div>

          {texts?.url && <div className='blueQuad externalLink fullBorder' >
              <div className='linkP'>(לינק חיצוני)</div>
              <button className='blueBtn' onClick={ gotoUrl } >{texts.btnName}</button>
          </div>}

          {responseData?.data?.Html && <div className='blueQuad externalLink fullBorder' >
              {/* <div className='linkP'>(לינק חיצוני)</div> */}
              <button style={{marginTop: '20px'}} className='blueBtn' onClick={ () => setShowModal(true)  } >לצפייה</button>
          </div>}

      </div>
    </Grid>
    
    
    { responseData?.data?.Html && <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite filesModal modalHtml' }
                animation={false}
                onHide={() => setShowModal(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
            <Modal.Body>

                <Button className="closeBtn" onClick={() => setShowModal(false)} variant="secondary" >X</Button>

                <div className='showHtml' dangerouslySetInnerHTML={{ __html: responseData.data.Html }} />

                <Grid className="btnCont" container spacing={2}  alignItems="center" direction="row" justifyContent="center" >
                
                    <Grid item md={2} >
                        <Button variant="secondary" style={{width: '100%'}} className="closeBtnBig" onClick={() => setShowModal(false)} >סגירה</Button>
                    </Grid>

                </Grid>

            </Modal.Body>
        </Modal> }
    </>
    
  )
}
