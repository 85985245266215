import React, { Component } from "react";

import bulletLev from "./../../img/icons/menuIcons/lev.svg";
import userData from "./../../img/icons/menuIcons/pratim.svg";
import exit from "./../../img/icons/menuIcons/exit.svg";
import mekomotSherut from "./../../img/icons/menuIcons/mekomotSherut.svg";
import doccumentIco from "./../../img/icons/menuIcons/doccumentIco.svg";
import clockInOutIndex from "./../../img/icons/menuIcons/timer.svg";
import contact from "./../../img/icons/menuIcons/envelope.svg";


export default class BulletsMenu extends Component {
  render() {
    const bulletName = this.props.name;

    return (
      <div className="BulletMenuCont">
        {bulletName === "bulletLev" ? (
          <div className="bCountCont">
            <div className="number">{this.props.sayarotNumber}</div>
            <img className="bulletMainMenu" src={bulletLev} alt="בולט" />
          </div>
        ) : (
          false
        )}

        {bulletName === "userData" ? (
          <div className="bCountCont">
            {/* <div className="number">1</div> */}
            <img className="bulletMainMenu" src={userData} alt="בולט" />
          </div>
        ) : (
          false
        )}

      {bulletName === "files" ? (
          <div className="bCountCont">
            {/* <div className="number">1</div> */}
            <img className="bulletMainMenu" src={doccumentIco} alt="בולט" />
          </div>
        ) : (
          false
        )}

        {bulletName === "logOff" ? (
          <div className="bCountCont">
            <img className="bulletMainMenu" src={exit} alt="בולט" />
          </div>
        ) : (
          false
        )}

        {bulletName === "sherutPlaces" ? (
          <div className="bCountCont">
            <img className="bulletMainMenu" src={mekomotSherut} alt="בולט" />
          </div>
        ) : (
          false
        )}

        {bulletName === "clockInOutIndex" ? (
          <div className="bCountCont">
            <img className="bulletMainMenu" src={clockInOutIndex} alt="בולט" />
          </div>
        ) : (
          false
        )}

        {bulletName === "contact" ? (
          <div className="bCountCont">
            <img className="bulletMainMenu" src={contact} alt="בולט" />
          </div>
        ) : (
          false
        )}



      </div>
    );
  }
}
