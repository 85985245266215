import React, { Component } from 'react'

import ContentWrapper from '../../Components/loginPages/ContentWrapper'
//import { RestUrls } from "../../Components/-Helpers-/config";

//import logoPositive from './../../img/sherut-leumi/svg/logoPositive.svg'

export default class UserConsoleIndex extends Component {

    render() {

        //let bgDesktop = RestUrls.pagesPictures;
        /* style={{backgroundImage : "url('"+bgDesktop+"register/bg.jpg')"}} */

        if(this.props.isMobile) {

            return (<div className={`body-container Mobile MekomotSherutIndex`}>
                
                {/* <header>
                    <img src={ logoPositive } alt='האגודה להתנדבות' />
                </header> */}

                {/* <NavMobile  {...this.props} changeSayarot={this.state.changeSayarot} /> */}
                <ContentWrapper {...this.props} />

            </div>)

        }

        else {

            return (<div className="body-container Desktop" >
                <div className="content-wrapper twoColumns">
                        {/* <NavRight {...this.props} changeSayarot={this.state.changeSayarot}  /> */}
                    <div className="mainContent">
                        <ContentWrapper {...this.props} />
                    </div>
                </div>
                
                    
            </div>)

        }
        
    }

}
