import { isEmpty, last, map } from 'lodash';
import React, { useEffect, useState } from 'react'
import { checkShowDays } from './clockFunctions';

import failed from "../../img/icons/clockPage/failed.svg";
import correct from "../../img/icons/clockPage/correct.svg";
import { dateFromString } from './editClockFunctions';

export default function MonthDaysAndConfirmationPanel(props) {

    const {daysMonthData, monthData} = props
    const [daysMonth, setDaysMonth] = useState([]);

    //NoFutureDays
    useEffect(() => {

        let newData = [];
        
        map(daysMonthData, item => {

            if(checkShowDays(monthData,item)) {  //NOT SHOW FUTURE DAYS
                newData.push(item);
            }

        })

        if(daysMonth !== newData ) {

            setDaysMonth(newData);

        }
        
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysMonthData,monthData])
    

  return (
    <div className='monthConfirm'>
        
        {!isEmpty(daysMonth) && <div className='showStartStopMonth'>
            {last(daysMonth)?.title2 && <span>{last(daysMonth).title2}</span>}
            <span>עד</span>
            {daysMonth[0] && <span>{daysMonth[0]?.title2}</span>}
        </div> }

        {monthData?.ReportConfirmationDate && <div className='showStatus'>
            <h3>אישרו את הדו"ח:</h3>
            <ul className='statusCont'>

                <li>
                    <div>
                        <span>מתנדב:</span>
                        {monthData?.ReportConfirmationDate ? <img src={correct } alt='correct' /> : <img src={failed } alt='failed' />}
                        <div className='date'>{dateFromString(monthData?.ReportConfirmationDate)}</div>
                    </div>
                </li>

                <li>
                    <div>
                        <span>מפעיל:</span>
                        {monthData?.ReportConfirmationDateByEmployee ? <img src={correct } alt='correct' /> : <img src={failed } alt='failed' />}
                        <div className='date'>{dateFromString(monthData?.ReportConfirmationDateByEmployee)}</div>
                    </div>
                </li>

                <li>
                    <div>
                        <span>רכז:</span>
                        {monthData?.ReportConfirmationDateByManager ? <img src={correct } alt='correct' /> : <img src={failed } alt='failed' />}
                        <div className='date'>{dateFromString(monthData?.ReportConfirmationDateByManager)}</div>
                    </div>
                </li>
                
            </ul>
        </div> }

    </div>
  )
}
