import React from 'react'
import { RestUrls } from "../../../Components/-Helpers-/config";
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';

export default function AvatarMenuPic() {

    const [ avatarPicture, setAvatarPicture ] = useState(false);

    useEffect(() => {
      
        const user = JSON.parse(localStorage.getItem("userData"));
        getAvatarImage({ idno :  user.IDNO, token: RestUrls.Code },  setAvatarPicture );
        


    }, [])
    
    //console.log('avatarPicture >', avatarPicture);

    if(!avatarPicture?.base64Pic) {

        return (
            <Fragment>
                <DefaultPic />
            </Fragment>
        )

    }

    return (
        <div className='avatarPicture'>

            < img src={ avatarPicture.base64Pic } alt='תמונת אווטאר' />

        </div>
    )


}

function DefaultPic() {

    let bgDesktop = RestUrls.img;

    return(<figure>
        <div
          className="userPic"
          style={{
            backgroundImage:
              "url('" + bgDesktop + "default/noUser.png')",
          }}
        />
      </figure>)

}

export function getAvatarImage(sendObj,setAvatarPicture) {

    
    const json = JSON.stringify(sendObj);
    

    axios.post( RestUrls.baseApiUrl + 'users/getAvatarImage', json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            
            console.log(res.data);
            setAvatarPicture(res.data);
        
        })
        
        .catch((error) => {
            console.log(error)
        })

}