import React, { Fragment, useState } from 'react'
//import { Accordion } from "react-bootstrap";

//import { toast } from 'react-toastify'
import StartStopPage from './StartStopPage';
import { RestUrls } from "../../Components/-Helpers-/config";
import './clockInOut.scss';
import ReportsPage from './ReportsPage';

export default function ClockInOutIndex(props) {

  const {siteInfo} = props;

  //console.log(props);

  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
  const m_topPic = RestUrls.pagesPictures + "/register/m_clockTop.jpg?v=1";
  const isMobile = siteInfo.isMobile;

  
  
  const [selectedPage, setSelectedPage] = useState(false); //'reportsPage'


  return (
    <div className="DataSection clockInOutCont">
        <img className="topPic full_width" src={isMobile ? m_topPic : topPic} alt="top"  />

            <Fragment>
                
                <GetPageClock selectedPage = {selectedPage} setSelectedPage = {setSelectedPage} /> 
                
            </Fragment>
        
    </div>
  )
}

function GetPageClock(props) {

    const { selectedPage, setSelectedPage } = props 


    switch (selectedPage) {

        case 'reportsPage':
            return(<ReportsPage setSelectedPage = {setSelectedPage} />)
    
        default:
            return(<StartStopPage setSelectedPage = {setSelectedPage}  />)
    }

   

}