import React, { Component } from "react";
//import loader from "../../../img/preLoader.gif";

import { Form, FormControl, Button } from "react-bootstrap";
import getDataFromApi from "../../../Components/-Helpers-/api/getDataFromApi";
import MultiSelectReact from "./MultiSelectReact";

//import ShowFilters from "./ShowFilters";

import { getAllUrlParams } from "./../../../Components/-Helpers-/UrlParameters";

export default class SearchUpPlaces extends Component {
  constructor(props) {
    super(props);
    /* function unescapeDoubleQuotes(value) {
      return value.replace(/\\"/g, '"');
    } */
    
    /* function getQueryParam(name) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get(name);
    } */
    
    //const yearParam = getQueryParam("year");
    //console.log(yearParam, "yearParam");

    let urlParams = getAllUrlParams(window.location.search);
    //console.log(urlParams);

    this.state = {
      //allCitiesResponse : false,
      urlParams: urlParams,
      searchDataResp: false,
      loading: false,
      yearChanged: false,
      freeSearch: props?.currentState?.freeSearch
        ? props?.currentState?.freeSearch
        : urlParams && urlParams.freesearch
        ? decodeURI(urlParams.freesearch)
        : "",
      Maslol: props?.currentState?.Maslol
        ? props?.currentState?.Maslol
        : urlParams && urlParams.maslol
        ? decodeURI(urlParams.maslol)
        : "",
      Thum: props?.currentState?.Thum ? props?.currentState?.Thum : "",
      YEAR: props?.currentState?.YEAR
        ? props?.currentState?.YEAR
        : urlParams && urlParams.year
        ? urlParams.year
        : "", //default YEAR
      ZoneName: props?.currentState?.ZoneName
        ? props?.currentState?.ZoneName
        : urlParams && urlParams.zonename
        ? decodeURI(urlParams.zonename)
        : ``, //default YEAR,
      City: props?.currentState?.City
        ? props?.currentState?.City
        : urlParams && urlParams.city
        ? decodeURI(urlParams.city)
        : ``,

      clearMulti: false,

      updateFilters: false,
    };

    //console.log('this.state', this.state);
  }

  handleChange = (event) => {
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeThum = (params) => {
    this.setState({ Thum: params }, () => {
      //console.log(this.state.Thum);
    });
  };

  componentDidMount() {
    //console.log(this.state, "this.state");
    if (!this.state.searchDataResp) {
      this.sendtoAdmin(
        "getMekSherutSearchParams",
        "mekSherut",
        {},
        "searchDataResp",
        "mekSherut"
      );
    }
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, () => {
        //console.log(getData);
        //if(getData.ok) {
        //alert(getData.ok);
        //}
        //console.log(getData?.currentYear);

        this.setState({ YEAR: getData?.currentYear.substring(1) });
      })
    );
  };

  sendForm = () => {
    this.setState({
      updateFilters: Date.now(),
    });

    //console.log(this.state);
    this.props.updateSearch(this.state);

    if (this.props.mobile) {
      this.props.closeSearch();
    }
  };

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, function () {
      //console.log(this.state)
    });

    if (event.target.name === "ZoneName" && event.target.value === "") {
      this.setState({
        City: "",
      });
    }
  };

  searchFilters = (param, isMalol) => {
    if (isMalol) {
      this.setState({
        freeSearch: "",
        Maslol: "",
        Thum: "",
        YEAR: 'תשפ"ג',
        ZoneName: "",
        City: "",
      });
    } else {
      this.setState(
        {
          [param]: "",
        },
        () => {
          this.sendForm();
        }
      );
    }
  };

  clearFields = () => {
    this.setState(
      {
        freeSearch: "",
        Maslol: "",
        Thum: [],
        YEAR: 'תשפ"ג',
        ZoneName: "",
        City: "",
        clearMulti: Date.now(),
      },
      () => {
        this.sendForm();
      }
    );
  };

  render() {
    //console.log(this.state.searchDataResp);
    //console.log('this.state', this.state);

    let flagSend = this.state.Maslol ? true : false;
    return (
      <div className="SearchAndResults">
        <div
          style={{
            backgroundImage: `url("https://sherut-leumi.co.il/wp-content/uploads/2023/05/Group-162537.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="SearchUpCont GreyPanel"
        >
          {/* <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } /> */}

          {this.state.searchDataResp && !this.props.mobile ? (
            <div>
              <div className="line inputs3">
                <Form.Group className="inputsWithLabel selectIco maslulIco iconAfter">
                  <Form.Label style={{ color: "white" }}>מסלול</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={this.handleChangeSelect}
                    name="Maslol"
                    value={this.state.Maslol}
                  >
                    <option value="">בחרו מסלול</option>
                    <option disabled>----</option>

                    {this.state.searchDataResp.allMaslul.map((item) => {
                      if (true) {
                        /* currentState={this.state} */
                        return (
                          <option value={item.Maslol} key={item.Maslol}>
                            {item.Maslol}
                          </option>
                        );
                      } else return false;
                    })}
                  </FormControl>
                </Form.Group>

                <Form.Group className="inputsWithLabel shanaIco iconAfter">
                  <Form.Label style={{ color: "white" }}>שנה</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={(e) => {
                      this.setState({ yearChanged: true });
                      this.handleChangeSelect(e);
                    }}
                    name="YEAR"
                    disabled={this.state.YEAR ? false : true}
                    value={
                      !this.state.yearChanged
                        ? decodeURI(this.state.urlParams.year)
                        : this.state.YEAR
                    }
                  >
                    <option value="">בחרו שנה</option>
                    {/* <option value="">כל השנים</option> */}
                    <option disabled>----</option>
                    <option value='תשפ"ד'>תשפ"ד</option>
                    <option value='תשפ"ה'>תשפ"ה</option>
                  </FormControl>
                </Form.Group>

                <Form.Group className="inputsWithLabel">
                  <Form.Label style={{ color: "white" }}>תחום</Form.Label>
                  <MultiSelectReact
                    currentState={this.state}
                    disabled={this.state.Maslol ? false : true}
                    clearMulti={this.state.clearMulti}
                    options={this.state.searchDataResp.allThum}
                    handleChangeThum={this.handleChangeThum}
                  />
                </Form.Group>
              </div>

              <div className="line inputs4">
                <div className="input3Cont inputs3">
                  <Form.Group className="inputsWithLabel selectIco ezorIco iconAfter">
                    <Form.Label style={{ color: "white" }}>איזור</Form.Label>

                    <FormControl
                      as="select"
                      className="inputsWithLabel selectIcoBG"
                      onChange={this.handleChangeSelect}
                      name="ZoneName"
                      value={this.state.ZoneName}
                      disabled={this.state.Maslol ? false : true}
                    >
                      <option value="">בחרו איזור</option>
                      {/* <option value="">כל האיזורים</option> */}
                      <option disabled>----</option>

                      {this.state.searchDataResp.allCity_Zone.map((item) => {
                        if (true) {
                          /* currentState={this.state} */
                          return (
                            <option value={item.City_Zone} key={item.City_Zone}>
                              {item.City_ZoneName}
                            </option>
                          );
                        } else return false;
                      })}
                    </FormControl>
                  </Form.Group>

                  <Form.Group className="inputsWithLabel placeIco iconAfter">
                    <Form.Label style={{ color: "white" }}>ישוב</Form.Label>

                    <FormControl
                      as="select"
                      className="inputsWithLabel selectIcoBG"
                      onChange={this.handleChangeSelect}
                      name="City"
                      value={this.state.City}
                      disabled={
                        this.state.Maslol && this.state.ZoneName ? false : true
                      }
                    >
                      <option value="">בחרו ישוב</option>
                      {/* <option value="">כל הישובים</option> */}
                      <option disabled>----</option>

                      {this.state.searchDataResp.cities.map((item) => {
                        if (this.state.ZoneName) {
                          //console.log(this.state.ZoneName);
                          //console.log(item.City_ZoneName);

                          if (item.City_Zone === this.state.ZoneName) {
                            return (
                              <option
                                value={item.City_Value}
                                key={item.City_Value}
                              >
                                {item.City_Key}
                              </option>
                            );
                          } else return false;
                        } else {
                          return (
                            <option value="" key={item.City_Value}>
                              {item.City_Key}
                            </option>
                          );
                        }
                      })}
                    </FormControl>
                  </Form.Group>

                  <Form.Group className="inputsWithLabel searchIco">
                    <Form.Label style={{ color: "white" }}>
                      חיפוש חופשי
                    </Form.Label>
                    <FormControl
                      disabled={this.state.Maslol ? false : true}
                      placeholder="לדוגמא: חינוך מיוחד"
                      className="inputsWithLabel"
                      onChange={this.handleChange}
                      name="freeSearch"
                      value={this.state.freeSearch}
                      type="text"
                    />
                  </Form.Group>
                </div>

                <Button
                  disabled={!flagSend ? true : false}
                  className="sendBlueBtn withDisabled"
                  onClick={this.sendForm}
                  size="lg"
                  variant={
                    flagSend
                      ? "success animate__animated animate__bounceIn"
                      : "primary"
                  }
                >
                  מצא לי מקום שירות
                </Button>

                {flagSend ? (
                  <button className="btnClear" onClick={this.clearFields}>
                    ניקוי שדות
                  </button>
                ) : (
                  false
                )}
              </div>
            </div>
          ) : (
            false
          )}

          {/* MOBILE MOBILE MOBILE!!! */}
          {this.state.searchDataResp && this.props.mobile ? (
            <div>
              <div className="line">
                <Form.Group className="inputsWithLabel selectIco maslulIco iconAfter">
                  <Form.Label>מסלול</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={this.handleChangeSelect}
                    name="Maslol"
                    value={this.state.Maslol}
                  >
                    <option value="">בחרו מסלול</option>
                    <option disabled>----</option>

                    {this.state.searchDataResp.allMaslul.map((item) => {
                      if (true) {
                        /* currentState={this.state} */
                        return (
                          <option value={item.Maslol} key={item.Maslol}>
                            {item.Maslol}
                          </option>
                        );
                      } else return false;
                    })}
                  </FormControl>
                </Form.Group>
              </div>

              <div className="line inputs2">
                <Form.Group className="inputsWithLabel shanaIco iconAfter">
                  <Form.Label>שנה</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={this.handleChangeSelect}
                    name="YEAR"
                    disabled={this.state.Maslol ? false : true}
                    value={this.state.YEAR}
                  >
                    <option value="">בחרו שנה</option>
                    {/* <option value="">כל השנים</option> */}
                    <option disabled>----</option>

                    {this.state.searchDataResp.allYEARS.map((item) => {
                      if (true) {
                        /* currentState={this.state} */
                        return (
                          <option value={item.YEAR} key={item.YEAR}>
                            {item.YEAR}
                          </option>
                        );
                      } else return false;
                    })}
                  </FormControl>
                </Form.Group>

                <Form.Group className="inputsWithLabel">
                  <Form.Label>תחום</Form.Label>
                  <MultiSelectReact
                    disabled={this.state.Maslol ? false : true}
                    clearMulti={this.state.clearMulti}
                    options={this.state.searchDataResp.allThum}
                    handleChangeThum={this.handleChangeThum}
                  />
                </Form.Group>
              </div>

              <div className="line inputs2">
                <Form.Group className="inputsWithLabel ezorIco iconAfter">
                  <Form.Label>איזור</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={this.handleChangeSelect}
                    name="ZoneName"
                    value={this.state.ZoneName}
                    disabled={this.state.Maslol ? false : true}
                  >
                    <option value="">בחרו</option>
                    {/* <option value="">כל האיזורים</option> */}
                    <option disabled>----</option>

                    {this.state.searchDataResp.allCity_Zone.map((item) => {
                      if (true) {
                        /* currentState={this.state} */
                        return (
                          <option value={item.City_Zone} key={item.City_Zone}>
                            {item.City_ZoneName}
                          </option>
                        );
                      } else return false;
                    })}
                  </FormControl>
                </Form.Group>

                <Form.Group className="inputsWithLabel placeIco iconAfter">
                  <Form.Label>ישוב</Form.Label>

                  <FormControl
                    as="select"
                    className="inputsWithLabel selectIcoBG"
                    onChange={this.handleChangeSelect}
                    name="City"
                    value={this.state.City}
                    disabled={
                      this.state.Maslol && this.state.ZoneName ? false : true
                    }
                  >
                    <option value="">בחרו</option>
                    {/* <option value="">כל הישובים</option> */}
                    <option disabled>----</option>

                    {this.state.searchDataResp.cities.map((item) => {
                      
                      if (true) {
                        /* currentState={this.state} */
                        return (
                          <option value={item.City_Value} key={item.City_Value}>
                            {item.City_Key}
                          </option>
                        );
                      } else return false;
                    })}
                  </FormControl>
                </Form.Group>
              </div>

              <div className="line">
                <Form.Group className="inputsWithLabel searchIco">
                  <Form.Label>חיפוש חופשי</Form.Label>
                  <FormControl
                    disabled={this.state.Maslol ? false : true}
                    placeholder="לדוגמא: חינוך מיוחד"
                    className="inputsWithLabel"
                    onChange={this.handleChange}
                    name="freeSearch"
                    value={this.state.freeSearch}
                    type="text"
                  />
                </Form.Group>
              </div>

              <Button
                disabled={!flagSend ? true : false}
                className="sendBlueBtn withDisabled"
                onClick={this.sendForm}
                size="lg"
                variant={
                  flagSend
                    ? "success animate__animated animate__bounceIn"
                    : "disabled"
                }
              >
                מצא לי מקום שירות
              </Button>
              {flagSend ? (
                <button className="btnClear" onClick={this.clearFields}>
                  ניקוי שדות
                </button>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}
