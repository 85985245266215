import React, {Fragment, useEffect, useState} from 'react'

import rightArrow from "../../img/icons/clockPage/right-arrow.svg";
import sherut from "../../img/icons/clockPage/sherut.svg";
import sicum from "../../img/icons/clockPage/sicum.svg";

import left from "../../img/icons/clockPage/left.svg";
import right from "../../img/icons/clockPage/right.svg";

import { changeDay, showCurrentMonthName } from './clockFunctions';
import CardDayReport from './CardDayReport';
import { map } from 'lodash';
import { confirmMonth, getMonthData, getSummaryData } from './editClockFunctions';
import loader from "../../img/preLoader.gif";

import { toast } from 'react-toastify';

import MonthDaysAndConfirmationPanel from './MonthDaysAndConfirmationPanel';
import { Accordion } from 'react-bootstrap';

export default function ReportsPage(props) {

    const {setSelectedPage} = props

    const [currentMonth, setCurrentMonth] = useState(parseInt(new Date().getMonth() + 1));
    const [currentYear, setCurrentYear] = useState(parseInt(new Date().getFullYear()));
    const [loading, setLoading] = useState(false);
    const [monthData, setMonthData] = useState(false);
    const [daysMonthData, setDaysMonthData] = useState([]);
    let userJ = JSON.parse(localStorage.getItem("userData"));

    /* loadMonthData */
  useEffect(() => {    
    loadMonthData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth,currentYear])

  function loadMonthData() {

    let month = currentMonth;
    
    if(currentMonth < 10) {
      month = '0' + currentMonth;
    } 

    //console.log(currentYear + month);

    const sendObj = {
      idno: userJ.IDNO,
      SessionKey: userJ.SessionKey,
      Date: `${currentYear}${month}`
    }

    //console.log('sendObj', sendObj);

    getMonthData('v2/volunteer/Maakav', sendObj, setLoading,setMonthData,setDaysMonthData);

  }

    const isActiveMonth = changeDay('checkActive',currentMonth, setCurrentMonth, currentYear, setCurrentYear);
    const isEditMode = changeDay('checkEditMode',currentMonth, setCurrentMonth, currentYear, setCurrentYear,monthData);


  const reloadData = () => { 

    loadMonthData();
    //console.log('reloadData');

  }

  const [actualSection, setActualSection] = useState(false);

  const confirmMyMonth = (second) => { 
    console.log(monthData, "monthData");

    // Check if 'original' is defined in monthData
    if (monthData['original'] !== undefined) {
        // Iterate over each key in the 'original' object
        for (const key in monthData['original']) {
            if (monthData['original'].hasOwnProperty(key)) {
                const report = monthData['original'][key];

                const sendObj = {
                    SessionKey: userJ.SessionKey,
                    idno: userJ.IDNO,
                    Year: report.Year,      
                    Month: report.Month,
                    ReportID: report.ReportID,
                    // Include other properties from 'report' if needed
                };
    
                // Call confirmMonth for each report
                confirmMonth('v2/volunteer/maakavconfirm', sendObj, setLoading, loadMonthData);
            }
        }
    } else {
        // 'original' is not defined, use monthData directly
        const sendObj = {
            SessionKey: userJ.SessionKey,
            idno: userJ.IDNO,
            Year: monthData.Year,      
            Month: monthData.Month,
            ReportID: monthData.ReportID,
            // Add other properties from monthData if needed
        };

        // Call confirmMonth with the original monthData
        confirmMonth('v2/volunteer/maakavconfirm', sendObj, setLoading, loadMonthData);
    }
}


  return (
    <div className={`ReportsPage ${isEditMode ? 'editModePage' : ''} animate__animated animate__fadeIn`}>
        
        <img  src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        <header className='clear'>
          <h1>דיווחי נוכחות</h1>
          {/* <p>תודה שבחרת להתנדב איתנו!</p> */}
          
          <button className='backBtn' onClick={ () => setSelectedPage('index') }>
              <img src={rightArrow} alt='rightArrow' />
          </button>

        </header>

        {/* BUTTONS no se sabe que hacen*/}
        <div className='headerReportsPage'>
            <button  onClick={()=>setActualSection('summary')}>
                <img src={sicum} alt='sicum' />
                <span>סיכום</span>
            </button>
            <button  onClick={()=>toast.error('אין API')}>
                <img src={sherut} alt='sherut' />
                <span>השירות שלי</span>
            </button>
        </div>

        {/* חודשים */}
        { !actualSection && <Fragment>

          <nav className='monthsNav'>

              <button onClick={()=> changeDay('back',currentMonth, setCurrentMonth, currentYear, setCurrentYear) }>
                <img src={right} alt='right' style={{marginLeft : '10px'}} />
                <span>הקודם</span>
              </button>

              <p>{showCurrentMonthName(currentMonth,currentYear)}</p>

              <button className={`${ isActiveMonth && 'disabled' }`} onClick={()=> changeDay('forward',currentMonth, setCurrentMonth, currentYear, setCurrentYear) } >
                <span>הבא</span>
                <img src={left} alt='left' style={{marginRight : '10px'}} />
              </button>

          </nav>

          <MonthDaysAndConfirmationPanel daysMonthData={daysMonthData} monthData = {monthData} />
          
          { (monthData && daysMonthData)  && <div className='cardsReportCont'>

            {map(daysMonthData, item => {

              return(<CardDayReport key={ item.id } item= { item }  reloadData = { reloadData } monthData = {monthData} editMode = {isEditMode} />)

            })}
              

          </div> }

          {isEditMode && <div className='confirmation'>

              <button onClick={ confirmMyMonth }>אישור חודש {showCurrentMonthName(currentMonth,currentYear,'monthNameOnly')}</button>  

          </div>}
        </Fragment> }
        
        {actualSection === 'summary' && < Summary setActualSection = {setActualSection} />}
    </div>
  )
}


function Summary(props) {

  const { setActualSection } = props

  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(false);
  
  useEffect(() => {    

    let userJ = JSON.parse(localStorage.getItem("userData"));

    const sendObj = {IDNumber: userJ.IDNO,SessionKey: userJ.SessionKey}
    getSummaryData('v1/volunteer/service/summary', sendObj, setLoading,setSummaryData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('summaryData >', summaryData)


  return (
    <div className='Summary animate__animated animate__fadeIn'>

    <img  src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />
      
      <h2>סיכום נוכחות</h2>

      {summaryData && <div className='contSummary animate__animated animate__fadeIn'>

        {map(summaryData?.Years, item => {

          return(<MyAccordiondata item={item} key= {item.Year} />)

        })}


        <button className="backBtn" onClick={ ()=>setActualSection(false) }>
            <span>אישור</span>
        </button>

      </div> }
      

    </div>
  )

}


function MyAccordiondata(props) {
  const { item } = props
  return(
    <Accordion defaultActiveKey="0" className={`openCLoseSumm`}>
      <Accordion.Item eventKey="01">
          <Accordion.Header >
              <span>שנה {changeNumber(item.Year)}</span>
          </Accordion.Header>
          <Accordion.Body>
              <ul>
                <li>
                  <h3>ימי חופשה</h3>
                  <p>עד כה ניצלת {item.UsedDaysOfVacation} ימים מתוך {item.GivenDaysOfVacation} ימי חופשה אותם צברת.</p>
                  {/* <p>לזכותך נספרים {item.UsedDaysOfVacation} ימי השלמה/הכשרה</p> */}
                  {parseFloat(item.VacationSummary) > 0 ? <p>*סה"כ עומדים לזכותך: <strong>{item.VacationSummary}</strong> ימי חופש</p> :
                  <p>*סה"כ ימים בחוסר (לכל תקופת השירות):  <strong style={{color: 'red'}}>{item.VacationSummary.replace('-','')}</strong></p> }
                </li>

                {(parseFloat(item.ConfirmedSickDays) > 0 || parseFloat(item.UnconfirmedSickDays) > 0) && <li>
                  <h3>ימי מחלה</h3>
                  <p>עד כה היו לך {item.ConfirmedSickDays} ימי מחלה מאושרים</p>
                  {parseFloat(item.UnconfirmedSickDays) > 0 && <p style={{marginBottom: '0'}}>כמו כן חסרים לך אישורים על {item.UnconfirmedSickDays} ימי מחלה</p>}

                  <p><strong>ללא אישורים, תאריכים אלו יחשבו כימי חופש</strong></p>
                  
                </li>}
              </ul>
          </Accordion.Body>

      </Accordion.Item>

    </Accordion>
  )
  
  function changeNumber(number) {

      switch (number) {
        
        case '1':
          return "א'"  

        case '2':
            return "ב'"  

        case '3':
          return "ג'"  
      
        default:
          return number
      }

  }

}