import React, { Component } from "react";
import { RestUrls } from "../../../Components/-Helpers-/config";

import rakaz from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/1.svg";
import phone from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/2.svg";
import whatsapp from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/3.svg";
import shituf from "./../../../img/sherut-leumi/svg/sherutPlaces/card/moreInfo/4.svg";

//import { CheckTekenName } from "./checkTekenName.js";

//npm install react-share --save
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export default class ShowMoreData extends Component {
  render() {
    const item = this.props.item;
    let urlShare = RestUrls.appUrl + "/sherutPlaces?id=" + item.id;

    //let checkTekenName = CheckTekenName(item);

    //let textShare = "תבדקו את הסיירת שמצאתי...";

    let textShare = item.MOSADNA + " - האגודה להתנדבות";

    //console.log(urlShare);
    //console.log(item);

    return (
      <div>
        <h3>פרטים נוספים:</h3>

        <div className="moreDataPlaces">
          {item.MAS_NOTES ? (
            <article>
              <h4>תאור המוסד:</h4>
              <p>{item.MAS_NOTES}</p>
            </article>
          ) : (
            false
          )}

          {item.TAFKID ? (
            <article>
              <h4>הגדרות תפקיד:</h4>
              <p>{item.TAFKID}</p>
            </article>
          ) : (
            false
          )}
        </div>

        <ul className="iconsLine fullLine">
          <li>
            <img src={rakaz} alt="" />
            <span>רכז/ת: </span> <span>{item.Rak_Key}</span>
            {parseInt(item.sayarotRakazCount) > 0 ? (
              <a
                href={"/userConsole/?rakazId=" + item.Rak_Value}
                target="_blank"
                className="goSayarot bounce3"
                rel="noopener noreferrer"
              >
                צפו בסיירות שלי &raquo;
              </a>
            ) : (
              false
            )}
          </li>

          {item.Rak_Phone ? (
            <li>
              {this.props.isMobile ? (
                <div>
                  <a href={"tel:" + item.Rak_Phone}>
                    <img src={phone} alt="" />
                    <span>{item.Rak_Phone}</span>
                  </a>
                </div>
              ) : (
                <div>
                  <img src={phone} alt="" />
                  <span>{item.Rak_Phone}</span>
                </div>
              )}
            </li>
          ) : (
            false
          )}

          {item.Rak_Phone.startsWith("02") ||
          item.Rak_Phone.startsWith("03") ||
          item.Rak_Phone.startsWith("04") ||
          item.Rak_Phone.startsWith("08") ||
          item.Rak_Phone.startsWith("09") ||
          item.Rak_Phone.startsWith("072") ||
          item.Rak_Phone.startsWith("073") ||
          item.Rak_Phone.startsWith("074") ||
          item.Rak_Phone.startsWith("076") ||
          item.Rak_Phone.startsWith("077") ||
          item.Rak_Phone.startsWith("078") ? (
            false
          ) : item.Rak_Phone ? (
            <li>
              <a
                href={
                  "https://api.whatsapp.com/send?phone=972" + item.Rak_Phone.replace("-", "")
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={whatsapp} alt="" />
                <span>שיחה עם הרכז/ת</span>
              </a>
            </li>
          ) : (
            false
          )}

          <li>
            <img src={shituf} alt="" />
            <span>שיתוף</span>

            <div className="shares">
              {/* https://www.npmjs.com/package/react-share */}

              <EmailShareButton url={urlShare} subject={textShare}>
                <EmailIcon size={32} round={true} />
              </EmailShareButton>

              <WhatsappShareButton
                url={urlShare}
                title={textShare}
                windowWidth={1200}
                windowHeight={800}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>

              <TelegramShareButton url={urlShare} title={textShare}>
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>

              <TwitterShareButton url={urlShare} title={textShare}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>

              <FacebookShareButton
                url={encodeURI(urlShare)}
                quote={this.props.item.MAS_NOTES}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
            </div>
          </li>
        </ul>

        {/* <ul className="iconsLine allLine" >

                    <li>
                        <img src={pratim} alt='' />
                        <span>פרטים לסיירת: </span> <span>{item.Mosadot ? item.Mosadot : '---'}</span>
                    </li>

                </ul> */}
      </div>
    );
  }
}
