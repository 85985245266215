import React, { Component } from 'react'
import SmallForm from './SmallForm'
import ForgotPassword from './ForgotPassword';

import Login from './Login'
import LoginQueryUrl from './LoginQueryUrl';

export default class RegisterIndex extends Component {

    render() {

        const page = this.props.page;

        return (
            <div className="clear">
                
                {page === 'register' ? <SmallForm { ...this.props } /> : false }

                {page === 'reSendPass' ? <ForgotPassword { ...this.props } /> : false }

                {page === 'login' ? <Login { ...this.props } /> : false }

                {page === 'loginQueryUrl' ? <LoginQueryUrl { ...this.props } /> : false }


                

            </div>
        )
    }
}
