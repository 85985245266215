import React, { Component } from 'react'

export default class HomePage extends Component {
    render() {
        return (
            <div>
                {console.log(this.props)}
                HomePage
            </div>
        )
    }
}
