import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';

import warning from '../../../img/sherut-leumi/svg/search/warning.svg'

export default class ModalDeleteSayeret extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalShow : true
        }
    }
    
    modalShow = (value, param) => {
        
        //this.setState({ modalShow : value });

        this.props.modalShow(value);

        if(this.props.callBack) {
            this.props.callBack(param, this.props.item.id);
        }
        
    }

    render() {

        let item = this.props.item;

        return (
            <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite deleteSayeretModal' }
                animation={false}
                onHide={() => this.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                //size="sm" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.state.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <img className="warningPic" src={warning} alt="warning" />

                    <Button className="closeBtn" onClick={() => this.modalShow(false,false)} variant="secondary" >X</Button>

                    <div className="Message">
                        <h2>האם להסיר מרשימת הסיירות את:</h2>
                        <p>{item.Name ? <span>{ item.Name }</span> : <span>{item.id}</span> }?</p>

                    </div>

                    <div className="buttons">
                        <Button onClick={() => this.modalShow(false, true)} variant="secondary" >כן</Button>
                        <Button onClick={() => this.modalShow(false, false)} variant="primary" >לא</Button>
                    </div>


                </Modal.Body>
            </Modal>
        )
    }
}
