import React, { Component } from 'react'
import SearchResultRow from './SearchResultRow';

export default class SearchResults extends Component {

    render() {

        let count = 0;

        return (

            <div>

                {this.props.sayarotData ? <div className="SearchResults">

                    {this.props.sayarotData.map(item => { if (true) {

                        //count++;
                        //if(count > 16) {count = 1;}

                        return  <SearchResultRow  activeSayeretId={this.props.activeSayeretId} changeMenuright={(id)=>this.props.changeMenuright(id)} item={item} key={item.id} picChangeName = {count} />
                    } else return false}) }
                    
                </div> : <h2 className="noResults" >שגיאה</h2> }

            </div>
        )

    }
}
