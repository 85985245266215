import React, { Component } from "react";

//https://www.npmjs.com/package/react-text-truncate
//import TextTruncate from 'react-text-truncate';

//import place from "./../../../img/sherut-leumi/svg/search/resultsIcons/place.svg";
import date from "./../../../img/sherut-leumi/svg/search/resultsIcons/date.svg";
import rakaz from "./../../../img/sherut-leumi/svg/search/resultsIcons/rakaz.svg";
import mekomot from "./../../../img/sherut-leumi/svg/search/resultsIcons/mekomot.svg";
import homeOnly from "./../../../img/sherut-leumi/svg/search/resultsIcons/homeOnly.svg";

export default class ShowDataInRow extends Component {
  render() {
    let item = this.props.item;

    //console.log(item);

    return (
      <div className="ShowDataInRow">
        <header>
          {item.Name ? <h2>{item.Name}</h2> : <h2>#{item.id}</h2>}
          {item.City_Value ? <p>{item.City_Value} </p> : <p></p>}
          {/* {item.Info ? <TextTruncate
                        line={2}
                        element="p"
                        truncateText="…"
                        text={item.Info}
                    /> : <p></p> } */}
        </header>

        <ul className="iconsLine">
          {/* <li>
                        <img src={place} alt='' />
                        <span>מיקום: </span> <span>{item.City_Value}</span>
                    </li> */}

          {item.SyrDayDate !== "---" ? (
            <li>
              <img src={date} alt="" />
              <span>תאריך: </span> <span>{item.SyrDayDate}</span>
            </li>
          ) : (
            false
          )}

          <li>
            <img src={rakaz} alt="" />
            <span>רכז/ת: </span> <span>{item.Rak_Value}</span>
          </li>

          {item.LimMtn !== "901" ? (
            <li>
              <img src={mekomot} alt="" />
              <span>מקומות שנותרו: </span> <span>{item.placesLeft}</span>
            </li>
          ) : (
            false
          )}

          {item.LimMtn === "901" ? (
            <li>
              <img src={mekomot} alt="" />
              <span>רשימת המתנה</span>
            </li>
          ) : (
            false
          )}

          {item.HomeOnly === "1" ? (
            <li>
              <img src={homeOnly} alt="" />
              <span>ללא דירת שרות</span>
            </li>
          ) : (
            false
          )}
        </ul>
      </div>
    );
  }
}
