import React from 'react'
import { RestUrls } from "../../../Components/-Helpers-/config";

import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';

export default function AvatarPratimMobilePic() {

    const [ avatarPicture, setAvatarPicture ] = useState(false);

    useEffect(() => {
      
        const user = JSON.parse(localStorage.getItem("userData"));
        getAvatarImage({ idno :  user.IDNO, token: RestUrls.Code },  setAvatarPicture );

    }, [])
    
    //console.log('avatarPicture >', avatarPicture);

    if(!avatarPicture?.base64Pic) {return false;}

    return (
        <div className='mobileAvatarPicture'>
            < img src={ avatarPicture.base64Pic } alt='תמונת אווטאר' />
        </div>
    )


}


export function getAvatarImage(sendObj,setAvatarPicture) {

    
    const json = JSON.stringify(sendObj);
    

    axios.post( RestUrls.baseApiUrl + 'users/getAvatarImage', json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            
            console.log(res.data);
            setAvatarPicture(res.data);
        
        })
        
        .catch((error) => {
            console.log(error)
        })

}