import React, { Component } from 'react'
import { RestUrls } from "../../Components/-Helpers-/config";

import loader from "../../img/preLoader.gif";
import getDataFromApi from '../../Components/-Helpers-/api/getDataFromApi';
import {getAllUrlParams} from "./../../Components/-Helpers-/UrlParameters";

export default class LoginQueryUrl extends Component {

    constructor(props) {
        super(props);

        let urlParams = getAllUrlParams(window.location.search);

    
        this.state = {
            urlParams : urlParams ? urlParams : false,
            loading: false,
            responseLogin : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        console.log('sending...');

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);
        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false,
        
        }, function(){

            this.setState({loading : false});

            if(getData.ok) {
                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/userConsole','_self');
            } else {
                localStorage.setItem('userData','');
                window.open('/login','_self');
            }
            
            //console.log(getData);

            /* if(url === 'reportBuilder') {
                
            } */
        }))
    }
    
    componentDidMount() {

        let urlParams = this.state.urlParams;

        if( urlParams && urlParams.idno && urlParams.sessionkey ) {

            console.log('ok');

            let sendObj = {
                idno : urlParams.idno,
                sessionkey : urlParams.sessionkey
            }

            this.sendtoAdmin('LoginQueryUrl','register',sendObj,'responseLogin','register');

        } else {

            //console.log('false');
            localStorage.setItem('userData','');
            window.open('/login','_self');
            

        }

    }
    

    render() {

        const isMobile = this.props.siteInfo.isMobile ? this.props.siteInfo.isMobile : false;
        let m_picTop = RestUrls.pagesPictures + 'register/m_registerTop.jpg?v=3';



        console.log(this.state.urlParams);


        return (
            <div className="firstRegister loginPage animate__animated animate__fadeIn">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                { isMobile ? <img className='topPic' src={m_picTop} alt="top" /> : false }

                <header style={{ padding: '50px 0 ' }}>
                    <h1 className="boldTypeFamily">מתחברים...</h1>
                    {/* <p>ברוכים השבים לאגודה להתנדבות</p> */}
                </header>

            </div>
        )
    }
}
