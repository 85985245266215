import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import NavMobileMenuContent from "./NavMobileMenuContent";

import m_Open from "../../img/sherut-leumi/svg/m_openMenu.svg";

export default function TemporaryDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <NavMobileMenuContent allProps={props} />
    </Box>
  );

  const anchor = "right";

  //console.log(props);

  return (
    <React.Fragment key={anchor}>
      {window.location.origin !== "https://sherut-leumi-app.vercel.app" && (
        <Button className="mobileOpenBtn" onClick={toggleDrawer(anchor, true)}>
          <img src={m_Open} alt="לפתוח תפריט" />
        </Button>
      )}
      <Drawer
        className="mobileMenu"
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer("right", false)}
      >
        {list(anchor)}
      </Drawer>
    </React.Fragment>
  );
}
