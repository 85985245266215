import React, { Fragment, useState } from "react";
import { RestUrls } from "../../Components/-Helpers-/config";
import { useForm } from "react-hook-form";
//import axios from "axios";
function ContactUs(props) {
  const { siteInfo } = props;
  const [sending, setSending] = useState(false);
  const [sent, ] = useState(false); //setSent
  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors },
  } = useForm();
/*   const sendMail = async (data) => {
    try {
      const sendMailResponse = await axios.post(
        "https://sherut-leumi.wdev.co.il/api/register/sendMyMail?sebas=1",
        {
          fullName: data.fullName,
          idNumber: data.idNumber,
          email: data.email,
          telephone: data.telephone,
          subject: data.subject,
          content: data.content,
        }
      );
      return sendMailResponse;
    } catch (error) {
      console.log(error);
    }
  };
  const webhook = async (data) => {
    try {
      const webHookResponse = await axios.post(
        "https://webhooks.wdev.co.il/api/webhook-sherut-leumi-app",
        {
          ...data,
        }
      );
      console.log(webHookResponse, "webHookResponse");
      return webHookResponse;
    } catch (error) {
      console.log(error);
    }
  }; */
  const onSubmit = async (data) => {
    console.log(data, "onSubmit");

    setSending(true);

    // try {
    //   await sendMail(data);
    //   await webhook(data);
    //   setSending(false);
    //   setSent(true);
    // } catch (error) {
    //   setSending(false);
    //   alert("משהו השתבש");
    //   console.log(error);
    // }

    console.log(data);
  };

  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
  const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=2";
  //const m_topPic = RestUrls.pagesPictures + "/register/m_clockTop.jpg?v=1";
  const isMobile = siteInfo.isMobile;
  function isIsraeliIdNumber(id) {
    id = String(id).trim();
    if (id.length > 9 || isNaN(id)) return false;
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
    return (
      Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) %
        10 ===
      0
    );
  }
  return (
    <div className="DataSection trainingPageIndex">
      <img
        className="topPic full_width"
        src={isMobile ? m_topPic : topPic}
        alt="top"
      />

      <div
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        {!sent && (
          <form
            style={{ maxWidth: "713px", margin: "0 auto" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className="px-5 mt-5"
              style={{ textAlign: "right", width: "100%" }}
            >
              <div>
                <h1
                  style={{
                    textAlign: "right",
                    fontWeight: "700",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  צרו קשר
                </h1>
                <h5 style={{ margin: "10px 0", padding: "0" }}>
                  מלאו את הפרטים ונציג מטעמנו יחזור אליכם בהקדם
                </h5>
                <br />
              </div>
            </div>
            {/* register your input into the hook by invoking the "register" function */}
            <div className="row px-5">
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="fullName">שם מלא</label>
                <input
                  disabled={sending}
                  {...register("fullName", {
                    required: "נא להזין שם מלא",
                  })}
                  type="text"
                  className="form-control"
                  id="fullName"
                  aria-describedby="emailHelp"
                />
                {errors && errors.fullName && (
                  <p className="text-danger">{errors.fullName.message}</p>
                )}
              </div>
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="idNumber">תעודת זהות</label>
                <input
                  disabled={sending}
                  {...register("idNumber", {
                    required: "נא להזין תעודת זהות",
                    validate: (value) => isIsraeliIdNumber(value),
                  })}
                  type="text"
                  className="form-control"
                  id="idNumber"
                  aria-describedby="emailHelp"
                />
                {errors && errors.idNumber && (
                  <p className="text-danger">{errors?.idNumber.message}</p>
                )}
              </div>
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="email">איימיל</label>
                <input
                  disabled={sending}
                  {...register("email", {
                    // required: "נא להזין כתובת אימייל",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                />
                {errors.email && (
                  <p className="text-danger">{errors.email.message}</p>
                )}
              </div>
              <div className="col-md-6 col-sm-12 my-1">
                <label htmlFor="telephone">טלפון / נייד</label>
                <input
                  disabled={sending}
                  {...register("telephone", {
                    required: "נא להזין מספר טלפון",
                    pattern: {
                      value: /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/,
                      message: "מספר טלפון לא תקין",
                    },
                  })}
                  type="text"
                  className="form-control"
                  id="telephone"
                  aria-describedby="emailHelp"
                />
                {errors && errors.telephone && (
                  <p className="text-danger">{errors.telephone.message}</p>
                )}
              </div>
              <div className="form-group">
                <div className="col-md-12 col-sm-12 my-1">
                  <label htmlFor="subject">נושא</label>
                  <select
                    disabled={sending}
                    {...register("subject", {
                      required: "נא לבחור נושא",
                    })}
                    className="form-control"
                    id="subject"
                  >
                    <option value={null}></option>

                    <option value={`אישורי שרות`}>אישורי שרות</option>
                    <option value={`דמי כיס`}>דמי כיס</option>
                    <option value={`חיפוש מקום שרות שנה נוכחית`}>
                      חיפוש מקום שרות שנה נוכחית
                    </option>
                    <option value={`חיפוש מקום שרות שנה הבאה`}>
                      חיפוש מקום שרות שנה הבאה
                    </option>
                    <option value={`טפסים`}>טפסים</option>
                    <option value={`כללי`}>כללי</option>
                  </select>
                  {errors && errors.subject && (
                    <p className="text-danger">{errors.subject.message}</p>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-sm-12 my-1">
                <label htmlFor="content">תוכן הפניה</label>
                <textarea
                  disabled={sending}
                  {...register("content")}
                  type="email"
                  className="form-control"
                  id="content"
                  aria-describedby="emailHelp"
                />
              </div>
              <div>
                <br />
                <button
                  disabled={sending}
                  style={{ backgroundColor: "#1991d0" }}
                  className="btn btn-success w-100"
                  type="submit"
                >
                  {sending ? "שולח" : "צור איתנו קשר"}
                </button>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </form>
        )}
        {sent && (
          <>
            <div
              style={{ display: "grid", placeItems: "center" }}
              className="mt-3"
            >
              <h1>הפנייה נשלחה בהצלחה</h1>
              <h1>ניצור איתך קשר בהקדם האפשרי</h1>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="368.756"
              height="377.514"
              viewBox="0 0 368.756 377.514"
            >
              <g
                id="Group_162071"
                data-name="Group 162071"
                transform="translate(-135.127 -55.003)"
              >
                <g id="Clouds" transform="translate(135.127 55.003)">
                  <g id="Group_221" data-name="Group 221">
                    <path
                      id="Cloud"
                      d="M175.912,62.357l13.463,7.773a16.479,16.479,0,0,1-.113-1.781v-3.9c0-6.941,4.874-9.755,10.884-6.284a21.06,21.06,0,0,1,6.487,6.239c.984-8.728,7.832-11.98,16.149-7.179,8.994,5.193,16.283,17.82,16.283,28.2v5.835a15.373,15.373,0,0,1-1.378,6.759l11.183,6.458a13.341,13.341,0,0,1,6.037,10.457c0,3.85-2.7,5.41-6.037,3.485L175.912,76.3a13.343,13.343,0,0,1-6.039-10.457C169.873,61.993,172.576,60.432,175.912,62.357Z"
                      transform="translate(-115.544 -55.003)"
                      fill="#ebebeb"
                    ></path>
                    <path
                      id="Cloud-2"
                      data-name="Cloud"
                      d="M139.554,83.831l9.87,5.7a12.025,12.025,0,0,1-.083-1.307v-2.86c0-5.088,3.573-7.15,7.979-4.606a15.425,15.425,0,0,1,4.756,4.574c.721-6.4,5.742-8.783,11.838-5.262,6.594,3.806,11.938,13.062,11.938,20.676v4.278a11.263,11.263,0,0,1-1.01,4.955l8.2,4.733a9.785,9.785,0,0,1,4.425,7.666c0,2.822-1.981,3.967-4.425,2.556L139.554,94.052a9.782,9.782,0,0,1-4.427-7.666C135.127,83.564,137.11,82.421,139.554,83.831Z"
                      transform="translate(-135.127 -41.793)"
                      fill="#ebebeb"
                    ></path>
                  </g>
                </g>
                <g id="Message" transform="translate(236.196 87.173)">
                  <path
                    id="Path_10147"
                    data-name="Path 10147"
                    d="M173.547,224.941,382.1,104.532l8.345,4.817a8.7,8.7,0,0,1,3.923,6.8V269.993a8.706,8.706,0,0,1-3.923,6.8L189.738,392.667a8.7,8.7,0,0,1-7.846,0l-4.422-2.553a8.7,8.7,0,0,1-3.923-6.8Z"
                    transform="translate(-173.542 -48.258)"
                    fill="#a0b7d5"
                  ></path>
                  <path
                    id="Path_10148"
                    data-name="Path 10148"
                    d="M282.856,74.931a5.233,5.233,0,0,0-1.063,1.126L179.111,225.991a15.956,15.956,0,0,0-1.906,4.284l-3.19-1.845a20.478,20.478,0,0,1,.827-2.267c.017-.031.017-.047.033-.063.141-.313.28-.61.438-.907.03-.047.045-.094.078-.141a8.181,8.181,0,0,1,.546-.907L278.6,74.212a4.856,4.856,0,0,1,.516-.625c.172-.172.36-.344.546-.5Z"
                    transform="translate(-173.279 -65.981)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10149"
                    data-name="Path 10149"
                    d="M343.423,108.773a5.221,5.221,0,0,0-.876-.421l-91.8-34.024a6.294,6.294,0,0,0-5.973,1.126l-3.19-1.845a6.365,6.365,0,0,1,5.989-1.126l91.784,34.008a4.827,4.827,0,0,1,.862.422Z"
                    transform="translate(-135.196 -66.504)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10150"
                    data-name="Path 10150"
                    d="M280.905,75.91,178.231,225.85a17.758,17.758,0,0,0-2.649,8.561v9.957L384.141,123.95l0-9.949a7.23,7.23,0,0,0-4.4-6.32L287.954,73.669A6.318,6.318,0,0,0,280.905,75.91Z"
                    transform="translate(-172.395 -65.839)"
                    fill="#08418b"
                  ></path>
                  <path
                    id="Path_10151"
                    data-name="Path 10151"
                    d="M193.114,355.43l-6.683-3.868h-.016l-.067-.05v-.017a4.057,4.057,0,0,1-1.609-3.651V139.4a12.017,12.017,0,0,1,1.876-5.962l6.8,3.918a11.863,11.863,0,0,0-1.876,5.979V351.779A4.122,4.122,0,0,0,193.114,355.43Z"
                    transform="translate(-167.234 -31.966)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10152"
                    data-name="Path 10152"
                    d="M194.844,168.545,362.359,71.839c3.173-1.833,5.746-.347,5.746,3.316V283.594a12.7,12.7,0,0,1-5.746,9.951L194.847,390.252c-3.174,1.831-5.746.346-5.746-3.318l0-208.437A12.7,12.7,0,0,1,194.844,168.545Z"
                    transform="translate(-164.777 -67.127)"
                    fill="#f0f0f0"
                  ></path>
                  <path
                    id="Path_10153"
                    data-name="Path 10153"
                    d="M361.412,68.938l6.566,3.8a4.08,4.08,0,0,0-3.843.549L196.612,169.968a11.09,11.09,0,0,0-3.873,3.964l-6.8-3.918a11.494,11.494,0,0,1,3.868-3.987l167.517-96.7c1.607-.921,3.049-1,4.087-.367Z"
                    transform="translate(-166.558 -68.542)"
                    fill="#fafafa"
                  ></path>
                  <path
                    id="Path_10154"
                    data-name="Path 10154"
                    d="M202.346,169.551,341.659,89.117c1.87-1.079,3.385-.313,3.385,1.714a7.265,7.265,0,0,1-3.387,5.621L202.346,176.884c-1.87,1.079-3.385.313-3.385-1.712A7.257,7.257,0,0,1,202.346,169.551Z"
                    transform="translate(-159.219 -57.221)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10155"
                    data-name="Path 10155"
                    d="M202.346,178.9,341.659,98.467c1.87-1.08,3.385-.313,3.385,1.712a7.263,7.263,0,0,1-3.387,5.621L202.346,186.233c-1.87,1.079-3.385.313-3.385-1.712A7.257,7.257,0,0,1,202.346,178.9Z"
                    transform="translate(-159.219 -51.952)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10156"
                    data-name="Path 10156"
                    d="M202.346,188.248l139.313-80.432c1.87-1.08,3.385-.313,3.385,1.712a7.263,7.263,0,0,1-3.387,5.621L202.346,195.582c-1.87,1.08-3.385.313-3.385-1.712A7.257,7.257,0,0,1,202.346,188.248Z"
                    transform="translate(-159.219 -46.683)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10157"
                    data-name="Path 10157"
                    d="M202.346,197.6l139.313-80.432c1.87-1.08,3.385-.313,3.385,1.712a7.263,7.263,0,0,1-3.387,5.621L202.346,204.931c-1.87,1.08-3.385.313-3.385-1.712A7.26,7.26,0,0,1,202.346,197.6Z"
                    transform="translate(-159.219 -41.414)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10158"
                    data-name="Path 10158"
                    d="M202.346,206.946l139.313-80.432c1.87-1.079,3.385-.313,3.385,1.712a7.263,7.263,0,0,1-3.387,5.621L202.346,214.28c-1.87,1.08-3.385.313-3.385-1.712A7.26,7.26,0,0,1,202.346,206.946Z"
                    transform="translate(-159.219 -36.144)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10159"
                    data-name="Path 10159"
                    d="M202.346,216.3l139.313-80.434c1.87-1.079,3.385-.313,3.385,1.712a7.266,7.266,0,0,1-3.387,5.623L202.346,223.63c-1.87,1.079-3.385.313-3.385-1.714A7.254,7.254,0,0,1,202.346,216.3Z"
                    transform="translate(-159.219 -30.875)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10160"
                    data-name="Path 10160"
                    d="M202.346,225.646l139.313-80.434c1.87-1.079,3.385-.313,3.385,1.712a7.266,7.266,0,0,1-3.387,5.623L202.346,232.979c-1.87,1.079-3.385.313-3.385-1.712A7.257,7.257,0,0,1,202.346,225.646Z"
                    transform="translate(-159.219 -25.606)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10161"
                    data-name="Path 10161"
                    d="M202.346,234.995l139.313-80.434c1.87-1.079,3.385-.313,3.385,1.714a7.263,7.263,0,0,1-3.387,5.621L202.346,242.328c-1.87,1.079-3.385.313-3.385-1.712A7.257,7.257,0,0,1,202.346,234.995Z"
                    transform="translate(-159.219 -20.337)"
                    fill="#e0e0e0"
                  ></path>
                  <path
                    id="Path_10162"
                    data-name="Path 10162"
                    d="M270.8,200.3l.219-.186L186.444,335.831,183,337.817l-.03.016a2.842,2.842,0,0,1-2.658.3L267.453,198.3Z"
                    transform="translate(-169.728 4.588)"
                    fill="#fff"
                    opacity="0.4"
                  ></path>
                  <path
                    id="Path_10163"
                    data-name="Path 10163"
                    d="M350.814,226.622a1.91,1.91,0,0,1-.22.313l-86.919-38.559a14.966,14.966,0,0,0-18.779,5.785l-3.441-2a15,15,0,0,1,18.763-5.754Z"
                    transform="translate(-135.269 -2.833)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10164"
                    data-name="Path 10164"
                    d="M269.1,188.782l-12.8,17.841a7.3,7.3,0,0,1-7.052,2.642l-11.055-2.628.219-.186.53-.844,7.24,1.72a7.265,7.265,0,0,0,7.053-2.644l12.681-17.636Z"
                    transform="translate(-137.112 -1.753)"
                    fill="#fff"
                    opacity="0.4"
                  ></path>
                  <path
                    id="Path_10165"
                    data-name="Path 10165"
                    d="M344.023,108.677l-5.158-2.971-3.127,1.8,7.458,4.3.017.014-.012-.008a3.418,3.418,0,0,0-.969.891l-86.191,120-.125.172v.016l3.188,1.736,85.765-119.4.438-.61a4.483,4.483,0,0,1,1-1.032c.766-.532,1.359-.219,1.548.905h.016A8.943,8.943,0,0,0,344.023,108.677Z"
                    transform="translate(-127.119 -47.596)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10166"
                    data-name="Path 10166"
                    d="M191.946,192.167l-18.4-10.628V339.917a8.7,8.7,0,0,0,3.923,6.8l4.422,2.553a8.7,8.7,0,0,0,7.846,0l2.208-1.274Z"
                    transform="translate(-173.542 -4.856)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10167"
                    data-name="Path 10167"
                    d="M386.872,111.8V263.97a7.889,7.889,0,0,1-1.22,3.972,8.132,8.132,0,0,1-2.3,2.564l-.141.094a2.555,2.555,0,0,1-.266.172l-200.7,115.863-.031.016a3.727,3.727,0,0,1-.766.36,4.985,4.985,0,0,1-.047-.7V232.448a3.41,3.41,0,0,1,4.409-3.471L270.2,249.053l11.055,2.627a7.305,7.305,0,0,0,7.052-2.642L301.108,231.2Z"
                    transform="translate(-169.117 -44.162)"
                    fill="#455a64"
                  ></path>
                  <path
                    id="Path_10168"
                    data-name="Path 10168"
                    d="M389.952,112.737V266.581a8,8,0,0,1-1.22,3.972,8.57,8.57,0,0,1-2.3,2.58l-.156.078a2.383,2.383,0,0,1-.25.172l-200.7,115.863-.031.016a2.8,2.8,0,0,1-2.674.313,2.382,2.382,0,0,1-1.173-1.876,4.985,4.985,0,0,1-.047-.7V233.135a3.41,3.41,0,0,1,4.409-3.471L270.2,249.74l11.055,2.627a7.305,7.305,0,0,0,7.052-2.642l12.806-17.841,85.764-119.4.438-.61C388.764,109.86,389.952,110.235,389.952,112.737Z"
                    transform="translate(-169.117 -44.849)"
                    fill="#08418b"
                  ></path>
                  <path
                    id="Path_10169"
                    data-name="Path 10169"
                    d="M342.874,229.659a2.639,2.639,0,0,0,.256-.166l.15-.091a8.389,8.389,0,0,0,2.3-2.567,8.05,8.05,0,0,0,1.218-3.906l-88.833-34.767,59.489,56.174Z"
                    transform="translate(-125.966 -1.124)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10170"
                    data-name="Path 10170"
                    d="M278.714,191.612l-96.1,153.406a2.706,2.706,0,0,1-1.22-2.58v-2.549L266.188,204.23l.657-1.048,8.429-13.51c0-.016.014-.031.03-.047Z"
                    transform="translate(-169.119 -0.298)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10171"
                    data-name="Path 10171"
                    d="M388.288,227.857a8.389,8.389,0,0,1-2.3,2.567l-.15.091L184.881,346.549l-.031.016a2.79,2.79,0,0,1-2.672.3l96.109-153.431a15.1,15.1,0,0,1,18.9-5.8Z"
                    transform="translate(-168.678 -2.146)"
                    fill="#08418b"
                  ></path>
                  <path
                    id="Path_10172"
                    data-name="Path 10172"
                    d="M388.29,213.488a8.154,8.154,0,0,1-2.3,2.564l-.141.094L184.88,332.181l-.031.016a2.826,2.826,0,0,1-2.674.3l2.674-4.253,197.5-114.05.158-.094a7.992,7.992,0,0,0,2.078-2.252Z"
                    transform="translate(-168.68 12.225)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10173"
                    data-name="Path 10173"
                    d="M174.28,172.436l3.19,1.845a15.591,15.591,0,0,0-.736,4.284v9.945l-3.19-1.845V176.7A15.6,15.6,0,0,1,174.28,172.436Z"
                    transform="translate(-173.544 -9.987)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10174"
                    data-name="Path 10174"
                    d="M192.005,189.159l-.017,0-13.33-7.6-3.08,1.829,7.927,4.566,3.221,1.861.033-.019a3.17,3.17,0,0,1,.6-.191,4.31,4.31,0,0,1,1.722.063l6.487,1.548,73.9,17.575.657-1.048Z"
                    transform="translate(-172.398 -4.85)"
                    fill="#0d2335"
                  ></path>
                  <path
                    id="Path_10175"
                    data-name="Path 10175"
                    d="M365.912,194.3l-14.842-8.6-.017-.009-.133-.077-.014.022c-6.476-3.656-15.367-3.105-25.2,2.569-19.8,11.433-35.852,39.237-35.852,62.1,0,11.414,4,19.434,10.468,23.188v0l.03.017,0,0,13.527,7.762,3.173-5.305a36.394,36.394,0,0,0,10.284-4c19.8-11.433,35.852-39.236,35.857-62.1a38.481,38.481,0,0,0-1.124-9.158Z"
                    transform="translate(-107.99 -3.85)"
                    fill="#854f08"
                  ></path>
                  <path
                    id="Path_10176"
                    data-name="Path 10176"
                    d="M291.209,218.459a57.759,57.759,0,0,0-1.354,12.044c0,11.414,4,19.434,10.468,23.188v0l.03.017,0,0,13.527,7.762,3.173-5.305a36.394,36.394,0,0,0,10.284-4,57.8,57.8,0,0,0,9.771-7.213Z"
                    transform="translate(-107.99 15.952)"
                    opacity="0.15"
                  ></path>
                  <path
                    id="Path_10177"
                    data-name="Path 10177"
                    d="M334.737,193.414c-19.8,11.433-35.855,39.236-35.857,62.1s16.052,32.132,35.854,20.7,35.855-39.236,35.857-62.1S354.538,181.981,334.737,193.414Z"
                    transform="translate(-102.904 -0.923)"
                    fill="#ffa000"
                  ></path>
                  <g
                    id="Group_222"
                    data-name="Group 222"
                    transform="translate(213.06 203.618)"
                  >
                    <path
                      id="Path_10178"
                      data-name="Path 10178"
                      d="M342.742,219.473v-8.784c0-10.024-6.093-14.631-13.611-10.29h0c-7.516,4.341-13.61,15.983-13.61,26.006v7.851a31.1,31.1,0,0,1-4.581,15.333l-.019.034a8.54,8.54,0,0,0-1.115,4.014h0c0,2.525,1.535,3.685,3.429,2.592l31.793-18.355a8.21,8.21,0,0,0,3.427-6.552h0a3.22,3.22,0,0,0-1-2.652.248.248,0,0,0-.053-.031C344.588,227.373,342.742,224.2,342.742,219.473Z"
                      transform="translate(-309.806 -198.765)"
                      fill="#fff"
                    ></path>
                    <path
                      id="Path_10179"
                      data-name="Path 10179"
                      d="M331.055,228.97c0,4.811-2.924,10.4-6.534,12.484s-6.534-.127-6.534-4.939Z"
                      transform="translate(-305.196 -181.741)"
                      fill="#fff"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </>
        )}
      </div>
    </div>
  );
}

export default ContactUs;
