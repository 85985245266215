import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; //npm install react-router-dom
import PrintView from "../../Pages/PrintView/PrintView";

import UserConsoleIndex from "../../Pages/UserConsole/UserConsoleIndex";

export default class PagesUserConsole extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route
              exact
              path="/userConsole/search"
              component={() => (
                <UserConsoleIndex {...this.props} page="userSearch" />
              )}
            />
            <Route
              exact
              path="/userConsole/data"
              component={() => (
                <UserConsoleIndex {...this.props} page="userData" />
              )}
            />
            <Route
              exact
              path="/userConsole/files"
              component={() => (
                <UserConsoleIndex {...this.props} page="files" />
              )}
            />
            <Route
              exact
              path="/userConsole/printview"
              component={() => <PrintView {...this.props} page="files" />}
            />

            <Route
              exact
              path="/userConsole/clockInOut"
              component={() => (
                <UserConsoleIndex {...this.props} page="clockInOutIndex" />
              )}
            />
            <Route
              exact
              path="/userConsole/training"
              component={() => (
                <UserConsoleIndex {...this.props} page="training" />
              )}
            />

            <Route
              exact
              path="/userConsole/sorties"
              component={() => (
                <UserConsoleIndex {...this.props} page="sorties" />
              )}
            />

             <Route
              exact
              path="/userConsole/editAvatar"
              component={() => (
                <UserConsoleIndex {...this.props} page="editAvatar" />
              )}
            />

            <Route
              exact
              path="/userConsole/contactUs"
              component={() => (
                <UserConsoleIndex {...this.props} page="contactUs" />
              )}
            />

            {/* <Route path="/" component={() =>  < UserConsoleIndex {...this.props} page='userData' /> } /> */}
            <Route
              path="/"
              component={() => (
                <UserConsoleIndex {...this.props} page="userIndex" />
              )}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
