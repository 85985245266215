import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; //npm install react-router-dom
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import SiteConnector from "./SiteConnector";
import { getAllUrlParams } from "../-Helpers-/UrlParameters";
import MekomotSherutSearchOnly from "../../Pages/MekomotSherut/MekomotSherutSearchOnly";

export default class getPage extends Component {
  render() {
    if (this.props.location.search === "?logout=1") {
      localStorage.setItem("userData", "");
      window.open("/login", "_self");
    } else if (this.props.location.search === "?login=1") {
      localStorage.setItem("userData", JSON.stringify({ SessionKey: "s" }));
      localStorage.setItem("rakazid", "");
      localStorage.setItem("sayeretid", "");
      window.open("/userConsole", "_self");
    }

    const urlParams = getAllUrlParams(window.location.search);
    //console.log(localStorage.getItem('userData').length);

    //console.log(urlParams);
    //http://localhost:3000/?iframe=1&menuonly=1

    if (urlParams?.iframe === "1" && urlParams?.menuonly === "1") {
      return (
        <React.Fragment>
          <Router>
            <Switch>
              <Route
                path="/"
                component={() => (
                  <MekomotSherutSearchOnly isMobile={isMobile ? true : false} />
                )}
              />
            </Switch>
          </Router>
        </React.Fragment>
      );
    } else if (urlParams?.iframe === "1") {
      return (
        <React.Fragment>
          <Router>
            <Switch>
              <Route
                path="/"
                component={() => (
                  <SiteConnector
                    search={this.props.location.search}
                    page="mekomotSherutPages"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />
            </Switch>
          </Router>
        </React.Fragment>
      );
    } else if (
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length > 0
    ) {
      return (
        <React.Fragment>
          <Router>
            <Switch>
              <Route
                path="/sherutPlaces"
                component={() => (
                  <SiteConnector
                    search={this.props.location.search}
                    page="mekomotSherutPages"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />
              <Route
                path="/"
                component={() => (
                  <SiteConnector
                    page="userConsolePages"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />
            </Switch>
          </Router>
        </React.Fragment>
      );
    } else if (this.props.location.search !== "?logout=1") {
      return (
        <React.Fragment>
          <Router>
            <Switch>
              <Route
                path="/register"
                component={() => (
                  <SiteConnector
                    page="register"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />
              <Route
                path="/login"
                component={() => (
                  <SiteConnector
                    page="login"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />
              <Route
                path="/reSendPass"
                component={() => (
                  <SiteConnector
                    page="reSendPass"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />

              <Route
                path="/loginQueryUrl"
                component={() => (
                  <SiteConnector
                    page="loginQueryUrl"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />

              <Route
                path="/sherutPlaces"
                component={() => (
                  <SiteConnector
                    search={this.props.location.search}
                    page="mekomotSherutPages"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />

              <Route
                path="/"
                component={() => (
                  <SiteConnector
                    page="login"
                    isMobile={isMobile ? true : false}
                  />
                )}
              />
            </Switch>
          </Router>
        </React.Fragment>
      );
    }
  }
}
