import React, { Component } from 'react'
import ModalPictures from './ModalPictures'

export default class GalleryPictures extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            openModal : false,
            firstPic : 0
        }
    }


    openGallery = (key) => {

        
        this.setState({ firstPic : key, openModal : true });
        
    }
    
    

    render() {

        // console.log(this.props.pictures);

        const pictures = this.props.pictures;

        return (

            <div className="carouselGallery">

                {pictures.map( item => {  if (true) {

                    let urlPic = item.url + "200";
                    //let imgUrl = 'url("'+ urlPic + '")';

                    //console.log(url);
                    
                    return  <button key={item.key} onClick={ () => this.openGallery(item.key) } >
                            
                            <img src={urlPic} alt={item.alt} />

                        </button>

                } else return false}) }

                {this.state.openModal ? 

                    < ModalPictures pictures={ this.props.pictures } currentState={ this.state } callBack={() => this.setState({ openModal : false })}  />

                : false }

            </div>
        )
    }
}

