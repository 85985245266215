import React, { Component } from 'react'

import face from './../../img/sherut-leumi/svg/social/face.svg';
import insta from './../../img/sherut-leumi/svg/social/insta.svg';
import tiktok from './../../img/sherut-leumi/svg/social/tiktok.svg';
import youtube from './../../img/sherut-leumi/svg/social/youtube.svg';
import whatsApp from './../../img/sherut-leumi/svg/social/whatsapp.svg';

export default class ShowSocialNetworks extends Component {
    render() {
        
        const social = [
            {
                name : 'facebook',
                url : 'https://www.facebook.com/agudasherut/',
                pic : face
            },
            {
                name : 'instagram',
                url : 'https://www.instagram.com/aguda.sherut/',
                pic : insta
            },
            {
                name : 'tiktok',
                url : 'https://www.tiktok.com/@aguda.sherut',
                pic : tiktok 
            },
            {
                name : 'youtube',
                url : 'https://www.youtube.com/channel/UC6jDIDFUTAkHeP4I2ky20Mw',
                pic : youtube
            },
            {
                name : 'whatsapp',
                url : 'https://api.whatsapp.com/send/?phone=972507521223',
                pic : whatsApp
            }
        ]

        return (
            <div className="ShowSocialNetworks">
                <ul>
                {social.map(item => { if (true) {
                        /* currentState={this.state} */
                        return  <li key={item.name} >
                                    <a href= { item.url }  target="_blank" rel="noopener noreferrer" >
                                        <img src={ item.pic } alt={ item.name } />
                                    </a>
                                </li>
                } else return false}) }
                    
                </ul>
            </div>
        )
    }
}
