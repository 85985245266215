import { RestUrls } from "../config";

export default async function getDataFromApi(
  url,
  objectToSend,
  controller,
  auth = "all"
) {
  //const API =  RestUrls.baseApiUrl + controller + '/' + url;

  const API = RestUrls.baseApiUrl + controller + "/" + url;

  console.log("API Check:",API);

  const host = window.location.host;
  objectToSend.isRotemDev = host.includes("rotem-dev-sherut-leumi.vercel.app")
    ? true
    : false;
  //objectToSend.isRotemDev = ( host.includes("rotem-dev-sherut-leumi.vercel.app") || host.includes("localhost:") ) ? true : false;
  //console.log('host', host);

  let userPass = {
    auth: auth,
    token: RestUrls.Code,
  };

  let items = { ...userPass, ...objectToSend };

  if (objectToSend.isRotemDev) {
    console.log("isRotemDev >", items?.isRotemDev);
    console.log("controller >", controller);
    console.log("url >", url);
  }
  console.log("items: ",items);
  console.log("JSON.stringify(items) ",JSON.stringify(items));

  try {
    const res = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(items),
    });

    const data = await res.json();
    console.log("Data:",data);
    if (objectToSend?.isRotemDev) {
      console.log("isRotemDev Response: ", data);
    }

    if (data === "unauthorized" || res.status === 403) {
      //403 forbidden
      //alert('yes');
      localStorage.setItem("userData", "");
      window.open("/login", "_self");
    } else if (data) {
      let statusHeaders = {
        headerStatus: res.status,
      };

      let responseData = { ...statusHeaders, ...data };
      //console.log(res);

      //console.log(data);
      return responseData;
    }
  } catch (e) {
    console.log(e);
    alert(e, "שגיאה");

    //window.location.reload();
    //return 'apiError';
  }
}
