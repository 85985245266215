import React, { Component } from "react";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import { RestUrls } from "../../Components/-Helpers-/config";

import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";

import { Button } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0
import loader from "../../img/preLoader.gif";

import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";

import { getAllUrlParams } from "./../../Components/-Helpers-/UrlParameters";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IDNO: "",
      Password: "",
      loading: false,
      responseLogin: false,
    };
  }

  updateValue = (newValue) => {
    this.setState(newValue, () => {
      //console.log(this.state);
    });
  };

  sendForm = (sendRules) => {
    this.setState({
      checkInputs: true,
      checkInputsPage: true,
      checkRadio1: true,
      checkRadio2: true,
    });

    if (sendRules) {
      this.setState({
        loading: true,
      });

      const sendObj = this.state;
      delete sendObj.responseLogin;

      this.sendtoAdmin(
        "login",
        "register",
        sendObj,
        "responseLogin",
        "register"
      );

      //console.log(sendObj);
    } else {
      this.setState({
        btnSendClass: "danger",
      });
    }
  };

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    console.log("sending...");

    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);
    getData.then(async (getData) =>
      this.setState(
        { [setStateName]: getData, loading: false },
        async function () {
          if (getData.ok) {
            //TODO: change to routeing here
            console.log(window.location.hostname);
            localStorage.setItem("userData", JSON.stringify(getData.ok));
            console.log(getData.ok, "getData.ok");
            const { InService, Category, FirstName, LastName, IDNO, Sex } =
              getData["ok"];
            if (window.ReactNativeWebView !== undefined) {
              const newLog = await fetch(
                // "https://webhooks.wdev.co.il/api/aguda-logs",
                "https://webhooks.wdev.co.il/api/aguda-logs",

                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },

                  body: JSON.stringify({
                    InService,
                    Category,
                    FirstName,
                    LastName,
                    IDNO,
                    Sex,
                  }),
                }
              );
              console.log(newLog, "new log");
            }
            console.log(window.location.hostname, "window.location.hostname");
            if (InService === false && Category !== "2") {
              window.open("/userConsole/data", "_self");
            } else if (InService === false && Category === "2") {
              window.open("/userConsole/data", "_self");
            } else if (InService === true) {
              window.open("/userConsole/clockInOut", "_self");
            } else {
              window.open("/userConsole/data", "_self");
            }
          }
        }
      )
    );
  };

  componentDidMount() {
    //console.log(isTodayGreaterThan (new Date('2023-01-15')));

    const urlQuery = getAllUrlParams(window.location.href);
    const rakazId = urlQuery.rakazid ? urlQuery.rakazid : false;
    const sayeretId = urlQuery.sayeretid ? urlQuery.sayeretid : false;

    if (rakazId) {
      localStorage.setItem("rakazid", rakazId);
    } else if (sayeretId) {
      localStorage.setItem("sayeretid", sayeretId);
    }
  }

  render() {
    const sendRules = this.state.IDNO && this.state.Password;

    const isMobile = this.props.siteInfo.isMobile
      ? this.props.siteInfo.isMobile
      : false;
    let m_picTop = RestUrls.pagesPictures + "register/m_registerTop.jpg?v=4";

    return (
      <div className="firstRegister loginPage animate__animated animate__fadeIn">
        <img
          src={loader}
          alt="loader"
          className={
            !this.state.loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        {isMobile ? <img className="topPic" src={m_picTop} alt="top" /> : false}

        <header>
          <h1 className="boldTypeFamily">התחברות</h1>
          <p>ברוכים השבים לאגודה להתנדבות</p>
        </header>

        <div className="inputs">
          <div className="line">
            <CustomFloatInput
              name="IDNO"
              updateValue={this.updateValue}
              value={this.state.IDNO}
              placeholder="תעודת זהות"
              cssClass=""
              validationRules={{ required: true }}
              typeInput="number"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
          </div>

          <div className="line">
            <CustomFloatInput
              name="Password"
              updateValue={this.updateValue}
              value={this.state.Password}
              placeholder="סיסמה"
              cssClass=""
              validationRules={{ required: true }}
              typeInput="password"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
            <div className="singIn reSendPass">
              <NavLink
                className="jumpPage"
                role="menuitem"
                onClick={() => scroll.scrollTo(0)}
                to={"/reSendPass"}
              >
                <span>
                  <strong>שכחתי סיסמה</strong>
                </span>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="text-center">
          <Button
            disabled={this.state.loading ? true : false}
            className="sendBlueBtn"
            onClick={() => this.sendForm(sendRules)}
            size="lg"
            variant={
              sendRules
                ? "success animate__animated animate__bounceIn"
                : this.state.btnSendClass
            }
          >
            התחברות
          </Button>
          {window.ReactNativeWebView === undefined && (
            <div className="singIn">
              <NavLink
                className="jumpPage"
                role="menuitem"
                onClick={() => scroll.scrollTo(0)}
                to={"/register"}
              >
                <span>
                  עדין לא רשומים? <strong>לחצו להרשמה (נרשמים חדשים)</strong>
                </span>
              </NavLink>
            </div>
          )}
        </div>
        {this.state.responseLogin && this.state.responseLogin.error ? (
          <ModalDefaul
            variant="error"
            params={{ title: "שגיאה", text: this.state.responseLogin.error }}
            callBack={() => this.setState({ responseLogin: false })}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}
