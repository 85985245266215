import React from 'react'

//import { toast } from 'react-toastify'

import { RestUrls } from "../../../Components/-Helpers-/config";
import CloseAppIframe from './CloseAppIframe';

export default function AvatarEditPage(props) {

  const { siteInfo } = props;

  console.log(props);

  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
  const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=2";
  //const m_topPic = RestUrls.pagesPictures + "/register/m_clockTop.jpg?v=1";
  const isMobile = siteInfo.isMobile;


  return (
    <div className="DataSection editAvatarPageIndex">  {/* trainingPageIndex */}
      {!isMobile && <img className="topPic full_width" src={isMobile ? m_topPic : topPic} alt="top" />}

      <div className='editAvatarPage'>

        {!isMobile && <header className="clear">
          <h1>עריכת אווטר</h1>
        </header>
        }
        < CloseAppIframe {...siteInfo} />

      </div>

    </div>
  )
}
