import React, { Component } from "react";
import ContentWrapper from "./ContentWrapper";
import { RestUrls } from "../-Helpers-/config";

export default class PagesRegister extends Component {
  render() {
    let bgDesktop = RestUrls.pagesPictures;

    if (this.props.isMobile) {
      return (
        <div className={`body-container PagesRegister Mobile`}>
          <ContentWrapper {...this.props} />
        </div>
      );
    } else {
      return (
        <div
          className="body-container PagesRegister Desktop"
          style={{
            backgroundImage: "url('" + bgDesktop + "register/bg.jpg?v=2')",
          }}
        >
          {window.ReactNativeWebView === undefined && (
            <>
              <ContentWrapper {...this.props} />
              <a
                className="linkToSite"
                href="https://sherut-leumi.co.il/"
                target="_self"
                rel="noopener noreferrer"
              >
                לאתה האגודה
              </a>
            </>
          )}
        </div>
      );
    }
  }
}
