import React, { Component } from 'react'

export default class SearchCategory extends Component {
    render() {
        return (
            <div className="changePage">

                <div className='buttonsCont'>
                    <button className={this.props.currentPage !== 'mySayarot' ? 'active' : ''} onClick={() => this.props.changePage('pageSayarot') } >הסיירות</button>
                    <button className={this.props.currentPage === 'mySayarot' ? 'active' : ''} onClick={() => this.props.changePage('mySayarot') } >הסיירות שלך</button>
                </div>

            </div>
        )
    }
}
