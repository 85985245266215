import React from 'react'

import fileBgQuad from '../../../img/sherut-leumi/svg/files/fileBgQuad.png';

//import x from '../../../img/sherut-leumi/svg/files/x.svg';
import v from '../../../img/sherut-leumi/svg/files/v.svg';
import UploadFile from './UploadFile';

export default function FileItem(props) {

    const {formData, texts, noHistoryForm} = props;

    //console.log('formData > ',formData);
    //console.log('texts > ',texts);

    const backgroundQuad = "url('" + fileBgQuad + "')";

  return (
    <div className='fileItem'>
        <div className='header' >
            <h3>{texts.title}</h3>
            <p>{texts.filesType}</p>
        </div>

        { ( formData.Status !== "Missing" && !noHistoryForm ) ? <div className='blueQuad docExist' style={{ backgroundImage : backgroundQuad }}>
            <img src={v} alt='המסמך קיים במערכת' />
            <p>המסמך קיים במערכת</p>
        </div> : < UploadFile {...props} />}
    </div>
  )
}
