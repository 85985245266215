import React, { Component } from 'react'

//https://www.npmjs.com/package/react-text-truncate
//import TextTruncate from 'react-text-truncate';

import thum from "./../../../img/sherut-leumi/svg/sherutPlaces/card/thum.svg";

import garin from "./../../../img/sherut-leumi/svg/sherutPlaces/card/garin.svg";

import tekenB from "./../../../img/sherut-leumi/svg/sherutPlaces/card/teken.svg";
import tekenH from "./../../../img/sherut-leumi/svg/sherutPlaces/card/tekenH.svg";
import tekenP from "./../../../img/sherut-leumi/svg/sherutPlaces/card/tekenP.svg";

//import mekomot from "./../../../img/sherut-leumi/svg/search/resultsIcons/mekomot.svg";


export default class ShowDataInRow extends Component {
    
    render() {

        let item = this.props.item;
        //console.log(item);

        return (
            <div className="ShowDataInRow">
                <header>
                    {item.MOSADNA ? <h2>{ item.MOSADNA }</h2> : <h2>שם מקום - {item.MOSADNA}</h2> }
                    <p>{item.City_Key}</p>
                </header>
                
                <ul className="iconsLine" >
                    <li>
                        <img src={thum} alt='' />
                        <span>תחום: </span> <span>{item.Thum2_Value}</span>
                    </li>

                    { parseInt(item.TEKENB) > 0  ? <li>
                        <img src={tekenB} alt='' />
                        <span>תקן: בית - </span> <span>{item.TEKENB}</span> <span>פנויים</span>
                    </li> : false }

                    { parseInt(item.TEKENP) > 0  ? <li>
                        <img src={tekenH} alt='' />
                        <span>תקן: פנימיה - </span> <span>{item.TEKENP}</span> <span>פנויים</span>
                    </li>  : false }

                    { parseInt(item.TEKENH) > 0  ? <li>
                        <img src={tekenP} alt='' />
                        <span>תקן: חוץ - </span> <span>{item.TEKENH}</span> <span>פנויים</span>
                    </li>  : false }

                    {item.Grain !== '0' ? <li>
                        <img src={garin} alt='' />
                        <span>גרעין</span>
                    </li> : false }

                </ul>
                
                
                
            </div>
        )
    }

}
