import axios from "axios";
import { isEmpty, map, reverse, size } from "lodash";
import { toast } from "react-toastify";
import { RestUrls } from "../../Components/-Helpers-/config";
import moment from "moment";   //npm install moment
import { checkTextAreaReportContent } from "./clockFunctions";

function humanClock(hoursMinutes) {

    if(hoursMinutes) {

        const hours = hoursMinutes.substring(0, 2);
        const minutes = hoursMinutes.substring(2, 4);

        return hours + ':' + minutes;

    } else {
        return "00:00"
    }
}

function prettyDay(monthDay, monthNumber,currentYear) {


    const startDate = `${currentYear}-${monthNumber}-${monthDay}`;

    //tikun Iphone: .toString().replace(/-/g, '/')

    const monthYear = new Date(startDate.toString().replace(/-/g, '/')).toLocaleDateString('he', {
        
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',

    });

    if(monthYear === 'Invalid Date') {toast.error('שגיאה'); return '---';}

    if(monthYear !== 'Invalid Date') {
        return monthYear
    } else {
        toast.error('שגיאה');
        return '---';
    }

}

function prettyDay2(monthDay, monthNumber,currentYear) {


    const startDate = `${currentYear}-${monthNumber}-${monthDay}`;

    //tikun Iphone: .toString().replace(/-/g, '/')

    const monthYear = new Date(startDate.toString().replace(/-/g, '/')).toLocaleDateString('he', {
        
        weekday: 'long',
        day: '2-digit',
        //month: '2-digit',
        //year: 'numeric',

    });

    if(monthYear === 'Invalid Date') {toast.error('שגיאה'); return '---';}

    if(monthYear !== 'Invalid Date') {
        return monthYear
    } else {
        toast.error('שגיאה');
        return '---';
    }

}

export function dateFromString(yearMonthDay) {

    if(yearMonthDay) {
        
        const prettyDate = yearMonthDay.substring(6, 8) + '.' + yearMonthDay.substring(4, 6) + '.' + yearMonthDay.substring(0, 4);
        return prettyDate;    

    } else {

        return false
        
    }
    
}

export function getTypeRecord(id) {

    switch (id) {

        case '1':
            return 'נוכחות';

        case '2':
            return 'נוכחות בהכשרה';

        case '3':
            return 'מחוץ למקום השירות';

        case '4':
            return 'נוכחות ביום שבתון';
    
        default:
            return 'לא נבחר';
    }

}

export function getTypeRecordsList() {

    const data = [
        {id: '1', name: 'נוכחות'},
        {id: '2', name: 'נוכחות בהכשרה'},
        {id: '3', name: 'מחוץ למקום השירות'},
        /* {id: '4', name: 'נוכחות ביום שבתון'} */
    ]

    return data;

}
function mergeShibObjects(shibArray) {
    const mergedObject = {
        ReportID: "",
        Month: "",
        Year: "",
        LateReportQuota: 0,
        LateReportUsed: 0,
        SumOfWorkingDays: 0,
        SumOfWorkingHours: 0,
        AmountOfUsedVacationDays: 0,
        ReportConfirmationDate: "",
        ReportConfirmationDateByEmployee: "",
        ReportConfirmationDateByManager: "",
        ConfirmedSickDays: 0,
        UnconfirmedSickDays: 0,
        Dates: [],
        original: shibArray,
    };

    shibArray.forEach(shibObj => {
        // Merge simple numeric and string fields
        mergedObject.LateReportQuota += parseInt(shibObj.LateReportQuota || "0");
        mergedObject.LateReportUsed += parseInt(shibObj.LateReportUsed || "0");
        mergedObject.AmountOfUsedVacationDays += parseInt(shibObj.AmountOfUsedVacationDays || "0");
        mergedObject.ConfirmedSickDays += parseInt(shibObj.ConfirmedSickDays || "0");
        mergedObject.UnconfirmedSickDays += parseInt(shibObj.UnconfirmedSickDays || "0");

        // For ReportID, Month, Year, and Dates, handling is a bit different
        if (!mergedObject.ReportID) mergedObject.ReportID = shibObj.ReportID;
        if (!mergedObject.Month) mergedObject.Month = shibObj.Month;
        if (!mergedObject.Year) mergedObject.Year = shibObj.Year;
        
        shibObj.Dates.forEach(dateObj => {
            let existingDate = mergedObject.Dates.find(d => d.DOM === dateObj.DOM);
            if (existingDate) {
                // Merge Times arrays
                existingDate.Times = [...existingDate.Times, ...dateObj.Times];
            } else {
                // Add new date object
                mergedObject.Dates.push({ ...dateObj });
            }
        });
    });

    // You may need additional logic here to handle other fields like ReportConfirmationDate
    // For example, you might want to keep the earliest or latest date

    return mergedObject;
}

export function getMonthData(url,sendObj,setLoading,setResponseData,setDaysMonthData) {


    setLoading(true);
    
    const json = JSON.stringify(  sendObj  );
    
    axios.post( RestUrls.sherutLeumiApi + url, json, {
        headers: {'Content-Type': 'application/json'},
    })
        .then( res => {
            setLoading(false);
            //console.log(res);
            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                setResponseData(false);
                return toast.error('שגיאה בקריאת סטטוס');
            }
            if(!res?.data?.Shib[0]) {
                setResponseData(false);
                toast.error('לא נמצאו נתונים');
                return false;

            } else {
if(res?.data?.Shib.length > 1) {
    populateMonthData(mergeShibObjects(res.data.Shib),setResponseData,setDaysMonthData);
} else {
    const singleShibWithOriginal = { ...res.data.Shib[0], original: [res.data.Shib[0]] };
    populateMonthData(singleShibWithOriginal, setResponseData, setDaysMonthData);
}

            }
        })
        
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}


export function getAbsenceList(url,sendObj,setLoading,setResponseData) {

    setLoading(true);
    
    const json = JSON.stringify( sendObj  );

    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            setLoading(false);

            if(!res?.data?.Items) {
                setResponseData(false);
                return toast.error('שגיאה');
            }

            setResponseData(res.data.Items);           
          

        
        })
        
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}


function populateMonthData(response,setResponseData,setDaysMonthData) {

    //console.log('response', response);

    let isOpen = true;

    if(response.ReportConfirmationDate && response.ReportConfirmationDateByEmployee &&
        response.ReportConfirmationDateByManager ) {

            isOpen = false;
    }


    let items = [];

    if(response?.Dates && !isEmpty(response.Dates)) {
        items = populateDayItems(+response.ReportID,response.Dates);
    }

    const monthData = {

        ReportID: +response.ReportID,

        isOpen: isOpen,
        //items: items,

        Month: response.Month,
        Year: response.Year,
        LateReportQuota: +response.LateReportQuota,
        LateReportUsed: +response.LateReportUsed,
        SumOfWorkingDays: +response.SumOfWorkingDays,
        SumOfWorkingHours: +response.SumOfWorkingHours,
        AmountOfUsedVacationDays: +response.AmountOfUsedVacationDays,
        ReportConfirmationDate: response.ReportConfirmationDate,
        ReportConfirmationDateByEmployee: response.ReportConfirmationDateByEmployee,
        ReportConfirmationDateByManager: response.ReportConfirmationDateByManager,
        ConfirmedSickDays: +response.ConfirmedSickDays,
        UnconfirmedSickDays: +response.UnconfirmedSickDays,
        original: {...response.original},

    }

    //console.log('monthData >',monthData);

    setResponseData(monthData);
    setDaysMonthData(items)

}

function populateDayItems(ReportID,Dates) {

    //console.log('Dates', Dates);

    let items = [];

    map(Dates, item => {

        let entryDays = [];

        if(item?.Times && !isEmpty(item.Times)) {
            
            entryDays = populateEntryDays(ReportID, item.Times);
        }

        const isOk = checkIsOk(item?.Times);

        const newItem = {

            dayData : {
                        Reson: item?.Reson,
                        ResonLateMtn: item?.ResonLateMtn,
                        ResonLateRak: item?.ResonLateRak,
                        YM: item?.YM,
                        DOM: item?.DOM
                    },

            id: ReportID + '_' + item.DOM,
            
            title : prettyDay(item?.DOM, item.YM.substring(4, 6),item.YM.substring(0, 4)),

            title2 : prettyDay2(item?.DOM, item.YM.substring(4, 6),item.YM.substring(0, 4)),

            reportsCount: size(item?.Times),

            isOk: isOk,
            isAbsence: item?.Reson ? item.Reson : false,


            items: entryDays

        }

        items.push(newItem);

    })

    return reverse(items);

}


function populateEntryDays(ReportID,entryDays) {

    let count = 1;
    let entryDaysNew = [];

    map(entryDays, item => {

        const newItem = {

            id: ReportID + '_' + count,
            in : item?.TimeF ? humanClock(item?.TimeF) : false,
            out :  item?.TimeT ? humanClock(item?.TimeT) : false,
            api : item

        }

        entryDaysNew.push(newItem);

        count++;

    })

    //console.log('entryDaysNew', entryDaysNew);

    return entryDaysNew
}

function checkIsOk(entryDays) {

    //console.log(entryDays);

    let flag = true;

    map(entryDays, item => {

        if(!item.TimeF || !item.TimeT) {
            flag =  false;
        }

    })

    return flag
}


export function checkData4Save(allChanges) {

    let okFlag = true;
    //let okFlag = false;

    //console.log('allChanges', allChanges)

    if( (allChanges?.Dates[0]?.Reson === 'חופשה') && !isEmpty(allChanges?.Dates[0]?.Times) ) {

        toast.error('לא ניתן לבחור חופשה אם קיימים דיווחים');
        return false;
        
    }


    
    map(allChanges?.Dates[0]?.Times, item => {

        //checkhasPerutDivuach
        if( ( item.Typ === '2' || item.Typ === '3' ) && !item.MoreInfo ) {

            okFlag = false;
            toast.error('יש להזין פירוט דיווח');
            return okFlag;

        }

        else if(!checkTextAreaReportContent(item.Typ,item.MoreInfo)) {
            okFlag = false;
            return false
        }

        else if( !item.Typ ) {

            okFlag = false;
            toast.error('יש לבחור סוג דיווח');
            return okFlag;

        }

        else if(!item.TimeF && !item.TimeT) {

            okFlag = false;
            toast.error('יש להזין שעות כניסה / יציאה');
            return okFlag;

        }

        else {
        
            if(item.TimeF && item.TimeT) {

                const hoursF = item.TimeF.substring(0, 2);
                const minutesF = item.TimeF.substring(2, 4);

                const hoursT = item.TimeT.substring(0, 2);
                const minutesT = item.TimeT.substring(2, 4);

                let dateObjectA = new Date();
                dateObjectA.setHours(hoursF);
                dateObjectA.setMinutes(minutesF);

                let dateObjectB = new Date();
                dateObjectB.setHours(hoursT);
                dateObjectB.setMinutes(minutesT);

                const a = moment(dateObjectA);
                const b = moment(dateObjectB);

                var duration = moment.duration(b.diff(a));

                //console.log('duration >', duration );

                if(duration._data.hours < 0 || duration._data.minutes < 0 ) {

                    okFlag = false;
                    const message = `שעת היציאה קטנה משעת הכניסה ${hoursF}:${minutesF} - ${hoursT}:${minutesT}`;
                    toast.error(message ,{toastId: "customId"});
                    return okFlag;
                }

            } else {

                okFlag = true;
                return okFlag;

            }


        }

        

    })

    

    return okFlag;


}


export function sendChangesToApi(url,sendObj,setLoading,allChanges,closeMe,reloadData) {

    //toast.success('ready for save...');
    //console.log('saveChanges => ',allChanges);

    console.log('url => ',url);

    const sendData = makeMyUpdateObj(sendObj,allChanges);
    console.log('sendData', sendData);

    //return true;

    if(!sendData) {toast.error('שגיאה');return false;}

    setLoading(true);
    
    const json = JSON.stringify(  sendData  );

    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            setLoading(false);
            console.log('response update',res?.data);

            if(res?.data?.Result === 'Error') {

                return toast.error('שגיאה');
                
            }

            if( res?.data?.ErrorMessage.includes('OverQuota') ) {

                toast.error("מכסת הדיווחים באיחור נוצלה במלואה. כל או חלק מהדיווחים לא התקבלו. נא לפנות לרכזת להמשך טיפול");
                closeMe('bottom', false);
                reloadData();

            } 

            else if( res?.data?.Reason.includes('OverQuota') ) {

                console.log('yes');
                toast.error("מכסת הדיווחים באיחור נוצלה במלואה. כל או חלק מהדיווחים לא התקבלו. נא לפנות לרכזת להמשך טיפול");
                closeMe('bottom', false);
                reloadData();

            } 
            
            else if( res?.data?.Reason.includes('ימי ההיעדרות מסוג מחלה') ) {

                toast.error("ניצלת בעבר את מכסת ימי ההיעדרות מסוג מחלה סיבת ההיעדרות עודכנה לחופש");
                closeMe('bottom', false);
                reloadData();


            } else if(  res?.data?.ErrorMessage.includes('OutOfOrder') ) {

                return toast.error('שגיאה, השעות חופפות'); 

            } else if(  res?.data?.ErrorMessage.includes('ToMuchInOuts') ) {

                return toast.error('שגיאה, יותר מדי דיווחים ידניים');

            } else if(  res?.data?.ErrorMessage.includes('Early') ) {

                return toast.error('שגיאה, דיווח מוקדם מהשעה המוגדרת'); 

            } else if(  res?.data?.Result === 'failed' ) {
                
                if(res?.data?.ErrorMessage) {

                    return toast.error(res?.data?.ErrorMessage); 

                } else {

                    return toast.error('שגיאה');
                }

            } else {

                toast.success('השינויים נשמרו בהצלחה');
                closeMe('bottom', false);
                reloadData();


            }
            
          

        
        })
        
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}

function makeMyUpdateObj(sendObj,allChanges) {

    if(!allChanges?.Dates[0].YM || !allChanges?.Dates[0].DOM) {
        toast.error('שגיאה');
        return false;
    }

    let Times = [];
    
    //console.log(allChanges.Dates[0].Times);

    if(!isEmpty(allChanges.Dates[0].Times)) {

        map(allChanges.Dates[0].Times, item => {

            const objNew = {
                TimeF : item.TimeF,
                TimeT : item.TimeT,
                Typ: item.Typ,
                MoreInfo: item.MoreInfo
            }

            Times.push(objNew);
           

        })

    }   


    const updateObj = {

        SessionKey:sendObj.SessionKey,
        idno:sendObj.IDNumber,
        Date: allChanges.Dates[0].YM +  allChanges.Dates[0].DOM,
        Times: Times,
        Reason: allChanges?.Dates[0].Reson
    }


    return updateObj;

}



export function getSummaryData(url,sendObj,setLoading,setResponseData) {


    setLoading(true);
    
    const json = JSON.stringify(  sendObj  );
    
    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            setLoading(false);
            //console.log(res);

            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                setResponseData(false);
                return toast.error('שגיאה');
            }
            
            if(res.data?.Years) {
                res.data.Years = reverse(res.data.Years);
            }

            setResponseData(res.data);
        
        })
        
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}


export function confirmMonth(url,sendObj,setLoading,loadMonthData) {

    //toast.success('ready for save...');
    //console.log('saveChanges => ',allChanges);

    setLoading(true);
    const json = JSON.stringify(  sendObj  );
    axios.post( RestUrls.sherutLeumiApi + url, json, {
        headers: {'Content-Type': 'application/json'},
    })
        .then( res => {
            setLoading(false);
            console.log('responseConfirm',res);
            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                if(res?.data?.ErrorMessage === 'Incomplete report.') {
                    return toast.error('שגיאה, יש למלא את החוסרים');
                } else {
                    return toast.error('שגיאה');
                }
            } else {

                toast.success('החודש אושר בהצלחה');
                loadMonthData();

            }
            
          

        
        })
        
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}




//OLD_OLD_OLD
// eslint-disable-next-line no-unused-vars
function makeMyUpdateObj_OLD_OLD_OLD (sendObj,allChanges) {

    if(!allChanges?.Dates[0].YM || !allChanges?.Dates[0].DOM) {
        toast.error('שגיאה');
        return false;
    }

    let Times = "";
    let daysHours = [];

    if(!isEmpty(allChanges.Dates[0].Times)) {

        map(allChanges.Dates[0].Times, item => {

            daysHours.push(item.TimeF);
            daysHours.push(item.TimeT);

        })

        Times = '[' + daysHours.join() + ']';

    }   


    const updateObj = {

        idno:sendObj.IDNumber,
        SessionKey:sendObj.SessionKey,
        Date: allChanges.Dates[0].YM +  allChanges.Dates[0].DOM,
        Times: Times,
        Reason: allChanges?.Dates[0].Reson

    }


    return updateObj;

}
/* 

if( res?.data?.EntranceTime1 === 'OverQuota' || res?.data?.EntranceTime2 === 'OverQuota' ) {

    return toast.error("מכסת הדיווחים באיחור נוצלה במלואה. כל או חלק מהדיווחים לא התקבלו. נא לפנות לרכזת להמשך טיפול");

} else if(  res?.data?.EntranceTime1 === 'OutOfOrder' || res?.data?.EntranceTime2 === 'OutOfOrder' ) {

    return toast.error('שגיאה, השעות חופפות'); */