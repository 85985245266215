import React, { Component } from "react";
import loader from "../../img/preLoader.gif";
import { Form, Button } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import FloatInputPassword from "../../Components/-Helpers-/forms/FloatInputPassword";

import man from "../../img/sherut-leumi/svg/man.svg";
import woman from "../../img/sherut-leumi/svg/woman.svg";

import { RestUrls } from "../../Components/-Helpers-/config";

import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";

import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";

function get17YearsAgo() {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date();
  date.toLocaleDateString("he-IL", options);
  date.setFullYear(date.getFullYear() - 17);
  return date.toISOString().substr(0, 10);
}

export default class SmallForm extends Component {
  constructor(props) {
    console.log("props", props);

    super(props);

    this.state = {
      responseNewRegister: false,
      sex: false,
      takanon: false,
      rishum: false,
      loading: false,
      btnSendClass: "success",
      checkInputs: false,
      checkInputsPage: false,
      checkRadio1: false,
      checkRadio2: false,
      // BirthDate: get17YearsAgo(),
      dataApi: false,
    };
  }

  updateValue = (newValue) => {
    this.setState(newValue, () => {
      //console.log(this.state);
    });
  };

  sendForm = (sendRules) => {
    this.setState({
      checkInputs: true,
      checkInputsPage: true,
      checkRadio1: true,
      checkRadio2: true,
    });

    if (sendRules) {
      this.setState({
        loading: true,
      });

      const sendObj = this.state;
      delete sendObj.responseNewRegister;
      delete sendObj.loading;
      delete sendObj.btnSendClass;
      delete sendObj.checkInputs;
      delete sendObj.checkInputsPage;
      delete sendObj.checkRadio1;
      delete sendObj.checkRadio2;
      delete sendObj.takanon;
      delete sendObj.rishum;

      /*             BirthDate: "1979-05-07"
            Category: "1"
            CityCode: "271"
            Email: "test@test.com"
            FirstName: "סבס"
            IDNO: "324504620"
            LastName: "בדיקה"
            Mobile: "6464312"
            MobileStart: "054"
            Password: "1234567890"
            PrvSchool: "6034"
            YearYad: "תשפ\"ב"
            sex: "man" */

      this.sendtoAdmin(
        "newRegister",
        "register",
        sendObj,
        "responseNewRegister",
        "register"
      );

      //console.log(sendObj);
    } else {
      this.setState({
        btnSendClass: "danger",
      });
    }
  };

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    //console.log('sending...');

    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState(
        {
          [setStateName]: getData,

          loading: false,
          rishum: true,
          takanon: true,
        },
        function () {
          //console.log(getData);

          if (getData.ok) {
            localStorage.setItem("userData", JSON.stringify(getData.ok));
            window.open("/userConsole", "_self");
            //alert(getData.ok);
          }
        }
      )
    );
  };

  componentDidMount() {
    if (!this.state.dataApi) {
      this.getDataApi();
    }
  }

  getDataApi = () => {
    this.sendtoAdmin2("hebrewYears", "register", {}, "dataApi", "all");
  };

  sendtoAdmin2 = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, () => {
        if (this.state.dataApi?.years) {
          const years = this.state.dataApi?.years;

          this.setState({
            dataApi: {
              ...this.state.dataApi,

              years: [
                { id: years.current.substring(1), value: "שנה זו" },
                { id: years.nextYear.substring(1), value: "שנה הבאה" },
                { id: years.year2.substring(1), value: "עוד שנתיים" },
              ],
            },
          });
        }

        //console.log(getData.userData);
      })
    );
  };

  render() {
    //console.log('dataApi', this.state.dataApi);

    const sendRules =
      this.state.sex &&
      this.state.rishum &&
      this.state.takanon &&
      this.state.FirstName &&
      this.state.LastName &&
      this.state.IDNO &&
      this.state.Mobile &&
      this.state.MobileStart &&
      this.state.BirthDate &&
      this.state.CityCode &&
      this.state.Email &&
      this.state.Category &&
      this.state.PrvSchool &&
      this.state.CityCode &&
      this.state.Password &&
      this.state.YearYad;

    const kidomot = [
      { id: "050", value: "050" },
      { id: "051", value: "051" },
      { id: "052", value: "052" },
      { id: "053", value: "053" },
      { id: "054", value: "054" },
      { id: "055", value: "055" },
      { id: "056", value: "056" },
      { id: "057", value: "057" },
      { id: "058", value: "058" },
      { id: "059", value: "059" },
      { id: "072", value: "072" },
      { id: "073", value: "073" },
      { id: "074", value: "074" },
      { id: "076", value: "076" },
      { id: "077", value: "077" },
      { id: "078", value: "078" },
    ];

    const CategoryOptions = [
      { id: "1", value: 'חילוני' },
      //{ id: "1", value: 'של"מ' },
      { id: "2", value: "דתי" },
      //{ id: "2", value: "ממלכתי דתי" },
      { id: "3", value: "מיעוטים" },
      /* { id: "4", value: "צרכים מיוחדים" },
      { id: "5", value: "בינלאומי" }, */
    ];

    const isMobile = this.props.siteInfo.isMobile
      ? this.props.siteInfo.isMobile
      : false;
    let m_picTop = RestUrls.pagesPictures + "register/m_registerTop.jpg?v=2";

    return (
      <div className="firstRegister animate__animated animate__fadeIn">
        {isMobile ? <img className="topPic" src={m_picTop} alt="top" /> : false}

        <img
          src={loader}
          alt="loader"
          className={
            !this.state.loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        <header>
          <h1 className="boldTypeFamily">הרשמה לאתר</h1>
          <p>בכדי להתחיל בתהליך השירות הלאומי עלייך להרשם לאתר</p>
        </header>

        <div className="inputs">
          <div className="rows2 clear line">
            <CustomFloatInput
              name="FirstName"
              updateValue={this.updateValue}
              value={this.state.FirstName}
              noEnglish={true}
              placeholder="שם פרטי"
              cssClass=""
              validationRules={{ required: true, minlength: 2, alpha: true }}
              typeInput="text"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
            <CustomFloatInput
              name="LastName"
              updateValue={this.updateValue}
              value={this.state.LastName}
              noEnglish={true}
              placeholder="שם משפחה"
              cssClass=""
              validationRules={{ required: true, minlength: 2, alpha: true }}
              typeInput="text"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
          </div>

          <div className="rows3 clear line lineTZPhone">
            <CustomFloatInput
              name="IDNO"
              updateValue={this.updateValue}
              value={this.state.IDNO}
              placeholder="תעודת זהות"
              cssClass=""
              validationRules={{ required: true, tz: true }}
              typeInput="number"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />

            <div className="phoneInputs">
              <CustomFloatInput
                name="MobileStart"
                selectOptions={kidomot}
                updateValue={this.updateValue}
                value={this.state.MobileStart}
                placeholder="קידומת"
                cssClass=""
                validationRules={{ required: true }}
                typeInput="select"
                checkInputs={this.state.checkInputs}
                checked={() => this.setState({ checkInputs: false })}
              />
              <CustomFloatInput
                name="Mobile"
                updateValue={this.updateValue}
                value={this.state.Mobile}
                placeholder="טלפון נייד"
                cssClass=""
                validationRules={{
                  required: true,
                  minlength: 7,
                  maxlength: 7,
                  numbers: true,
                }}
                typeInput="text"
                checkInputs={this.state.checkInputs}
                checked={() => this.setState({ checkInputs: false })}
              />
            </div>
          </div>

          <div className="rows2 clear line">
            <CustomFloatInput
              isMobile={isMobile}
              name="BirthDate"
              updateValue={this.updateValue}
              value={this.state.BirthDate}
              startDate={get17YearsAgo()}
              placeholder="תאריך לידה"
              cssClass=""
              validationRules={{ required: true }}
              typeInput="date"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
            <CustomFloatInput
              name="Email"
              updateValue={this.updateValue}
              value={this.state.Email}
              placeholder='דוא"ל'
              cssClass=""
              validationRules={{ required: true, email: true }}
              typeInput="text"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
          </div>

          <div className="rows2 clear line">
            <CustomFloatInput
              name="CityCode"
              dbParams={{
                function: "getCities4Select",
                controller: "app",
                valueField: "id",
              }}
              updateValue={this.updateValue}
              value={this.state.CityCode}
              placeholder="עיר / ישוב"
              cssClass=""
              zIndexClass="topZindex"
              validationRules={{ required: true }}
              typeInput="searchDB"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
            <CustomFloatInput
              name="PrvSchool"
              dbParams={{
                function: "getSchools4Select",
                controller: "app",
                valueField: "id",
              }}
              updateValue={this.updateValue}
              value={this.state.PrvSchool}
              placeholder="בית ספר"
              cssClass=""
              validationRules={{ required: true }}
              typeInput="searchDB"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
          </div>

          <div className="line selectButtons">
            <label
              className={
                this.state.checkInputsPage && !this.state.sex
                  ? "errorClass"
                  : "1"
              }
            >
              מין
            </label>

            <div className="buttonsCont">
              <button
                onClick={() =>
                  this.setState({ sex: "man", checkInputsPage: false })
                }
                className={
                  this.state.sex === "man" ? "choseBtn selected" : "choseBtn"
                }
              >
                <img src={man} alt="זכר" />
                <span>זכר</span>
              </button>

              <button
                onClick={() =>
                  this.setState({ sex: "woman", checkInputsPage: false })
                }
                className={
                  this.state.sex === "woman" ? "choseBtn selected" : "choseBtn"
                }
              >
                <img src={woman} alt="נקבה" />
                <span>נקבה</span>
              </button>
            </div>
          </div>

          <div className="rows2 clear line">
            <CustomFloatInput
              name="Category"
              selectOptions={CategoryOptions}
              updateValue={this.updateValue}
              value={this.state.Category}
              placeholder="בחירת מסלול התנדבות"
              cssClass=""
              validationRules={{ required: true }}
              typeInput="select"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
            {this.state.dataApi?.years &&
              this.state.dataApi?.years.length > 0 && (
                <CustomFloatInput
                  name="YearYad"
                  selectOptions={this.state.dataApi.years}
                  updateValue={this.updateValue}
                  value={this.state.YearYad}
                  placeholder="שנת שירות"
                  cssClass=""
                  validationRules={{ required: true }}
                  typeInput="select"
                  checkInputs={this.state.checkInputs}
                  checked={() => this.setState({ checkInputs: false })}
                />
              )}
          </div>

          <div className="rows2 clear line">
            <FloatInputPassword
              name="Password"
              updateValue={this.updateValue}
              value={this.state.Password}
              placeholder="סיסמה"
              cssClass=""
              validationRules={{
                required: true,
                minlength: 8,
                equals: "pass2",
              }}
              typeInput="password"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
          </div>

          <div className="line checkboxes">
            <Form.Group className="checkBoxCont" controlId="formBasicCheckbox">
              {/* isInvalid={!this.state.takanon} */}
              <Form.Check.Input
                type={"radio"}
                isInvalid={this.state.checkRadio1 && !this.state.takanon}
                onChange={() => {
                  this.setState({ takanon: true, checkRadio1: false });
                }}
              />

              <Form.Check.Label>
                <span>קראתי את </span>
                <a
                  href="https://sherut-leumi.co.il/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F-%D7%90%D7%AA%D7%A8/"
                  target="_blank"
                  rel="noreferrer"
                >
                  התקנון
                </a>
                <span> אני מאשר/ת את הכתוב</span>
              </Form.Check.Label>
            </Form.Group>

            <Form.Group className="checkBoxCont" controlId="formBasicCheckbox2">
              <Form.Check.Input
                type={"radio"}
                isInvalid={this.state.checkRadio2 && !this.state.rishum}
                onChange={() => {
                  this.setState({ rishum: true, checkRadio2: false });
                }}
              />

              <Form.Check.Label>
                <span>
                  אני מאשר/ת רישום למאגר לקוחות ואני מסכימ/ה לקבל דיוור ללא
                  המילה פרסומות בכותרות
                </span>
              </Form.Check.Label>
            </Form.Group>
          </div>

          <div className="text-center">
            {/* disabled={!flagSendForm} */}
            <Button
              className="sendBlueBtn"
              onClick={() => this.sendForm(sendRules)}
              size="lg"
              variant={
                sendRules
                  ? "success animate__animated animate__bounceIn"
                  : this.state.btnSendClass
              }
            >
              הרשמה
            </Button>

            <div className="singIn">
              <NavLink
                className="jumpPage"
                role="menuitem"
                onClick={() => scroll.scrollTo(0)}
                to={"/login"}
              >
                <span>
                  כבר רשומים? <strong>לחצו להתחברות</strong>
                </span>
              </NavLink>
            </div>
          </div>
        </div>

        {this.state.responseNewRegister &&
        this.state.responseNewRegister.error ? (
          <ModalDefaul
            variant="error"
            params={{
              title: "שגיאה",
              text: this.state.responseNewRegister.error,
            }}
            callBack={() => this.setState({ responseNewRegister: false })}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}
