import React, { Component } from 'react'

import loader from "../../../img/preLoader.gif";
import getDataFromApi from '../../../Components/-Helpers-/api/getDataFromApi';

import SearchResults from './SearchResults'

export default class MySayarot extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {

            sayarotResp : false,
            loading : false,
        }
    }

    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false}, () => {

        }))
    }

    componentDidMount() {

        if(!this.state.sayarotResp) {
            this.getMySayarot();
        }
    }


    getMySayarot = () => {

        let user = JSON.parse(localStorage.getItem('userData'));

        let sendObj = {
            IDNO : user.IDNO,
            SessionKey : user.SessionKey
        }

        const host = window.location.host;
        sendObj.isRotemDev = host.includes("rotem-dev-sherut-leumi.vercel.app") ? true : false;

        this.sendtoAdmin('getMySayarot','sayarot',sendObj,'sayarotResp','sayarot');


    }

    handlechange = (changes) => {

        
        this.props.changeMenuright(changes);

        let myArr = this.state.sayarotResp.sayarot;
        var index = myArr.findIndex(function(o){
            return o.id === changes.id;
        })

       if (index !== -1) myArr.splice(index, 1);


       //console.log(myArr);

       this.setState({sayarotResp : { sayarot : myArr } }, ()=> {

            //console.log(this.state);

       } )

        
    }
    
    
    render() {
        return (

            <div className="SearchResults">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
                
                {this.state.sayarotResp && this.state.sayarotResp.sayarot.length > 0 ? <SearchResults changeMenuright={ (id)=> this.handlechange(id) } sayarotData = {this.state.sayarotResp.sayarot} /> : <h2 className="noResults" >אין תוצאות...</h2> }

            </div>
                
        )
    }


    
}
