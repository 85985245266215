import React, { Component } from 'react'
import { RestUrls } from "../../../Components/-Helpers-/config"

import ShowDataInRowPlaces from './ShowDataInRowPlaces'
import ShowMoreDataPlaces from './ShowMoreDataPlaces'

import GalleryPictures from './GalleryPictures'

//import { Button } from 'react-bootstrap';

import blueBtnInfo from "../../../img/sherut-leumi/svg/sherutPlaces/card/blueBtnInfo.svg";
import favorites from "../../../img/sherut-leumi/svg/sherutPlaces/card/favorites.svg";
import favorites_on from "../../../img/sherut-leumi/svg/sherutPlaces/card/favorites_on.svg";

import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

export default class SearchResultRow extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            showMoreData : this.props.showOpen ? true : false,
            fPlacesArray :  this.props.favoritesPlaces
        }
    }
    

    addRemoveFavorites = (item) => {


        let favoritesPlaces = this.props.favoritesPlaces;
        
        if(!favoritesPlaces.includes(item.id)) {

            favoritesPlaces.push(item.id);

            this.setState({

                fPlacesArray :  favoritesPlaces

            }, ()=> {

                let addfavoritesPlaces = favoritesPlaces.join(',');
                localStorage.setItem('favoritesPlaces',addfavoritesPlaces);

            });

            

        } else {


            var index = favoritesPlaces.findIndex(function(o){
                return o === item.id;
            })

            if (index !== -1) {

                favoritesPlaces.splice(index, 1);
                
                this.setState({
                    fPlacesArray :  favoritesPlaces
                }, ()=> {
                    localStorage.setItem('favoritesPlaces',favoritesPlaces);
                });
                
            }

        }

        this.props.chageFavorites(favoritesPlaces);

        //console.log(favoritesPlaces);

    }
    


    render() {

        const item = this.props.item;
        let urlPic = RestUrls.pagesPictures + '/noPic.jpg?v=1';

        let is_Favorite = this.state.fPlacesArray.includes(item.id) ? true : false;

        return (
            <div className={ is_Favorite ? "searchRow  GreyPanel activeFavorite" : "searchRow  GreyPanel" } >

                <div className='tableSearchRow'>

                    <div className="coltableSearch picCol" >

                        { item.pictures && item.pictures.length > 0 ? < GalleryPictures pictures = {item.pictures} /> : 
                        
                            <figure  style={{backgroundImage : "url('"+urlPic+"')"}} />
                        }
                        
                        

                    </div>

                    <div className="coltableSearch dataCol">
                        
                        <ShowDataInRowPlaces item = {item} />

                    </div>

                    {!isMobile ? <div className="coltableSearch BtnsCol">

                        <div className="sherutPlaceButtons">

                            <button onClick={()=>this.setState({showMoreData : !this.state.showMoreData})} >
                                <img src={ blueBtnInfo } alt='לפרטים נוספים' />
                                <span>{!this.state.showMoreData ? 'לפרטים נוספים' : 'סגירת פרטים נוספים'}</span>
                            
                            </button>

                            <button className={is_Favorite ? 'active' : ''} onClick={ () => this.addRemoveFavorites(this.props.item) }>
                            
                                <img src={ is_Favorite ? favorites_on : favorites } alt='מועדפים' />
                                { is_Favorite ? <span>הסרה ממועדפים</span> : <span>הוספה למועדפים</span> }

                            </button>

                            {/* <button onClick={()=> addBookmark('sebas.com')}>test</button> */}

                        </div>

                    </div> :false }

                </div>

                {isMobile ? <div className="BtnsCol">

                    <div className="sherutPlaceButtons">

                        <button onClick={()=>this.setState({showMoreData : !this.state.showMoreData})} >
                            <img src={ blueBtnInfo } alt='לפרטים נוספים' />
                            <span>{!this.state.showMoreData ? 'לפרטים נוספים' : 'סגירת פרטים '}</span>

                        </button>

                        <button className={is_Favorite ? 'active' : ''} onClick={ () => this.addRemoveFavorites(this.props.item) }>

                            <img src={ favorites } alt='מועדפים' />
                            { is_Favorite ? <span>הסרה ממועדפים</span> : <span>הוספה למועדפים</span> }

                        </button>

                    </div>

                </div> :false }
                {/* && isMobile */}
                { this.state.showMoreData ? <div className="showMoreData mData animate__animated animate__fadeIn" >
                        <ShowMoreDataPlaces item = {item} isMobile={isMobile} />
                    </div> : false }

            </div>
        )
    }
}
