const md5 = require("md5");
let code = "seb-webProject!sherut-leumi!wd+=111@$%+";

//console.log(md5(today));

//const baseApiUrl = 'https://otzarhaaretz.wdev.co.il/api/';
const baseApiUrl = "https://sherut-leumi.wdev.co.il/api/";

export const RestUrls = {
  Code: md5(code),
  baseApiUrl: baseApiUrl,
  sherutLeumiApi: "https://sherut-leumi.org/api/",
  pictures: baseApiUrl + "assets/img/pictures/",
  pagesPictures: baseApiUrl + "assets/img/pages/",
  img: baseApiUrl + "assets/img/",
  appUrl: "https://app.sherut-leumi.co.il",
};
