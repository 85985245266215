import React,{ useState } from 'react';
import { getFromApiSherutLeumi } from '../UserConsole/files/fileFunctions';
import loader from "../../img/preLoader.gif";

export default function PrintView() {

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);  
    const [printDialogShowed, setPrintDialogShowed] = useState(false);

    let userJ = JSON.parse(localStorage.getItem("userData"));

    if(!loading && !responseData  ) {

    const sendObj = {

        idno: userJ.IDNO,
        SessionKey: userJ.SessionKey,
        FormID : 101
    }

    //console.log(sendObj);
    getFromApiSherutLeumi('/v2/volunteer/data/getDocument', sendObj, setLoading, setResponseData);
    //console.log('Loading');

    }

    if(responseData?.data?.Html && !loading ) {
        

        if(!printDialogShowed) {
            setPrintDialogShowed(true);
            setTimeout(() => { setPrintDialogShowed(true); window.print(); }, 200);
            
        }
        

        
        
        
    }


    

  return (
    <div className='printView'>
        <img
                src={loader}
                alt="loader"
                className={
                !loading
                    ? "loader"
                    : "loader active animate__animated animate__fadeIn"
                }
          />

        {responseData?.data?.Html &&  <>
            <div className="showHtml" dangerouslySetInnerHTML={{ __html: responseData?.data?.Html }} /> 
        </>}

        {responseData && !responseData?.data?.Html && <h1 style={{color: 'red', textAlign: 'center', padding: '20px 0' }}>שגיאה</h1>}
        
    </div>
  )
}
