import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { RestUrls } from "../../Components/-Helpers-/config";

import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";
import loader from "../../img/preLoader.gif";

import PersonalInformation from "./userData/PersonalInformation";
import Address from "./userData/Address";
import Emergency from "./userData/Emergency";
import Studies from "./userData/Studies";
import Sherut from "./userData/Sherut";

import pInfo from "../../img/sherut-leumi/svg/userData/pInfo.svg";

import ic2 from "../../img/sherut-leumi/svg/userData/2.svg";
import ic3 from "../../img/sherut-leumi/svg/userData/3.svg";
import ic4 from "../../img/sherut-leumi/svg/userData/4.svg";
import ic5 from "../../img/sherut-leumi/svg/userData/5.svg";
import AvatarPratimMobilePic from "./closeApp/AvatarPratimMobilePic";

export default class UserConsoleDataIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      userData: false,
    };
  }

  

  getUserData = () => {
    let user = JSON.parse(localStorage.getItem("userData"));

    let sendObj = {
      idno: user.IDNO,
      SessionKey: user.SessionKey,
    };

    this.sendtoAdmin("getUserData", "users", sendObj, "userData", "users");
  };

  componentDidMount() {
    if (!this.state.userData) {
      this.getUserData();
    }
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, () => {
        //console.log(getData.userData);
      })
    );
  };

  

  render() {

    //console.log(this.state.userData);

    const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
    const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=2";

    const isMobile = this.props.siteInfo.isMobile
      ? this.props.siteInfo.isMobile
      : false;

    return (
      <div className="DataSection">
        <img
          className="topPic full_width"
          src={isMobile ? m_topPic : topPic}
          alt="top"
        />

        {!!isMobile && < AvatarPratimMobilePic /> }

        <header>
          <h1>פרטים אישיים</h1>
          <p>תודה שבחרת להתנדב איתנו!</p>
        </header>

        <div className="AccordionCont">
          <img
            src={loader}
            alt="loader"
            className={
              !this.state.loading
                ? "loader"
                : "loader active animate__animated animate__fadeIn"
            }
          />

          {this.state.userData ? (
            <div>
              <Accordion className="accordions acc1" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={pInfo} alt="פרטים אישיים" />
                    <span>פרטים אישיים</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <PersonalInformation
                      isMobile={isMobile}
                      userData={this.state.userData.userData}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="accordions acc2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={ic2} alt="כתובת" />
                    <span>כתובת</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Address
                      isMobile={isMobile}
                      userData={this.state.userData.userData}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="accordions acc3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={ic3} alt="פרטים לשעת חירום" />
                    <span>פרטים לשעת חירום</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Emergency
                      isMobile={isMobile}
                      userData={this.state.userData.userData}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="accordions acc4" style={{position: 'relative', zIndex: '10'}}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={ic4} alt="לימודים והשכלה" />
                    <span>לימודים והשכלה</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Studies
                      isMobile={isMobile}
                      userData={this.state.userData.userData}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="accordions acc5">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={ic5} alt="שירות לאומי" />
                    <span>שירות לאומי</span>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Sherut
                      isMobile={isMobile}
                      userData={this.state.userData.userData}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}
