import { map } from 'lodash';
import React, { Fragment, useEffect } from 'react'
import { Accordion, Form } from 'react-bootstrap'
import left from "../../img/icons/clockPage/left.svg";
import note from '../../img/icons/clockPage/note.svg'
import { checkhasPerutDivuach } from './clockFunctions';
import {getTypeRecord, getTypeRecordsList} from './editClockFunctions';

export default function OpenCLoseTextAreaReport(props) {

    const {dayItem, isEdit } = props

    const typeRecord = getTypeRecord(dayItem?.api?.Typ);
    const text = (dayItem?.api?.MoreInfo && dayItem?.api?.MoreInfo !== 'False') ? dayItem.api.MoreInfo : '';



    //dayItem?.api?.MoreInfo

  return (
        <Fragment>

            {isEdit ? <div className='OpenCLoseTextAreaReport isEdit'>
                    <div className='accordion-body'>
                        <EditMe {...props} text = {text} /> 
                    </div>
                </div>
            :

            <Accordion defaultActiveKey="0" className={`OpenCLoseTextAreaReport`}>

                <Accordion.Item eventKey="01">

                    <Accordion.Header >
                        { isEdit ? <span>פרטי הדיווח</span> : <span>צפייה בפירוט הדיווח</span> }
                        <img src={left} alt='left' style={{marginRight : '10px'}}/>
                        <img src={left} alt='left'/>
                    </Accordion.Header>

                    <Accordion.Body>
                        <div>
                            <h3><strong>סוג דיווח: </strong>{typeRecord}</h3>
                            {text && <p className='text'><strong>פירוט הדיווח: </strong>{text}</p>}
                        </div>
                    </Accordion.Body>

                </Accordion.Item>

            </Accordion> }
        </Fragment>
  )
}

function EditMe(props) {

    const {text, dayItem, selectedReportType, setSelectedReportType, textAreaReport, setTextAreaReport} = props

    useEffect(() => {
      
        setSelectedReportType(dayItem?.api?.Typ);
        setTextAreaReport(text)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
  
        //console.log('selectedReportType', selectedReportType);
        if(!text) {

            if(selectedReportType === '2') {
        
                setTextAreaReport('הכשרת שרות לאומי');
        
            } else if(selectedReportType === '3') {
        
                setTextAreaReport('נוכחות מחוץ למקום השרות');
        
            } else {
        
                setTextAreaReport('');
            
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReportType])
    

    const reportList = getTypeRecordsList();

    return (<div className='editCont'>
        
        <div className='selectCont'>

            <img src={note} alt='note' />

            <Form.Control 
                as="select"
                className={"chooseReportSelect"}
                //isInvalid={showSelectError}
                //disabled={MaakavStatus?.api?.IsCurrIn}
                /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                //type={ config.typeInput }
                //name='reportType'
                onChange={(e) => setSelectedReportType(e.target.value)} 
                value={selectedReportType}
                //onFocus={this.populateSelect}
            >
                <option value="">סוג דיווח</option>
                
                {map(reportList, item => {

                    return(<option value={item.id} key= {item.id} >{item.name}</option>)

                })}

            </Form.Control>

        </div>
        
        {( checkhasPerutDivuach(selectedReportType,textAreaReport) ) && <div className='textAreaCont'>
            <Form.Control 
                as="textarea"
                className={"textAreaReport"}
                //disabled={MaakavStatus?.api?.IsCurrIn}
                //isInvalid={showSelectError}
                /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                //type={ config.typeInput }
                //name='reportType'
                onChange={(e) => setTextAreaReport(e.target.value)} 
                value={textAreaReport}
                placeholder='פירוט דיווח'
                //onFocus={this.populateSelect}
            />

        </div> }

        

    </div>)

}