import React, { useState } from "react";
import SearchUpPlaces from "./includes/SearchUpPlaces";

export default function MekomotSherutSearchOnly(props) {
  const { isMobile } = props;

  const [clearMulti, setClearMulti] = useState(false);

  const updateStateFromSearch = (searchState) => {
    let urlPath = `?city=${searchState.City}`;
    urlPath = `${urlPath}&maslol=${searchState.Maslol}`;
    urlPath = `${urlPath}&thum=${String(searchState.Thum)}`;
    urlPath = `${urlPath}&year=${searchState.YEAR}`;
    urlPath = `${urlPath}&zonename=${searchState.ZoneName}`;
    urlPath = `${urlPath}&freesearch=${searchState.freeSearch}`;
    //urlPath =  encodeURIComponent(urlPath);

    const pageUrl =
      "https://sherut-leumi.co.il/%D7%9E%D7%A7%D7%95%D7%9E%D7%95%D7%AA-%D7%A9%D7%99%D7%A8%D7%95%D7%AA";

    if (searchState.clearMulti && searchState.clearMulti !== clearMulti) {
      setClearMulti(searchState.clearMulti);
    } else {
      window.open(pageUrl + urlPath, "_blank");
    }

    //console.log(urlPath);
    //console.log('searchState', searchState);
  };

  return (
    <div className={`${isMobile ? "Mobile" : ""} MekomotSherutIndex`}>
      <div className="SherutPlacesIndex">
        <SearchUpPlaces
          currentState={false}
          mobile={isMobile}
          closeSearch={false}
          updateSearch={(searchState) => updateStateFromSearch(searchState)}
        />
      </div>
    </div>
  );
}
