import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';

import { Carousel } from 'react-bootstrap';

import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

export default class ModalDefaul extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalShow : true
        }
    }
    
    modalShow = (value) => {
        
        this.setState({ modalShow : value });

        if(this.props.callBack) {
            this.props.callBack();
        }
        
    }

    render() {

        
        let allPictures = this.props.pictures;
        let activeKey =  this.props.currentState.firstPic;


        return (
            <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite modalPictures' }
                animation={false}
                onHide={() => this.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.state.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body className={ isMobile ? 'mobileBody' : '' }>

                    <Button className="closeBtn" onClick={() => this.modalShow(false)} variant="secondary" >X</Button>

                    < div className='galleryAlbum' >

                        {/* <span style={ { color : 'white' }} >{ activeKey } </span> */}
                        
                        <Carousel controls = { allPictures.length > 1 ? true : false }  >

                            <Carousel.Item >

                                <img

                                src={allPictures[activeKey].url + '600'}
                                alt={allPictures[activeKey].alt}

                                />

                            </Carousel.Item>

                            { allPictures.map( item => { if (item.key !== activeKey) {
                                
                                return  <Carousel.Item key={item.key} >

                                    <img

                                    

                                    src={item.url + '600'}
                                    alt={item.alt}

                                    />
                                    
                                </Carousel.Item>

                            } else return false}) }

                        </Carousel>

                    </div>

                </Modal.Body>
            </Modal>
        )
    }
}
