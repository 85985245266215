import React, {useState} from 'react'

//import fileBgQuad from '../../../img/sherut-leumi/svg/files/fileBgQuad.png';

//import x from '../../../img/sherut-leumi/svg/files/x.svg';
import v from '../../../img/sherut-leumi/svg/files/v.svg';
import signature from '../../../img/sherut-leumi/svg/files/signature.svg';

import { Modal, Button } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
//import UploadFile from './UploadFile';
import {Form,FloatingLabel} from 'react-bootstrap' //npm install react-bootstrap@next bootstrap@5.1.0

//import validator from "validator";
//import { toast } from 'react-toastify'

import { getFromApiSherutLeumi, sendReadAndSignToClientApi, sendReadAndSignToClientApiStep2 } from './fileFunctions';
import loader from "../../../img/preLoader.gif";

export default function ReadAndSign(props) {

    const {formData, texts, userJ } = props;
    
    const [showFirstModal, setShowFirstModal ] = useState(false);
    const [enterSMSCodeModal, setEnterSMSCodeModal ] = useState(false);

    const [smsCodeInput, setSmsCodeInput ] = useState('');

    const [loading, setLoading] = useState(false);

    /* const [formInputs, setFormInputs] = useState({
      name: '',
      surname: '',
      tz: ''
    }); */

    const [formTextData, setFormTextData] = useState(false);

    if(showFirstModal && !loading && !formTextData) {

      
      const sendObj = {

          IDNumber: userJ.IDNO,
          SessionKey: userJ.SessionKey,
          FormID: formData.FormID
          /* idno: userJ.IDNO,
          SessionKey: userJ.SessionKey,
          FormID: formData.FormID */
      }

      //console.log('sendObj >',  sendObj);

      //getFromApiSherutLeumi('/v2/volunteer/data/getDocument', sendObj, setLoading, setFormTextData);
      getFromApiSherutLeumi('/v1/volunteer/form/getContent', sendObj, setLoading, setFormTextData);


    }

  
    //console.log('formTextData >', formTextData);

    //console.log('formData > ',formData);
    //console.log('userJ > ', userJ );
    //console.log('texts > ',texts);


    const openMyModal = () => { 

      setShowFirstModal(true);

    }

    const clearData = () => { 

      /* setFormInputs({
          name: '',
          surname: '',
          tz: ''
      }); */
    }

    const showStep2Modal = () => { 

      setShowFirstModal(false);
      setEnterSMSCodeModal(true);

    }

    const sendForm = () => { 

      //console.log(formInputs);

      const sendData = {

        IDNumber : userJ.IDNO,
        SessionKey :  userJ.SessionKey,
        formId : formData.FormID

      }

      setLoading(true);
      sendReadAndSignToClientApi(sendData,setLoading,clearData, showStep2Modal )

    }

    const sendVerificationCode = () => { 

      //console.log(smsCodeInput);

      const sendData = {

        IDNumber : userJ.IDNO,
        SessionKey :  userJ.SessionKey,
        formId : formData.FormID,
        smsCodeInput : smsCodeInput
    }

    setLoading(true);
    sendReadAndSignToClientApiStep2(sendData,setLoading, setEnterSMSCodeModal );


    }

    
    
  return (
    <div className='fileItem'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        <div className='header' >
            <h3>
              <span>{texts.title}</span>
            </h3>
            <p>{texts.filesType}</p>
        </div>

        { formData.Status !== "Missing" ? <div className='blueQuad docExist fullBorder' >
            <img src={v} alt='המסמך קיים במערכת' />
            <p>המסמך קיים במערכת</p>
        </div> : <div className='blueQuad externalLink fullBorder'>
            <div className='linkP'>קריאה וחתימה</div>
            <button className='readAndSignBtn' onClick={ openMyModal } >
              <img src={signature} alt='signature' />
            </button>
        </div> }
        

        { formTextData?.data?.Html && <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite filesModal' }
                animation={false}
                onHide={() => setShowFirstModal(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={showFirstModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
            <Modal.Body>

                <Button className="closeBtn" onClick={() => setShowFirstModal(false)} variant="secondary" >X</Button>

                
                <div className="Message">
                    
                    <h2>{texts.title}</h2>

                </div>

                <div className='textReadAndSign'>
                    
                    <div dangerouslySetInnerHTML={{ __html: formTextData.data.Html }} />

                </div>

                <Grid className="formCont" container spacing={2}  alignItems="center" direction="row" justifyContent="center" >
                    <Grid item md={5} >
                      <button className='blueSignBtn' onClick={sendForm} >אישור קריאה וחתימה</button>
                    </Grid>
                </Grid>


                {/* <Grid className="formCont" container spacing={2}  alignItems="center" direction="row" justifyContent="center" >
                
                  <Grid item md={3} >

                    <div className='input'>
                      <FloatingLabel label='שם פרטי'>
                        <Form.Control 
                            className={"customFloatInput " }
                            type='text'
                            disabled={false}
                            placeholder='שם פרטי'
                            onChange={e=>setFormInputs({ ...formInputs, name : e.target.value })}
                            value={formInputs.name}
                        />
                      </FloatingLabel>
                    </div>
                  </Grid>

                  
                  <Grid item md={3} >

                    <div className='input'>
                      <FloatingLabel label='שם משפחה'>
                        <Form.Control 
                            className={"customFloatInput " }
                            
                            type='text'
                            disabled={false}
                            placeholder='שם משפחה'
                            onChange={e=>setFormInputs({ ...formInputs, surname : e.target.value })}
                            value={formInputs.surname}
                        />
                      </FloatingLabel>
                    </div>
                  </Grid>

                  
                  <Grid item md={3} >

                    <div className='input'>
                      <FloatingLabel label='ת.ז'>
                        <Form.Control 
                            className={"customFloatInput " }
                            type='text'
                            disabled={false}
                            placeholder='ת.ז'
                            onChange={e=>setFormInputs({ ...formInputs, tz : e.target.value })}
                            value={formInputs.tz}
                        />
                      </FloatingLabel>
                    </div>
                  </Grid>

                  <Grid item md={3} >
                    <button className='blueSignBtn' onClick={sendForm} >אישור קריאה וחתימה</button>
                  </Grid>
                        
                </Grid> */}

                

            </Modal.Body>
        </Modal> }


        <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite filesModal SMSCodeModal' }
                animation={false}
                onHide={() => setEnterSMSCodeModal(false)}
                size="sm" // בגודל קטן!!! */
                //size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={enterSMSCodeModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
            <Modal.Body>

                <Button className="closeBtn" onClick={() => setEnterSMSCodeModal(false)} variant="secondary" >X</Button>

                <div className="Message">
                    
                    <h2>הכנסו את הקוד שקיבלתם ב-SMS</h2>

                </div>

                <Grid container spacing={2}  alignItems="center" direction="row" justifyContent="center" >
                
                  {/* שם פרטי */}
                  <Grid item md={12} >

                    
                      <FloatingLabel label='קוד SMS'>
                        <Form.Control 
                            className={"customFloatInput " }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type='text'
                            disabled={false}
                            placeholder='קוד SMS'
                            onChange={e=>setSmsCodeInput(e.target.value)}
                            value={smsCodeInput}
                        />
                      </FloatingLabel>
                    
                  </Grid>

                </Grid>

                <Grid container spacing={2}  alignItems="center" direction="row" justifyContent="center" >

                  <Grid item md={6} >
                    <button className='blueBtn' onClick={sendVerificationCode} >בדיקת קוד</button>
                  </Grid>
                        
                </Grid>

            </Modal.Body>
        </Modal>

    </div>
  )
}
