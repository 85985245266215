import React, { Component } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import getDataFromApi from '../../-Helpers-/api/getDataFromApi';

// https://www.npmjs.com/package/react-search-autocomplete
//  npm install react-search-autocomplete

export default class SearchAutocompleteAndDbFocus extends Component {
    


    constructor(props) {

        super(props);

        this.state = {
            searchItems : []
        };

    }


    handleOnSelect = (item) => {
      // the item selected
      //console.log(item);
      this.props.submit(item);
    } 
  
    handleOnFocus = () => {

      const dbParams = this.props.dbParams;
      //console.log(dbParams);

      this.sendtoAdmin( dbParams.functionName, dbParams.controller,{},'searchItems','all');
      
    }


    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

      this.setState({loading : true});

      const getData = getDataFromApi(url,objectToSend,controller,auth);

      getData.then(getData =>this.setState({ [setStateName] : getData.options,  loading : false, showInput : true }, function(){
                  //console.log(getData.options);
                  //console.log(this.state.searchItems);
      }));

  }

    render() {

        return (
            <ReactSearchAutocomplete
                    fuseOptions={{ keys: this.props.keys }}
                    /* fuseOptions={{ keys: ["name", "description"] }} */
                    placeholder = {this.props.placeholder}
                    items={this.state.searchItems}
                    onFocus={this.handleOnFocus}
                    onSelect={this.handleOnSelect}
                    showClear={false}
                    maxResults={200}
                    /* onSearch={handleOnSearch}
                    autoFocus */
            />
        )
    }
}
