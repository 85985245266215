import React, { Component } from "react";
import HomePage from "./../../Pages/HomePage";

import RegisterIndex from "./../../Pages/Register/RegisterIndex";

import UserConsoleSearchIndex from "../../Pages/UserConsole/UserConsoleSearchIndex";
import UserConsoleDataIndex from "../../Pages/UserConsole/UserConsoleDataIndex";
import SherutPlacesIndex from "../../Pages/MekomotSherut/SherutPlacesIndex";
import FilesPage from "../../Pages/UserConsole/files/FilesPage";
import ClockInOutIndex from "../../Pages/clockInOut/ClockInOutIndex";
import TrainingIndex from "../../Pages/training/TrainingIndex";
import SortiesIndex from "../../Pages/sorties/SortiesIndex";
import ContactUsIndex from "../../Pages/contactUs/ContactUsIndex";

import AvatarEditPage from "../../Pages/UserConsole/closeApp/AvatarEditPage";

//import Page404  from './pages/Page404';

export default class ContentWrapper extends Component {
  /*  constructor(props) {
    super(props);
    console.log(props.page);
  } */

  // Camera permissions

  componentDidMount() {
    // if (navigator.mediaDevices.getUserMedia !== null) {

    // }

    window.addEventListener(
      "message",
      (event) => {
        let options = {
          video: true,
        };
        navigator.getUserMedia(
          options,
          function (stream) {
            //console.log(stream, "streaming");
          },
          function (e) {
            //console.log("background error : " + e.name);
          }
        );
      },
      false
    );

    // window.addEventListener("message", (event) => {
    //   const data = JSON.parse(event.data);
    //   // Access the properties sent from React Native WebView
    //   const isCameraEnabled = data.isCameraEnabled;
    //   // Do something with the data
    //   if (!isCameraEnabled) {
    //     let options = {
    //       video: true,
    //     };
    //     navigator.getUserMedia(
    //       options,
    //       function (stream) {
    //         console.log(stream, "streaming");
    //       },
    //       function (e) {
    //         console.log("background error : " + e.name);
    //       }
    //     );
    //   }
    // });
    // const { pathname } = this.props.location;
    // check if the user logged in or at login register or resend password pageBreakInside
    const isLoggedIn =
      (localStorage.getItem("userData") &&
        localStorage.getItem("userData").length === 0) ||
      this.props.page === "login" ||
      this.props.page === "register" ||
      this.props.page === "reSendPass"
        ? false
        : true;

    // send ReactNativePostRequest boolean Depend on the outcome
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ isLoggedIn: "" + isLoggedIn })
      );
    } else {
      window?.postMessage(JSON.stringify({ isLoggedIn: "" + isLoggedIn }));
    }
    const { Category, InService } =
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length !== 0
        ? JSON.parse(localStorage.getItem("userData"))
        : { Category: "null", InService: "null" };

    //console.log("Current User: ", Category, InService, isLoggedIn);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          Category: Category ? "" + Category : +"2",
          InService: "" + InService,
        })
      );
    } else {
      window?.postMessage(
        JSON.stringify({
          Category: Category ? "" + Category : +"2",
          InService: "" + InService,
        })
      );
    }
  }

  render() {
    const host = window.location.host;
    const isDevUser =
      host.includes("localhost:") ||
      host.includes(".vercel.app") ||
      host.includes("10.0.0.192:") ||
      host.includes(".sherut-leumi.co.il") ||
      host.includes(".github.dev")
        ? true
        : false;

    //console.log('isDevUser >', isDevUser);

    //if (isDevUser && localStorage.getItem("userData")) {
    //let userJ = JSON.parse(localStorage.getItem("userData"));
    //console.log("SessionKey", userJ.SessionKey);

    //console.log('userJ', userJ)
    //}

    switch (this.props.page) {
      case "index":
        return (
          <React.Fragment>
            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
            {/* {console.log(this.props.info.user)} */}
            <HomePage siteInfo={this.props} />
          </React.Fragment>
        );

      case "register":
        return (
          <React.Fragment>
            <RegisterIndex siteInfo={this.props} page="register" />
          </React.Fragment>
        );

      case "login":
        return (
          <React.Fragment>
            <RegisterIndex siteInfo={this.props} page="login" />
          </React.Fragment>
        );

      case "loginQueryUrl":
        return (
          <React.Fragment>
            <RegisterIndex siteInfo={this.props} page="loginQueryUrl" />
          </React.Fragment>
        );

      case "reSendPass":
        return (
          <React.Fragment>
            <RegisterIndex siteInfo={this.props} page="reSendPass" />
          </React.Fragment>
        );

      /* USER CONSOLE!!! */
      case "userIndex":
        return (
          <React.Fragment>
            <UserConsoleSearchIndex
              siteInfo={this.props}
              changeMenuright={(callBack) =>
                this.props.changeMenuright(callBack)
              }
            />
          </React.Fragment>
        );

      case "userSearch":
        return (
          <React.Fragment>
            <UserConsoleSearchIndex siteInfo={this.props} />
          </React.Fragment>
        );

      case "userData":
        return (
          <React.Fragment>
            <UserConsoleDataIndex siteInfo={this.props} />
          </React.Fragment>
        );

      case "files":
        return (
          <React.Fragment>
            <FilesPage siteInfo={this.props} />
          </React.Fragment>
        );

      case "clockInOutIndex":
        return (
          <React.Fragment>
            {isDevUser ? (
              <ClockInOutIndex siteInfo={this.props} />
            ) : (
              <h2 style={{ color: "red" }}>שגיאה</h2>
            )}
          </React.Fragment>
        );

      case "training":
        return (
          <React.Fragment>
            {isDevUser ? (
              <TrainingIndex siteInfo={this.props} />
            ) : (
              <h2 style={{ color: "red" }}>שגיאה</h2>
            )}
          </React.Fragment>
        );

      case "sorties":
        return (
          <React.Fragment>
            <SortiesIndex siteInfo={this.props} />
          </React.Fragment>
        );
      case "contactUs":
        return (
          <React.Fragment>
            <ContactUsIndex siteInfo={this.props} />
          </React.Fragment>
        );

      case "editAvatar":
        return (
          <React.Fragment>
            <AvatarEditPage siteInfo={this.props} />
          </React.Fragment>
        );
        
      /* sherutPlaces!!! */
      case "sherutPlacesIndex":
        return (
          <React.Fragment>
            <SherutPlacesIndex siteInfo={this.props} />
          </React.Fragment>
        );

      default:
        return <div>NO PAGE</div>;
    }
  }
}
