import React, { useState } from "react";

import axios from "axios";
import { useDropzone } from "react-dropzone"; /* https://react-dropzone.js.org/ */
//import { toast } from 'react-toastify'

import { RestUrls } from "../../../Components/-Helpers-/config";

//import NoPic from './../../../img/sherut-leumi/pic.png';
import NoPic from "./../../../img/sherut-leumi/svg/files/pdfDOC.png?v=1";

import { Row, Col, ProgressBar, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { checkDatesAPI, sendFileFormToClientApi } from "./fileFunctions";

import loader from "../../../img/preLoader.gif";

import fileBgQuad from "../../../img/sherut-leumi/svg/files/fileBgQuad.png";
import x from "../../../img/sherut-leumi/svg/files/x.svg";
import infoModal from "../../../img/sherut-leumi/svg/files/infoModal.svg";
import trash from "../../../img/sherut-leumi/svg/files/trash.svg";
import success from "../../../img/sherut-leumi/svg/files/success.svg";

import face from "../../../img/sherut-leumi/svg/files/formsPic/face.jpg";
import tz from "../../../img/sherut-leumi/svg/files/formsPic/tz.jpg";
import sign from "../../../img/sherut-leumi/svg/files/formsPic/sign.jpg";

import { Grid } from "@material-ui/core";

export default function UploadFile(props) {
  const { userJ, formData, texts } = props;

  const [picUrl, setPicUrl] = useState(false);
  const [isPicture, setIsPicture] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState(false);

  const [finalSendFile, setFinalSendFile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [datesInput, setDatesInput] = useState({
    StartDate: "",
    EndDate: "",
  });

  const backgroundQuad = "url('" + fileBgQuad + "')";

  const clearData = () => {
    setPicUrl(false);
    setIsPicture(false);
    setLoading(false);
    setFinalSendFile(false);
    setDatesOk(false);
    setDatesInput({ StartDate: "", EndDate: "" });
  };

  const onDrop = (acceptedFiles) => {
    let file = acceptedFiles[0];
    let filename = (file?.name).toLowerCase();

    //setFilenameState(filename);
    //console.log(file);
    //const text = file?.type + ' | ' + file?.size + ' | ';
    //setFilenameState2 (text);

    setPicUrl(URL.createObjectURL(file));
    setShowFirstModal(false);

    if (
      !filename.includes(".pdf") &&
      !(
        filename.includes(".jpg") ||
        filename.includes(".jpeg") ||
        filename.includes(".png")
      )
    ) {
      toast.error("יש להעלות קבצים: " + texts.filesType);
      clearData();
      return true;
    }

    if (
      !filename.includes(".pdf") &&
      (filename.includes(".jpg") ||
        filename.includes(".jpeg") ||
        filename.includes(".png"))
    ) {
      setIsPicture(true);
    } else {
      //console.log(filename);
      setIsPicture(false);
    }

    uploadImage(file);
  };

  //, isDragActive
  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'image/jpeg, image/png',
    noKeyboard: true,
    //maxFiles:1,
    //maxSize:4096,
    multiple: false,
    onDrop,
  });

  const uploadImage = (file) => {
    setFinalSendFile(file);
  };
  const sendMyFile = () => {
    fileUploadHandler();
  };

  const fileUploadHandler = () => {
    const sendData = {
      IDNumber: userJ.IDNO,
      SessionKey: userJ.SessionKey,
      formId: formData.FormID,
      file: finalSendFile,
    };

    if (formData.FormID !== "10") {
      sendFileFormToClientApi(
        sendData,
        setLoading,
        clearData,
        setShowThanksModal
      );
    } else {
      //ONLY FORM ID 10 MAHALA DATES

      const fd = new FormData();

      fd.append("StartDate", datesInput.StartDate);
      fd.append("EndDate", datesInput.EndDate);

      axios
        .post(RestUrls.baseApiUrl + "/documents/getDates", fd, {
          onUploadProgress: (progressEvent) => {
            setUploadProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        })
        .then((res) => {
          //console.log(res.data);

          if (res.data.error) {
            toast.error(res.data.error);
            clearData();
          } else if (res?.data?.dates) {
            sendData.dates = res.data.dates;
            sendFileFormToClientApi(
              sendData,
              setLoading,
              clearData,
              setShowThanksModal
            );
          } else {
            toast.error("שגיאה");
          }

          //console.log(res.data);
        })

        .catch((error) => {
          setLoading(false);
          toast.error("שגיאה");
          console.log(error);
        })

        .finally(() => {
          setUploadProgress(null);
        });
    }
  };

  const openMyModal = () => {
    setShowFirstModal(true);
  };

  const [datesOk, setDatesOk] = useState(false);

  const checkDates = () => {
    checkDatesAPI(datesInput, setDatesOk, setLoading);
  };

  return (
    <>
      <Row className="fileCont">
        <img
          src={loader}
          alt="loader"
          className={
            !loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        <Col md="12">
          {!picUrl ? (
            <div
              className="blueQuad"
              style={{ backgroundImage: backgroundQuad }}
            >
              <div className="docNoExist">
                <img src={x} alt="לא הועלה קובץ" />
                <p>המסמך לא קיים</p>
                <button className="blueBtn" onClick={openMyModal}>
                  העלאת קובץ
                </button>
              </div>
            </div>
          ) : (
            <div className="picturePreview">
              <img
                className={`noDrag ${!picUrl && "noPic"}`}
                src={picUrl && isPicture ? picUrl : NoPic}
                alt=""
              />

              <Grid
                className="btnCont"
                container
                spacing={0}
                alignItems="center"
                direction="row"
                justifyContent="center"
              >
                <Grid item md={3}>
                  <img src={trash} alt="delete" onClick={clearData} />
                </Grid>
                <Grid item md={8}>
                  <button
                    className="greenBtn animate__animated animate__bounceIn"
                    onClick={sendMyFile}
                  >
                    שליחת קובץ
                  </button>
                </Grid>
              </Grid>
            </div>
          )}
        </Col>
      </Row>

      {uploadProgress && <ProgressBar now={uploadProgress} />}

      {formData?.FormID !== "10" && (
        <Modal
          className={
            "animate__animated animate__fadeInDown animate__faster modalSite filesModal"
          }
          animation={false}
          onHide={() => setShowFirstModal(false)}
          /* size="sm" // בגודל קטן!!! */
          //size="lg" // גדול!! */
          // backdrop="static" // חייב ללחוץ על כפתור!
          /* centered // VERTICA CENTER */
          show={showFirstModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <Button
              className="closeBtn"
              onClick={() => setShowFirstModal(false)}
              variant="secondary"
            >
              X
            </Button>

            <div className="Message">
              <img src={infoModal} alt={texts.startText} />
              <h2>{texts.startText}</h2>
            </div>

            <figure>
              {formData?.FormID === "1" && (
                <img src={tz} alt="" style={{ margin: "0 0 -20px 0" }} />
              )}
              {formData?.FormID === "4" && (
                <img src={face} alt="" style={{ margin: "0" }} />
              )}
              {(formData?.FormID === "6" || formData?.FormID === "14") && (
                <img src={sign} alt="" style={{ margin: "0" }} />
              )}
            </figure>

            <Grid
              className="btnCont"
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              <Grid item md={6}>
                <div className="user-file" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="blueBtn">הבנתי</div>
                </div>
              </Grid>
            </Grid>
          </Modal.Body>
        </Modal>
      )}

      {/* ishur mahala modal */}
      {formData?.FormID === "10" && (
        <Modal
          className={
            "animate__animated animate__fadeInDown animate__faster modalSite filesModal ishurMahalaModal"
          }
          animation={false}
          onHide={() => setShowFirstModal(false)}
          /* size="sm" // בגודל קטן!!! */
          //size="lg" // גדול!! */
          // backdrop="static" // חייב ללחוץ על כפתור!
          /* centered // VERTICA CENTER */
          show={showFirstModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <Button
              className="closeBtn"
              onClick={() => setShowFirstModal(false)}
              variant="secondary"
            >
              X
            </Button>

            <div className="Message">
              {/* <img src={infoModal} alt={texts.startText} /> */}
              <h2>{texts.startText}</h2>
            </div>

            {!datesOk?.ok && (
              <div className="inputs">
                <Grid
                  className="btnCont"
                  container
                  spacing={2}
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item md={6}>
                    <h4>תאריך התחלה</h4>
                    <Form.Control
                      className="customFloatInput"
                      /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                      type="date"
                      placeholder={""}
                      onChange={(e) =>
                        setDatesInput({
                          ...datesInput,
                          StartDate: e.target.value,
                        })
                      }
                      value={datesInput.StartDate}
                      /* onChange={this.handleChange} 
                            value={this.state.inputValue} */
                    />
                  </Grid>

                  <Grid item md={6}>
                    <h4>תאריך סיום</h4>
                    <Form.Control
                      className="customFloatInput"
                      /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                      type="date"
                      placeholder={""}
                      onChange={(e) =>
                        setDatesInput({
                          ...datesInput,
                          EndDate: e.target.value,
                        })
                      }
                      value={datesInput.EndDate}
                      /* onChange={this.handleChange} 
                            value={this.state.inputValue} */
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid
              className="btnCont"
              container
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              {datesOk?.error && (
                <Grid item md={12}>
                  <p className="errorDates">{datesOk.error}</p>
                </Grid>
              )}

              {datesOk?.ok && (
                <Grid item md={12}>
                  {/* {console.log(datesOk)} */}

                  {datesOk?.warning && (
                    <p className="errorDates warningDates">{datesOk.warning}</p>
                  )}

                  <p className="errorDates okDates">
                    מספר ימים באישור הוא: {datesOk.dates.SikDays}
                    <span
                      onClick={() => {
                        setDatesOk(false);
                      }}
                    >
                      &laquo; לבחירה חוזרת
                    </span>
                  </p>
                </Grid>
              )}

              <Grid item md={6}>
                {datesInput.StartDate && datesInput.EndDate && datesOk?.ok ? (
                  <div
                    className="user-file animate__animated animate__bounceIn"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="blueBtn">בחירת קובץ</div>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={checkDates}
                      disabled={!datesInput.StartDate || !datesInput.EndDate}
                      className={`blueBtn ${
                        !datesInput.StartDate || !datesInput.EndDate
                          ? "disabled"
                          : ""
                      }`}
                    >
                      המשך &raquo;
                    </button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Modal.Body>
        </Modal>
      )}

      {/* showThanksModal, setShowThanksModal */}
      <Modal
        className={
          "animate__animated animate__fadeInDown animate__faster modalSite filesModal thanksFile"
        }
        animation={false}
        onHide={() => setShowThanksModal(false)}
        /* size="sm" // בגודל קטן!!! */
        //size="lg" // גדול!! */
        // backdrop="static" // חייב ללחוץ על כפתור!
        /* centered // VERTICA CENTER */
        show={showThanksModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <Button
            className="closeBtn"
            onClick={() => setShowThanksModal(false)}
            variant="secondary"
          >
            X
          </Button>

          <div className="Message">
            <img src={success} alt={texts.startText} />
            <h2>{texts.thankText}</h2>
            <p>{texts.thankpText}</p>
          </div>

          <Grid
            className="btnCont"
            container
            spacing={2}
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <Grid item md={6}>
              <div
                className="blueBtn"
                onClick={() => setShowThanksModal(false)}
              >
                סגירה
              </div>
            </Grid>

            {/*  <Grid item md={2} >
                        <Button variant="secondary" style={{width: '100%'}} className="closeBtnBig" onClick={() => setShowFirstModal(false)} >סגירה</Button>
                    </Grid> */}
          </Grid>
        </Modal.Body>
      </Modal>
    </>
  );
}
