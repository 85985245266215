import React from "react";
import { RestUrls } from "../../Components/-Helpers-/config";
import Lottie from "react-lottie-player";
import lottieJson from "./lottie.json";

function SortiesIndex(props) {
  const { siteInfo } = props;

  //console.log(props);

  const topPic = RestUrls.pagesPictures + "/search/searchTop.jpg?v=1";
  const m_topPic = RestUrls.pagesPictures + "/register/m_registerTop.jpg?v=2";
  //const m_topPic = RestUrls.pagesPictures + "/register/m_clockTop.jpg?v=1";
  const isMobile = siteInfo.isMobile;

  return (
    <div className="DataSection trainingPageIndex">
      <img
        className="topPic full_width"
        src={isMobile ? m_topPic : topPic}
        alt="top"
      />

      <div
        style={{
          display: "grid",
          placeItems: "center",
          height: "50vh",
          padding: "3rem 0",
        }}
      >
        <h1>מיונים</h1>
        <br />
        <h1>מסך זה נמצא בבניה</h1>

        <Lottie
          style={{ width: 351, height: 351 }}
          loop
          animationData={lottieJson}
          play
        />
      </div>
    </div>
  );
}

export default SortiesIndex;
