import React from "react";

import { filesTexts, objectsMyforms } from "./fileFunctions";
import FileItem from "./FileItem";
import { Grid } from "@material-ui/core";
import ExternalLink from "./ExternalLink";
import ReadAndSign from "./ReadAndSign";

//console.log(filesTexts);

export default function DocsTromSherut(props) {
  const { forms } = props;

  const objectForm = objectsMyforms(forms);
  console.log(":forms", forms);
  console.log("objectForm", objectForm);
  let userJ = JSON.parse(localStorage.getItem("userData"));

  //console.log('objectForm >', objectForm);

  return (
    <div className="docsCont">
      <Grid
        className="lineForm"
        container
        spacing={2}
        alignItems="top"
        direction="row"
        justifyContent="center"
      >
        {objectForm.FormID1 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID1}
              texts={filesTexts.tz}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID2 && (
          <Grid item md={3}>
            <ReadAndSign
              formData={objectForm.FormID2}
              texts={filesTexts.takanon}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID3 && (
          <Grid item md={3}>
            <ReadAndSign
              formData={objectForm.FormID3}
              texts={filesTexts.dira}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID4 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID4}
              texts={filesTexts.facePicture}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID5 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID5}
              texts={filesTexts.medicalCertificate}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID6 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID6}
              texts={filesTexts.militar}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID9 && (
          <Grid item md={3}>
            <ReadAndSign
              formData={objectForm.FormID9}
              texts={filesTexts.ipuiCoach}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID11 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID11}
              texts={filesTexts.bank}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID12 && (
          <Grid item md={3}>
            <ReadAndSign
              formData={objectForm.FormID12}
              texts={filesTexts.generalCharge}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID13 && (
          <Grid item md={3}>
            <ReadAndSign
              formData={objectForm.FormID13}
              texts={filesTexts.gius}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID14 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID14}
              texts={filesTexts.custodian}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID15 && (
          <Grid item md={3}>
            <ExternalLink
              formData={objectForm.FormID15}
              texts={filesTexts.question}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID21 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID21}
              texts={filesTexts.formId21}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID22 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID22}
              texts={filesTexts.formId22}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID23 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID23}
              texts={filesTexts.formId23}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID24 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID24}
              texts={filesTexts.formId24}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID25 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID25}
              texts={filesTexts.formId25}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID26 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID26}
              texts={filesTexts.formId26}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID27 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID27}
              texts={filesTexts.formId27}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID28 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID28}
              texts={filesTexts.formId28}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID20 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID20}
              texts={filesTexts.formId20}
              userJ={userJ}
            />
          </Grid>
        )}

        {objectForm.FormID99 && (
          <Grid item md={3}>
            <FileItem
              formData={objectForm.FormID99}
              texts={filesTexts.klali}
              userJ={userJ}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
