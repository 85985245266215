import React, { Component } from "react";
import CustomFloatInput from "../../Components/-Helpers-/forms/CustomFloatInput";
import { RestUrls } from "../../Components/-Helpers-/config";

import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";

import { Button } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0
import loader from "../../img/preLoader.gif";

import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";

import ModalDefaul from "../../Components/-Helpers-/ModalDefaul";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IDNO: "",
      loading: false,
      responseLogin: false,
      ReactNativeWebView: window.ReactNativeWebView,
    };
  }
  componentDidMount() {
    console.log(window.ReactNativeWebView, "window.ReactNativeWebView");
  }
  updateValue = (newValue) => {
    this.setState(newValue, () => {
      //console.log(this.state);
    });
  };

  sendForm = (sendRules) => {
    this.setState({
      loading: false,
    });

    if (sendRules) {
      this.setState({
        loading: true,
      });

      const sendObj = {
        IDNO: this.state.IDNO,
      };

      //console.log(sendObj);
      this.sendtoAdmin(
        "forgotPassword",
        "register",
        sendObj,
        "responseLogin",
        "register"
      );

      //console.log(sendObj);
    } else {
      this.setState({
        btnSendClass: "danger",
      });
    }
  };

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    console.log("sending...");

    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);
    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, function () {
        console.log(getData);

        if (getData.ok) {
          //localStorage.setItem('userData',JSON.stringify(getData.ok));
          alert("הסיסמא נשלחה לנייד שלך");
          window.open("/login", "_self");
        }

        /* if(url === 'reportBuilder') {
                
            } */
      })
    );
  };

  componentDidMount() {
    window.addEventListener("message", this.handleIframeMessage, false);
}
componentWillUnmount() {
  window.removeEventListener("message", this.handleIframeMessage, false);
}


handleIframeMessage = (event) => {
    // Optional: Check the origin of the message for security
    // if (event.origin !== "https://sherut-mfa-2.vercel.app") return;

    // Handle the message
    if (event.data.passwordResetSuccess) {
        console.log("Password reset was successful.");
        window.location.href = 'https://app.sherut-leumi.co.il/login';

        // Here you can add any logic you want to execute upon receiving this message
    }
};


  goBack = () => {
    // Implement your back functionality here
    // For example, you could use window.history.back(); to go to the previous page
    window.history.back();
  };

  

  render() {
    const sendRules = this.state.IDNO;

    const isMobile = this.props.siteInfo.isMobile
      ? this.props.siteInfo.isMobile
      : false;
    let m_picTop = RestUrls.pagesPictures + "register/m_registerTop.jpg?v=3";

    return (
      <div className="firstRegister loginPage ForgotPassword animate__animated animate__fadeIn">
        <button onClick={this.goBack} className="closeButton">X</button> {/* Close button */}

        <img
          src={loader}
          alt="loader"
          className={
            !this.state.loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        {isMobile ? <img className="topPic" src={m_picTop} alt="top" /> : false}

        <header>
          <h1 className="boldTypeFamily">שכחת סיסמה?</h1>
          <p>הזן תעודת זהות והסיסמה תשלח אליך לשחזור</p>
        </header>

        <iframe
          width="100%"
          height="300px"
          style={{ overflow: 'hidden' }}
          src="https://sherut-mfa-2.vercel.app/"
          // src="http://localhost:5173/"
          className="w-full h-600 border-0 overflow-hidden"
          sc="true"
          
        ></iframe>

        
        <div className="inputs">
          {/* <div className="line">
            <CustomFloatInput
              name="IDNO"
              updateValue={this.updateValue}
              value={this.state.IDNO}
              placeholder="תעודת זהות"
              cssClass=""
              validationRules={{ required: true, tz: true }}
              typeInput="text"
              checkInputs={this.state.checkInputs}
              checked={() => this.setState({ checkInputs: false })}
            />
          </div> */}

          <div style={{ textAlign: "center" }}>
            <br />
            {/* <Button
              disabled={this.state.loading ? true : false}
              className="sendBlueBtn"
              onClick={() => this.sendForm(sendRules)}
              size="lg"
              variant={
                sendRules
                  ? "success animate__animated animate__bounceIn"
                  : this.state.btnSendClass
              }
            >
              שלח סיסמה לנייד...
            </Button> */}
          </div>
        </div>

        {window.ReactNativeWebView === undefined && (
          <div className="text-center">
            <div className="singIn">
              <NavLink
                className="jumpPage"
                role="menuitem"
                onClick={() => scroll.scrollTo(0)}
                to={"/register"}
              >
                <span>
                  עדין לא רשומים? <strong>לחצו להרשמה (נרשמים חדשים)</strong>
                </span>
              </NavLink>
            </div>
          </div>
        )}
        {this.state.responseLogin && this.state.responseLogin.error ? (
          <ModalDefaul
            variant="error"
            params={{ title: "שגיאה", text: this.state.responseLogin.error }}
            callBack={() => this.setState({ responseLogin: false })}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}
