import React, { Fragment, useState } from 'react'
import {Form} from 'react-bootstrap' //npm install react-bootstrap@next bootstrap@5.1.0
import { map } from 'lodash';
import { dateFromString } from '../clockInOut/editClockFunctions';
import Switch from '@material-ui/core/Switch';
import { useEffect } from 'react';
import { entryTraining, getTraning, iWillBeThere  } from './traningFunctions';
import loader from "../../img/preLoader.gif";
import moment from 'moment';
import { toast } from 'react-toastify';
//import Dummy from './dummy.json';


export default function TraningPage() {

    //const [items, setItems] = useState(Dummy);
    const [items, setItems] = useState(false);
    const [loading, setLoading] = useState(false);

    let userJ = JSON.parse(localStorage.getItem("userData"));

    /* setMaakavStatus */
  useEffect(() => {    
    loadTranings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function loadTranings() {
    
    const sendObj = {idno: userJ.IDNO,SessionKey: userJ.SessionKey,}
    getTraning('v2/volunteer/training/dates', sendObj, setLoading,setItems);
  }


  const reloadItems = () => {
    setItems([]);
    //console.log('reload!');
    loadTranings();
    
   }

  return (
    <div className='traningPage'>

        <header className="clear">
            <h1>רשימת הכשרות</h1>
        </header>

        <img  src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

        <ul className='list'>

           {map(items, item => {
                return(<Item key={ item.id } item={ item } reloadItems = {reloadItems} setLoading = {setLoading} />)
            })}
            
        </ul>

    </div>
  )
}

function Item(props) {

    const { item, setLoading, reloadItems } = props;

    let dateShow = '';

    if(item?.StartDate !== item?.EndDate) {
        dateShow = `${dateFromString(item.StartDate)} עד ${dateFromString(item.EndDate)}`;
    } else {
        dateShow = dateFromString(item.StartDate);
    }
    
    //const today = moment().format("YYYYMMDD");
    //const isToday = today === item?.Days[0]?.Date ? true : false;

    let userJ = JSON.parse(localStorage.getItem("userData"));
    
    const dbValue = item?.Days[0]?.rsvp;
    const [arrive, setArrive] = useState(false);

    useEffect(() => {

        if( dbValue === 'TRUE' ) {

            setArrive('אגיע');

        } else if( dbValue === 'FALSE' ) {

            setArrive('לא אגיע');

        } else {

            setArrive('');

        }

      
    }, [dbValue])
    
    //const date =dateFromStringObj(item?.Days[0]?.Date);

    var a = moment(item?.Days[0]?.Date);
    var b = moment(moment().format("YYYY") + moment().format("MM") + moment().format("DD"));
    let difference = a.diff(b, 'days');

    //difference = 3;

    //console.log(difference);

    const sendValue = (e) => {

        let valueChange = null;

        if(e.target.value === 'אגיע') {
            valueChange = "TRUE";
        } else if (e.target.value === 'לא אגיע') {
            valueChange = "FALSE";
        }

        const sendObj = {
            idno: userJ.IDNO,
            SessionKey: userJ.SessionKey,
            TrainingID: item?.TrainingID,
            RSVP: valueChange

        }

        console.log(sendObj);

        const valueName = e.target.value;
        
        if(difference <= 3 && (arrive === 'אגיע' && valueName === 'לא אגיע')) {

            toast.error('לא ניתן לבטל את אישור ההגעה. נא לפנות לרכז/ת');

        } else {
            setArrive(valueName);            
            iWillBeThere('v2/volunteer/training/rsvp', sendObj, setLoading, reloadItems );

        }


        console.log('difference', difference);
        

    }

    return(
        <li className={`${(item.rsvpRequired && difference > 0) ? 'rsvpRequired' : 'no_rsvpRequired'}`}>

            <div className='text'>
                <h3>{item.Title}</h3>
                {(item.rsvpRequired && difference > 0) && <Form.Control //&& !isToday
                    as="select"
                    className={"chooseArrive"}
                    //isInvalid={showSelectError && !selectedReportType }
                    //disabled={MaakavStatus?.api?.IsCurrIn}
                    /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                    //type={ config.typeInput }
                    //name='reportType'
                    onChange={(e) => sendValue(e) } 
                    value={arrive}
                    //onFocus={this.populateSelect}
                >
                    <option value="">מגיע/ה?</option>
                    <option value="אגיע">אגיע</option>
                    <option value="לא אגיע">לא אגיע</option>

                </Form.Control> }
            </div>

            

            <div className='flexCont'>

                    {map(item.Days, day => {

                    return(<Day key={ day.Date } day={day} {...props} />)

                    })}
                
            </div>
            
            <p className='showDates'>{dateShow}</p>
        </li>
    )

}

function Day(props) {

    const { day,item,setLoading, reloadItems } = props
    const [isArrived, setIsArrived] = useState(day?.isArrived);

    const today = moment().format("YYYYMMDD");
    //const today = '20220921';
    const isToday = today === day?.Date ? true : false;

    let userJ = JSON.parse(localStorage.getItem("userData"));

    

    //console.log('arrive', arrive);
    

    //ENTRY!
    const handleChange = () => { 

        const sendObj = {
            idno: userJ.IDNO,
            SessionKey: userJ.SessionKey,
            TrainingID: item?.TrainingID

        }


        entryTraining('v2/volunteer/training/entry', sendObj, setLoading, setIsArrived, reloadItems );
        

    }

    

    return (
        <Fragment>
            { isToday && <div className='day'>
                
                {/* <p>הגעת ב-{dateFromString(day?.Date)}?</p> */}
                <p>{dateFromString(day?.Date)}</p>

                <div className='switch'>
                    <span>הגעתי: </span>
                    <Switch
                    checked={isArrived}
                    onChange={ handleChange }
                    color="primary"
                    name="checkedB"
                    disabled={isArrived}
                    inputProps={{ 'aria-label': 'primary checkbox' }}/>
                </div>

            </div> }
        </Fragment>
    )

}
