import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/animateNew.css"; /* https://daneden.github.io/animate.css/ */

import "./css/site.css";
import "./css/site_pages.css";
import "./css/mobile.css";

//https://getbootstrap.com/ //npm install bootstrap@next

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; //npm install react-router-dom

import GetPage from "./Components/loginPages/getPage";

//import IdleTimer from 'react-idle-timer' //time out timmer
//npm install react-idle-timer --save
import { ToastContainer } from "react-toastify"; //npm i react-toastify
import "react-toastify/dist/ReactToastify.css";

import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

export default class App extends Component {
  render() {
    //localStorage.setItem('user-info','');
    //console.log('Current User: ' + localStorage.getItem('user-info'));
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path={"/"} component={GetPage} />
          </Switch>
        </Router>

        <ToastContainer
          limit={1}
          position={isMobile ? "top-center" : "top-left"}
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={true}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={true}
          theme="colored"
        />
      </React.Fragment>
    );
  }
}
