import React, { Component } from 'react'
import ContentWrapper from './ContentWrapper'
export default class Pages extends Component {
    render() {


        if(this.props.isMobile) {

            return (<div className="body-container Mobile">

                <h2>MENU MOBILE</h2>
                <h1>Mobile PAGE</h1>
                <h2>FOOTER MOBILE</h2>

            </div>)

        }

        else {

            return (<div className="body-container Desktop">

                <h2>MENU Desktop</h2>
                    <ContentWrapper {...this.props} />
                <h2>FOOTER Desktop</h2>
                    
            </div>)

        }
        
    }
}
