import React, {useState} from 'react'

//import fileBgQuad from '../../../img/sherut-leumi/svg/files/fileBgQuad.png';

//import x from '../../../img/sherut-leumi/svg/files/x.svg';
import v from '../../../img/sherut-leumi/svg/files/v.svg';
import link from '../../../img/sherut-leumi/svg/files/link.svg';
import { Modal, Button } from 'react-bootstrap';
import infoModal from '../../../img/sherut-leumi/svg/files/infoModal.svg';
import { Grid } from '@material-ui/core';
//import UploadFile from './UploadFile';


export default function ExternalLink(props) {

    const {formData, texts} = props;
    const [showFirstModal, setShowFirstModal ] = useState(false);

    //console.log('formData > ',formData);
    //console.log('texts > ',texts);

    //const backgroundQuad = "url('" + fileBgQuad + "')";

    const openMyModal = () => { 

      setShowFirstModal(true);

    }

     
    const gotoUrl = () => { 

      setShowFirstModal(false);

      const url = formData?.URL ? formData.URL : '#';

      window.open(url,'_blank')

    }

  return (
    <div className='fileItem'>
        <div className='header' >
            <h3>
              <img src={link} alt='link' />
              <span>{texts.title}</span>
            </h3>
            <p>{texts.filesType}</p>
        </div>

        { formData.Status !== "Missing" ? <div className='blueQuad docExist fullBorder'>
            <img src={v} alt='המסמך קיים במערכת' />
            <p>המסמך קיים במערכת</p>
        </div> : <div className='blueQuad externalLink fullBorder' >
            <div className='linkP'>(לינק חיצוני)</div>
            <button className='blueBtn' onClick={ openMyModal } >{texts.btnName}</button>
        </div> }

        <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite filesModal' }
                animation={false}
                onHide={() => setShowFirstModal(false)}
                /* size="sm" // בגודל קטן!!! */
                //size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={showFirstModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
            <Modal.Body>

                <Button className="closeBtn" onClick={() => setShowFirstModal(false)} variant="secondary" >X</Button>

                <div className="Message">
                    <img src={infoModal} alt={texts.startText} />
                    <h2>{texts.startText}</h2>
                    {texts?.startTextP && <p>{texts.startTextP}</p>}
                </div>

                <Grid className="btnCont" container spacing={2}  alignItems="center" direction="row" justifyContent="center" >
                
                    <Grid item md={6} >

                      <div className='blueBtn' onClick={ gotoUrl }  >{texts.btnName}</div>

                    </Grid>


                </Grid>

            </Modal.Body>
        </Modal>

    </div>
  )
}
