import React, { Fragment } from 'react'
//import { getAllUrlParams } from '../../../Components/-Helpers-/UrlParameters';

export default function CloseAppIframe(props) {

    //console.log('props', props);
    
    let user = JSON.parse(localStorage.getItem('userData'));
    //const urlParams = getAllUrlParams(window.location.search);

    /* if( !checkUserIframe(user) || !urlParams?.showcloseapp) {
        return false;
    } */

    //http://localhost:3000/userConsole/data?showcloseApp=1

    //console.log('props', props);
    console.log('user', user);
    //console.log('urlParams', urlParams);

    let iframeSrc = 'https://closeapp.co.il/apps/test/sherut-leumi/indexTest.html';

    iframeSrc += '?idno=' + user?.IDNO;
    //iframeSrc += '&sessionKey=' + user?.SessionKey;
    iframeSrc += '&status=1';
    iframeSrc += '&action=edit';
    iframeSrc += '&page=' + props?.page;
    iframeSrc += '&fullName=' + user?.FirstName + ' ' + user?.LastName;
    iframeSrc += '&imageUrl=' + user?.ImageUrl;


    let iframeSrc2 = 'https://closeapp.co.il/apps/test/sherut-leumi/indexTest.html';

    iframeSrc2 += '?idno=' + user?.IDNO;
    //iframeSrc += '&sessionKey=' + user?.SessionKey;
    iframeSrc2 += '&status=1';
    iframeSrc2 += '&action=popup';
    iframeSrc2 += '&page=' + props?.page;
    iframeSrc2 += '&fullName=' + user?.FirstName + ' ' + user?.LastName;
    iframeSrc2 += '&imageUrl=' + user?.ImageUrl;

  return (
    <Fragment>
      < iframe title="closeApp" className='closeAppIframe' src={ iframeSrc } />
      {/* < iframe title="closeApp popup" className='closeAppIframe popup' src={ iframeSrc2 } /> */}
    </Fragment>
  )

} 

function checkUserIframe(user) {

    
   /*  if( user?.IDNO !== '324504620' ) {
        return false;
    } */

    return true;

}