import { RestUrls } from "../../Components/-Helpers-/config";
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from "moment";   //npm install moment
import validator from "validator";
//import { map } from "lodash";

export function getFromApiSherutLeumi(url,sendObj,setLoading, setResponseData) {


    setLoading(true);
    
    const json = JSON.stringify(  sendObj  );
    
    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            setLoading(false);
            //console.log(res);
            setResponseData(res);
        
        })
        
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */
            
    


}


export function getClockApiMaakavStatus(url,sendObj,setLoading,
    setResponseData, setSelectedReportType, setTextAreaReport) {


    setLoading(true);
    
    const json = JSON.stringify(  sendObj  );
    console.log("URL:",RestUrls.sherutLeumiApi + url);
    console.log("json",json);
    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            setLoading(false);
            //console.log(res);

            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                setResponseData(false);
                return toast.error('שגיאה בקריאת סטטוס');
            }

            //res.data.IsCurrIn = false;

            if(res?.data?.IsCurrIn) {
                setSelectedReportType(res?.data?.Typ); 
                setTextAreaReport(res?.data?.MoreInfo);
            } else {
                setSelectedReportType(false); //res?.data?.Typ
                setTextAreaReport(false);
            }

            let timeValue = '00:00:00';

            let timeAllValues = {
                hours : '00',
                minutes :  '00',
            }

            if(res?.data?.IsCurrIn && res?.data?.TimeF) {

                
                const hours = res.data.TimeF.substring(0, 2);
                const minutes = res.data.TimeF.substring(2, 4);

                timeValue = hours + ':' + minutes;

                timeAllValues = {
                    hours : parseInt(hours),
                    minutes :  parseInt(minutes)
                }

                const weekday = new Date().toLocaleDateString('he', {
                    //day: 'numeric',
                    weekday: 'long',
                    //month: 'long',
                    //year: '2-digit',
                });
    
                const dayValue = new Date().toLocaleDateString('he', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                });
    
    
                setResponseData({
                    api : res?.data,
                    time : timeValue,
                    timeAllValues : timeAllValues,
                    weekday : weekday,
                    day: dayValue,
                })

            }

            
        
        })
        
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}


export function getClockApiMaakavOut(url,sendObj,setLoading,
    setResponseData, setSelectedReportType, setTextAreaReport, setPercentWorked, setLastExit = false) {


    setLoading(true);
    
    const json = JSON.stringify(  sendObj  );
    
    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            setLoading(false);
            //console.log(res.data);

            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                //setResponseData(false);
                return toast.error('שגיאה');
            } else {
                setResponseData(false);
                setSelectedReportType(false);
                setTextAreaReport(false);
                setPercentWorked(false);
                toast.success('היציאה התקבלה בהצלחה');

                if(setLastExit) {
                    setLastExit(moment().format("H:mm"))
                }

            }
        
        })
        
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}

//NOT IN USE
// eslint-disable-next-line no-unused-vars
function setLastExitValue(setLoading, setLastExit) {

    
    setLoading(true);

    let userJ = JSON.parse(localStorage.getItem("userData"));

    const url = 'v2/volunteer/Maakav';

    const sendObj = {
        idno: userJ.IDNO,
        SessionKey: userJ.SessionKey,
        Date: moment().format("YYYYMM") //DD
    }

    const json = JSON.stringify(  sendObj  );
    
    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {
            setLoading(false);
            console.log(res);

            if(!res?.data?.Result || res?.data?.Result !== 'Success') {
                return toast.error('שגיאה בקריאת סטטוס');
            }

            if(!res?.data?.Shib[0]) {
                toast.error('לא נמצאו נתונים');
                return false;

            } else {

                //populateMonthData(res.data.Shib[0],setResponseData,setDaysMonthData);

            }

            

        
        })
        
        .catch((error) => {
            setLoading(false);
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
    

}


export function getClockApiMaakavIn(url,sendObj,setLoading,setResponseData,setSelectedReportType,
    setTextAreaReport ) {


    setLoading(true);
    
    const json = JSON.stringify(  sendObj  );
    
    axios.post( RestUrls.sherutLeumiApi + url, json, {

        headers: {'Content-Type': 'application/json'},
        
    })
        .then( res => {

            setLoading(false);
            //console.log(res.data);

            if(!res?.data?.Result || res?.data?.Result !== 'Success'  ) {
                //setResponseData(false);
                return toast.error('שגיאה');
            } 

            else if(res?.data?.ErrorMessage === 'No maakav record'  ) {
                //setResponseData(false);
                return toast.error('שגיאה.  לא קיים מעקב.');
            } 
            
            
            else {
                toast.success('הכניסה התקבלה בהצלחה');
                let userJ = JSON.parse(localStorage.getItem("userData"));
                const sendObj = {IDNumber: userJ.IDNO,SessionKey: userJ.SessionKey,}

                getClockApiMaakavStatus('v2/volunteer/MaakavStatus', sendObj, setLoading,
                            setResponseData, setSelectedReportType,
                            setTextAreaReport);


            }
        
        })
        
        .catch((error) => {
            setLoading(false);
            setResponseData({});
            toast.error('שגיאה', {
                toastId: 'error'
              });
            console.log(error)
        })
        /* 
        .finally(() => {
            setLoading(false);
        }) */

}


export function showCurrentMonthName(monthNumber,currentYear, action = false) {

    const startDate = `${currentYear}-${monthNumber}-1`;

    //tikun Iphone: .toString().replace(/-/g, '/')

    let monthYear = '';

    
    if(action === 'monthNameOnly') {

        monthYear = new Date(startDate.toString().replace(/-/g, '/')).toLocaleDateString('he', {
            //day: '2-digit',
            month: 'long',
            //year: 'numeric',
        });

    } else {

        monthYear = new Date(startDate.toString().replace(/-/g, '/')).toLocaleDateString('he', {
            //day: '2-digit',
            month: 'long',
            year: 'numeric',
        });

    }

    if(monthYear !== 'Invalid Date') {
        return monthYear
    } else {
        toast.error('שגיאה');
        return '---';
    }

    

}

export function checkShowDays(monthData,item) {

    const nowMonth = parseInt(new Date().getMonth() + 1);
    const nowYear = parseInt(new Date().getFullYear());
    const nowDay = parseInt(new Date().toLocaleDateString('he', {day: 'numeric'}));

    const itemMonth = parseInt(monthData?.Month);
    const itemYear = parseInt(monthData?.Year);
    const itemDay = parseInt(item?.dayData?.DOM);
    
    if( itemDay > nowDay && (itemMonth === nowMonth && nowYear === itemYear) ) {

        return false;

    } else {

        return true;

    }

    //console.log(nowMonth);
    //console.log(itemMonth);

}

//isActiveMonth - isEditMode
export function changeDay(action,currentMonth, setCurrentMonth, currentYear, setCurrentYear, monthData = false) {

    const nowMonth = parseInt(new Date().getMonth() + 1);
    const nowYear = parseInt(new Date().getFullYear());
    
    //const nowDay = new Date().toLocaleDateString('he', {day: 'numeric'});
    //const lastDayNumberForEdit = 1;

    //console.log(currentMonth);
    //console.log(currentYear);
    //console.log('nowMonth', nowMonth);
    //console.log('nowYear', nowYear); 

    let flagMore = false;

    if( nowYear === currentYear) {

         flagMore = currentMonth < nowMonth ? true: false;

    } else if( nowYear > currentYear) {

        flagMore = true;

    }
    

    if(action === 'checkActive') {

        return !flagMore;


    } else if(action === 'checkEditMode') {

        /* if( ( nowMonth === currentMonth &&  monthData?.isOpen ) ||

            (   monthData?.isOpen && (nowMonth - 1 === currentMonth) &&
                nowDay <= lastDayNumberForEdit && nowYear === currentYear )
            )

        {
            return true;

        } else return false */

        if(!monthData.ReportConfirmationDate) {
            return true;
        } else {
            return false;
        }

    
    } else if(action === 'back') {

        if(currentMonth > 1) {
            setCurrentMonth( currentMonth - 1);
        } else {
            //console.log(currentYear);
            setCurrentYear( currentYear - 1);
            setCurrentMonth(12);
        }


    } else if(action === 'forward') {

        

        if(flagMore) {

            if(currentMonth > 0 && currentMonth < 12) {
                setCurrentMonth( currentMonth + 1);
            } else {
                console.log(currentMonth);
                setCurrentYear( currentYear + 1);
                setCurrentMonth(1);
            }

        } else {

            //toast.error('אין חודשים עתידיים');
            

        }
        
        

    }


}

//'פירוט דיווח'
export function checkhasPerutDivuach(selectedReportType,textAreaReport) {

    if (  ( ( selectedReportType === '2' || selectedReportType === '3' ) )
    
          || (textAreaReport && textAreaReport !== 'False' )
          
        ) {
        
       
        return true
       
    }
    
    else {

        return false
    }

}

export function checkTextAreaReportContent(selectedReportType,textAreaReport) {

    if(checkhasPerutDivuach(selectedReportType,textAreaReport)) {

        
        let regExp = /^[A-Za-z0-9]*$/;


        //return false;
        if( textAreaReport && validator.isNumeric(textAreaReport)   ) {

            toast.error('יש לרשום גם מלל' );
            return false;

        }

        else if( regExp.test(textAreaReport) ) {

            toast.error('אסור מילוי רק תווים באנגלית' );
            return false;

        }
        
        else { 

            return true;
        }

    } else {

        return true;
        
    }

}