import React, { Component } from "react";
import { getAllUrlParams } from "../../Components/-Helpers-/UrlParameters";
import { RestUrls } from "../../Components/-Helpers-/config";

import SearchUpPlaces from "./includes/SearchUpPlaces";
import SearchResultsPlaces from "./includes/SearchResultsPlaces";
import InfiniteScroll from "react-infinite-scroller";
import loader from "../../img/preLoader.gif";

import Fab from "@mui/material/Fab";
import SearchIcon from "@mui/icons-material/Search";

import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */

//import favorites from "../../img/sherut-leumi/svg/sherutPlaces/card/favorites.svg";

import FavoriteButton from "./includes/FavoriteButton";
import ShowInfoPopUp from "./ShowInfoPopUp";

export default class SherutPlacesIndex extends Component {
  constructor(props) {
    super(props);

    let urlQuery = this.props.siteInfo.search
      ? getAllUrlParams(this.props.siteInfo.search)
      : false;
    //console.log(this.props);

    const urlParams = getAllUrlParams(window.location.search);
    //console.log("urlParams", urlParams);

    this.state = {
      loading: false,

      freeSearch: urlParams?.freesearch ? decodeURI(urlParams.freesearch) : "",
      Maslol: urlParams?.maslol ? decodeURI(urlParams.maslol) : "",
      YEAR: urlParams?.year ? decodeURI(urlParams.year) : 'תשפ"ד', //default YEAR
      Thum:
        urlParams?.thum && urlParams?.thum !== "null"
          ? decodeURI(urlParams.thum).split(",")
          : [],

      rakaz: "",

      City: urlParams?.city ? urlParams.city : "",
      ZoneName: urlParams?.zonename ? urlParams.zonename : "",

      orderBy: "",

      searchFilters: false,

      items: [],
      hasMoreItems: true,
      nextHref: 0,
      sayarIdQuery: urlQuery && urlQuery.id ? urlQuery.id : "",

      pageName: "sayarot",

      allmekSherut: "0",

      favoritesPlaces: localStorage.getItem("favoritesPlaces")
        ? localStorage.getItem("favoritesPlaces").split(",")
        : [],
      showfavoritesPlaces: false,
    };
  }

  updateStateFromSearch = (searchState) => {
    console.log("searchState", searchState);

    this.setState({
      items: [],
      hasMoreItems: true,
      nextHref: 0,
    });

    this.setState(searchState, () => {
      //console.log(this.state);
      //this.getSayarot();
    });
  };

  handleChangeSelect = (event) => {
    //console.log(event);

    this.setState({ [event.target.name]: event.target.value }, function () {
      this.setState({
        items: [],
        hasMoreItems: true,
        nextHref: 0,
      });

      //this.loadItems()
      //this.updateStateFromSearch(this.state);
    });
  };

  showFavoritesOnly = (param) => {
    this.setState(
      {
        showfavoritesPlaces: param,
      },
      () => {
        this.setState({
          items: [],
          hasMoreItems: true,
          nextHref: 0,
        });
      }
    );
  };

  loadItems() {
    var self = this;
    const API = RestUrls.baseApiUrl + "/MekSherut/getMekSherut";

    const axios = require("axios").default; /* npm install axios */

    //axios.get(API)

    let sendObj = {
      auth: "mekSherut",
      token: RestUrls.Code,
      page: this.state.nextHref,

      freeSearch: this.state.freeSearch,
      year: this.state.YEAR,
      City_Value: this.state.City,
      City_Zone: this.state.ZoneName,
      Thum_Key: this.state.Thum,
      Maslol: this.state.Maslol,

      sayarIdQuery: this.state.sayarIdQuery,

      favoritesPlaces: this.state.favoritesPlaces.join(","),
      showfavoritesPlaces: this.state.showfavoritesPlaces,

      openCloseSearchMobile: false,

      orderBy: this.state.orderBy,
    };

    //console.log(sendObj);

    //console.log(sendObj.page);

    let body = JSON.stringify(sendObj);

    axios
      .post(API, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })

      .then((res) => {
        const data = res.data;

        //console.log(data);

        if (res.data) {
          if (data.items[0] && data.items[0].id) {
            var items = self.state.items;

            let uniqueIds = new Set(); // To track unique IDs
            data.items.forEach((item) => {
              console.log(item, "itemitemitem")
                if (!uniqueIds.has(item.id)) {
                    uniqueIds.add(item.id);
                    items.push(item);
                }
            });

            self.setState({
              allmekSherut: data.allmekSherut,
              items: items,
              hasMoreItems: true,
              nextHref: this.state.nextHref + 1,
            });
          } else {
            console.log("done");
            self.setState({
              hasMoreItems: false,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err, "error");
        alert(err, "שdגיאה");
        console.warn(err);
      });
  }

  closeSearchMobile = (params) => {
    this.setState({ openCloseSearchMobile: false }, () => {
      //scroll.scrollToTop({duration : 0});
      //scroll.scrollTo('toSearchResults');
      scroll.scrollTo(600);
    });
  };

  render() {
    let topPic = RestUrls.pagesPictures + "/sherutPlaces/topSherut.jpg?v=3";
    const m_topPic = RestUrls.pagesPictures + "/sherutPlaces/topSherut.jpg?v=3";

    const isMobile = this.props.siteInfo.isMobile
      ? this.props.siteInfo.isMobile
      : false;

    const loading = (
      <img
        src={loader}
        key={1231}
        alt="loader"
        className={"loaderInfiniteScroll animate__animated animate__fadeIn"}
      />
    );

    let showFavoritesBtn = this.state.favoritesPlaces.length > 0 ? true : false;

    return (
      <div className="SherutPlacesIndex">
        <img
          className="topPic full_width"
          src={isMobile ? m_topPic : topPic}
          alt="top"
        />

        <div className="btnsUp">
          <a href="/userConsole" target="_blank" rel="noopener noreferrer">
            <span>סיירות האגודה להתנדבות &raquo;</span>
          </a>
        </div>

        <h1>מקומות השרות באגודה להתנדבות - לבחירתך!</h1>

        <div className="placesContainer">
          <div className="SearchCategoty">
            <FavoriteButton
              allResults={this.state.allmekSherut}
              is_disabled={
                !this.state.sayarIdQuery && showFavoritesBtn ? true : false
              }
              is_all={this.state.showfavoritesPlaces}
              showFavoritesOnly={(param) => this.showFavoritesOnly(param)}
            />
          </div>

          <ShowInfoPopUp />

          {!this.state.sayarIdQuery && !this.state.showfavoritesPlaces ? (
            <div className="SearchUp">
              <SearchUpPlaces
                mobile={isMobile}
                closeSearch={this.closeSearchMobile}
                currentState={this.state}
                updateSearch={(searchState) =>
                  this.updateStateFromSearch(searchState)
                }
              />
            </div>
          ) : (
            false
          )}

          {/* -------- MOBILE!!!! ------ */}

          {isMobile && !this.state.sayarIdQuery ? (
            <Fab
              className="mobileSearchBtnFloat searchMobileCont animate__animated animate__bounceIn"
              color="primary"
              aria-label="חיפוש"
              onClick={() =>
                this.setState({
                  openCloseSearchMobile: !this.state.openCloseSearchMobile,
                })
              }
            >
              <SearchIcon />
            </Fab>
          ) : (
            false
          )}

          {isMobile &&
          !this.state.sayarIdQuery &&
          this.state.openCloseSearchMobile ? (
            <div className="searchMobileOpenClose animate__animated animate__fadeInDown animate__faster">
              <button
                className="openCloseSearchBtn xBtn"
                onClick={() => this.setState({ openCloseSearchMobile: false })}
              >
                <span className="closeMe">X</span>
              </button>

              {this.state.openCloseSearchMobile ? (
                <div className="searchMobileCont =">
                  <SearchUpPlaces
                    mobile={isMobile}
                    closeSearch={this.closeSearchMobile}
                    updateSearch={(searchState) =>
                      this.updateStateFromSearch(searchState)
                    }
                  />
                </div>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}

          {/* -------- END MOBILE!!!! ------ */}

          {this.state.sayarIdQuery ? (
            <div className="shareHeader">
              {/* <h2>
                סיירת: <small>#</small>
                {this.state.items[0] ? this.state.items[0].ContractNo : ""}
              </h2> */}
              <a className="bounce" href="/sherutPlaces">
                &laquo; חזרה למקומות השירות
              </a>
            </div>
          ) : (
            false
          )}

          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadItems.bind(this)}
            hasMore={this.state.hasMoreItems}
            loader={loading}
          >
            <div className="SearchResults" name="toSearchResults">
              {this.state.items && this.state.items.length > 0 ? (
                <SearchResultsPlaces
                  showOpen={this.state.sayarIdQuery ? true : false}
                  items={this.state.items}
                  favoritesPlaces={this.state.favoritesPlaces}
                  chageFavorites={(chageFavorites) =>
                    this.setState({ favoritesPlaces: chageFavorites })
                  }
                />
              ) : (
                <h2 className="noResults">אין תוצאות...</h2>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
