import React, { Component } from "react";
import { MultiSelect } from "react-multi-select-component";

//https://github.com/hc-oss/react-multi-select-component/tree/7949a0ab64e31a6de17b7a057f8b3e8a4ed2e04e

//npm i react-multi-select-component
//https://www.npmjs.com/package/react-multi-select-component

export default class MultiSelectReact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      clearMulti: false,
    };
  }

  componentDidUpdate() {
    if (this.state.clearMulti !== this.props.clearMulti) {
      this.setState({
        selected: [],
        clearMulti: this.props.clearMulti,
      });
    }
  }

  componentDidMount() {
    if (this.props?.currentState?.Thum) {
      let allParams = [];

      this.props?.currentState?.Thum.forEach((element) => {
        allParams.push({
          label: element,
          value: element,
        });
      });

      this.setState({
        selected: allParams,
      });
    }
  }

  setSelected = (params) => {
    //console.log('params', params);

    this.setState({ selected: params }, () => {
      //console.log(this.state);

      let allParams = [];

      params.forEach((element) => {
        allParams.push(element.value);
      });

      this.props.handleChangeThum(allParams);
    });
  };

  render() {
    //console.log('this.props.options', this.props.options);

    /* const options = [
            { label: "Grapes 🍇", value: "grapes" },
            { label: "Mango 🥭", value: "mango" },
            { label: "Strawberry 🍓", value: "strawberry", disabled: true },
          ]; */

    let text = {
      allItemsAreSelected: "נבחר הכל",
      clearSearch: "Clear Search",
      noOptions: "אין אופציות",
      search: "חיפוש",
      selectAll: "בחרו הכל",
      selectAllFiltered: "Select All (Filtered)",
      selectSomeItems: "בחרו תחום",
    };

    return (
      <MultiSelect
        className={this.props.disabled ? "multiSelect disabled" : "multiSelect"}
        disabled={this.props.disabled}
        options={this.props.options}
        value={this.state.selected}
        onChange={this.setSelected}
        hasSelectAll={false}
        disableSearch={true}
        overrideStrings={text}

        /* value={[{label : 'בחרו תחום...', value : 'בחרו תחום...' }]} */

        /* labelledBy="בחירת תחומים" */
      />
    );
  }
}
