import React, { Component } from "react";
import Pages from "./Pages";
import PagesRegister from "./PagesRegister";
import PagesUserConsole from "./PagesUserConsole";
import PagesMekomotSherut from "./PagesMekomotSherut";

import getDataFromApi from "../../Components/-Helpers-/api/getDataFromApi";

export default class SiteConnector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      userCredentialsResp: false,
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length > 0
    ) {
      let user = JSON.parse(localStorage.getItem("userData"));

      let sendObj = {
        idno: user.IDNO,
        SessionKey: user.SessionKey,
      };

      this.sendtoAdmin(
        "checkActiveSession",
        "Register",
        sendObj,
        "userCredentialsResp",
        "all"
      );
    }
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) => {
      if (getData.error) {
        localStorage.setItem("userData", "");
        window.open("/login", "_self");
      }
    });
  };

  render() {
    //console.log(this.props.page);

    if (
      this.props.page === "register" ||
      this.props.page === "login" ||
      this.props.page === "reSendPass" ||
      this.props.page === "loginQueryUrl"
    ) {
      return <PagesRegister {...this.props} />;
    } else if (this.props.page === "userConsolePages") {
      return <PagesUserConsole {...this.props} />;
    } else if (this.props.page === "mekomotSherutPages") {
      return <PagesMekomotSherut {...this.props} />;
    } else {
      return <Pages {...this.props} />;
    }
  }
}
