import { RestUrls } from "../../../Components/-Helpers-/config";
import { toast } from "react-toastify";
import axios from "axios";
import { map } from "lodash";

export function getFromApiSherutLeumi(
  url,
  sendObj,
  setLoading,
  setResponseData
) {
  setLoading(true);

  const json = JSON.stringify(sendObj);

  console.log("check the URL:", RestUrls.sherutLeumiApi + url);

  axios
    .post(RestUrls.sherutLeumiApi + url, json, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      setLoading(false);
      //console.log(res);
      setResponseData(res);
      console.log("response from api:::::", res);
    })

    .catch((error) => {
      setLoading(false);
      setResponseData({});
      toast.error("שגיאה", {
        toastId: "error",
      });
      console.log(error);
    });
  /* 
        .finally(() => {
            setLoading(false);
        }) */
}

//NOT IN USE - OLD OLD OLD
export function sendFormToClientApi(
  sendData,
  setLoading,
  clearData,
  setShowThanksModal = false
) {
  //console.log(sendData);

  //sendData.formId = '10';

  const formData = new FormData();

  console.log("64 > ", sendData.file64Data);

  const file = new File(
    [sendData.file64Data],
    "foo." + sendData.extentionFile,
    {
      type: sendData.typeFile,
    }
  );

  console.log("file > ", file);
  //return true;

  formData.append("file", file);

  const sendObj = {
    SessionKey: sendData.SessionKey,
    IDNumber: sendData.IDNumber,
    FormID: sendData.formId,
  };

  if (sendData.formId === "10") {
    //console.log(sendData);
    //const datesData = getDatesData(sendData.dates);
    sendObj.SikDate = sendData.dates.SikDate;
    sendObj.SikDays = sendData.dates.SikDays;

    //SikDate: sikDate,
    //SikDays: calcSickDays

    setLoading(false);

    //console.log(sendObj);
    //return true;
  }

  console.log("sendObj > ", sendObj);

  formData.append("jsonData", JSON.stringify(sendObj));

  fetch(RestUrls.sherutLeumiApi + "v1/volunteer/forms/send", {
    method: "post",
    body: formData,
  }).then(
    (res) => {
      setLoading(false);
      clearData();

      if (res?.statusText === "OK") {
        //toast.success('המסמך עלה בהצלחה');

        if (setShowThanksModal) {
          setShowThanksModal(true);
        }
      } else {
        toast.error("שגיאה בהעלאת הקובץ");
      }

      console.log(res);
    },
    (err) => {
      setLoading(false);
      clearData();
      toast.error("שגיאה בהעלאת הקובץ");
      console.erorr("!!!", err);
    }
  );
}

export function sendFileFormToClientApi(
  sendData,
  setLoading,
  clearData,
  setShowThanksModal = false
) {
  setLoading(true);

  const formData = new FormData();
  formData.append("file", sendData.file);

  const sendObj = {
    SessionKey: sendData.SessionKey,
    IDNumber: sendData.IDNumber,
    FormID: sendData.formId,
  };

  if (sendData.formId === "10") {
    sendObj.SikDate = sendData.dates.SikDate;
    sendObj.SikDays = sendData.dates.SikDays;
  }

  //setLoading(false);
  //console.log('sendObj >',sendObj);
  //console.log('file > ', sendData.file);
  //return true;

  formData.append("jsonData", JSON.stringify(sendObj));

  fetch(RestUrls.sherutLeumiApi + "v1/volunteer/forms/send", {
    method: "post",
    body: formData,
  }).then(
    (res) => {
      setLoading(false);
      clearData();

      if (res?.statusText === "OK") {
        //toast.success('המסמך עלה בהצלחה');

        if (setShowThanksModal) {
          setShowThanksModal(true);
        }
      } else {
        toast.error("שגיאה בהעלאת הקובץ");
      }

      //console.log(res);
    },
    (err) => {
      setLoading(false);
      clearData();
      toast.error("שגיאה בהעלאת הקובץ");
      console.erorr("!!!", err);
    }
  );
}

export async function sendReadAndSignToClientApi(
  sendData,
  setLoading,
  clearData,
  showStep2Modal
) {
  const sendObj = {
    SessionKey: sendData.SessionKey,
    IDNumber: sendData.IDNumber,
    FormID: sendData.formId,
  };

  //console.log(sendObj);

  const API = RestUrls.sherutLeumiApi + "v1/volunteer/forms/getcode";

  try {
    const res = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(sendObj),
    });

    const data = await res.json();
    //console.log(data);

    if (data?.Result === "Success" && data?.PasswordStatus === "Valid") {
      setLoading(false);
      clearData();
      showStep2Modal();
    } else {
      setLoading(false);
      toast.error("שגיאה");
    }
  } catch (e) {
    setLoading(false);
    toast.error("שגיאה");
    console.log(e);
  }
}

export async function sendReadAndSignToClientApiStep2(
  sendData,
  setLoading,
  setEnterSMSCodeModal
) {
  const sendObj = {
    SessionKey: sendData.SessionKey,
    IDNumber: sendData.IDNumber,
    FormID: sendData.formId,
    SignCode: sendData.smsCodeInput,
  };

  const API = RestUrls.sherutLeumiApi + "v1/volunteer/forms/sign";

  //console.log(sendObj);

  try {
    const res = await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(sendObj),
    });

    const data = await res.json();
    //console.log(data);

    if (data?.Result === "Success" && data?.PasswordStatus === "Valid") {
      setLoading(false);
      toast.success("המסמך נחתם בהצלחה");
      setEnterSMSCodeModal(false);
    } else {
      setLoading(false);
      toast.error("שגיאה");
    }
  } catch (e) {
    setLoading(false);
    toast.error("שגיאה");
    //console.log(e);
  }
}

export function objectsMyforms(forms) {
  let objReturn = {};
  map(forms, (item) => {
    objReturn["FormID" + item.FormID] = item;
  });

  return objReturn;
}

export function checkDatesAPI(datesInput, setDatesOk, setLoading) {
  const fd = new FormData();

  fd.append("StartDate", datesInput.StartDate);
  fd.append("EndDate", datesInput.EndDate);

  axios
    .post(RestUrls.baseApiUrl + "/documents/getDates", fd, {
      //onUploadProgress: progressEvent => {setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100 ));}
    })
    .then((res) => {
      //console.log(res.data);

      if (res?.data) {
        setDatesOk(res?.data);
      } else {
        toast.error("שגיאה");
      }

      //console.log(res.data);
    })

    .catch((error) => {
      setLoading(false);
      toast.error("שגיאה");
      console.log(error);
    });
}

export const filesTexts = {
  tz: {
    title: "צילום ת.ז + ספח",
    filesType: "(jpg,png,pdf)",
    startText:
      "המסמך יאושר אם יכיל את תעודת הזהות והספח יחד, כאשר הספח פתוח במלואו",
    thankText: 'צילום ת"ז + ספח נשלח בהצלחה',
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב",
  },

  bank: {
    title: "אישור ניהול חשבון בנק",
    filesType: "(jpg,png,pdf)",
    startText:
      "המסמך יאושר רק אם יכיל את כל הפרטים: שמך המלא | שם הבנק | מספר הסניף | מספר החשבון",
    thankText: "אישור ניהול חשבון בנק נשלח בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  facePicture: {
    title: "תמונת פנים",
    filesType: "(jpg,png,pdf)",
    startText:
      "התמונה תאושר רק אם תכיל פנים ברורות, על רקע בהיר, ללא אובייקטים נוספים בתמונה. (תמונת פספורט) *התמונה תופיע גם על גבי כרטיס המתנדב/ת",
    thankText: "תמונת הפנים נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  medicalCertificate: {
    title: "אישור רפואי",
    filesType: "(jpg,png,pdf)",
    startText:
      "אישור רפואי | אישור בריאות יאושר רק אם נחתם על ידי הרופא/ה המטפל/ת",
    thankText: "אישור רפואי נשלח בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  ishurMahala: {
    title: "אישורי מחלה",
    filesType: "(jpg,png,pdf)",
    startText: "בחירת תאריך אישור מחלה",
    thankText: "אישור המחלה נשלח בהצלחה",
    thankpText:
      "יעודכן ב-2 ימי העבודה הקרובים עבור כל אחד מהימים באישור אין צורך לשלוח לכל יום בנפרד",
  },

  militar: {
    title: "פטור מהצבא",
    filesType: "(jpg,png,pdf)",
    startText:
      'המסמכים היחידים שיאושרו הם: תעודת פטור ומכתב שחרור עם חותמת צה"ל. לא יאושרו: הצהרה בבית דין | מכתב נלווה לתעודת פטור | הודעה על מצב רפואי | הודעה למלש"ב',
    thankText: "אישור רפואי נשלח בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  klali: {
    title: "מסמך כללי",
    filesType: "(jpg,png,pdf)",
    startText: "לחצו על הכפתור לבחירת הקובץ",
    thankText: "מסמך כללי נשלח בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  diraProblem: {
    title: "דיווח תקלה בדירה",
    btnName: "מעבר לאתר",
    filesType: "",
    startText: "בלחיצה על הכפתור נעביר אותך לאתר חיצוני.",
    startTextP: "",
  },

  custodian: {
    title: "אישור אפוטרופוס",
    filesType: "(jpg,png,pdf)",
    startText:
      "המסמך יאושר אם יכיל חתימות ידניות של זוג ההורים. *במקרים בהם לא ניתן להשיג שתי חתימות, יש לפנות לרכז/ת",
    thankText: "אישור אפוטרופוס נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId16: {
    title: "ועדה רפואית", //בקשה להגשה ל
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "בקשה להגשה לועדה רפואית נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId17: {
    title: "סיכום רפואי לועדה רפואית",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "סיכום רפואי לועדה רפואית נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId18: {
    title: "אישור עבודה",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "אישור עבודה נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId19: {
    title: "אישור לימודים",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "אישור לימודים נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId20: {
    title: "מוכרות במנהל מוגבלויות",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "א.מוכרות במנהל מוגבלויות נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId21: {
    title: "אבחון פסיכולוגי",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "אבחון פסיכולוגי נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId22: {
    title: "א.רפואי",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "א.רפואי נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId23: {
    title: "קצבת ביטוח לאומי",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "קצבת ביטוח לאומי נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId24: {
    title: "ועדת השמה",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "ועדת השמה נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId25: {
    title: "דוח תפקודי",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "דוח תפקודי נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId26: {
    title: "א.מוכרות ברווחה",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "א.מוכרות ברווחה נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId27: {
    title: 'דו"ח סוציאלי',
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: 'דו"ח סוציאלי נשלחה בהצלחה',
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  formId28: {
    title: "תסקיר קצין מבחן",
    filesType: "(jpg,png,pdf)",
    startText: "יש לבחור קובץ",
    thankText: "תסקיר קצין מבחן נשלחה בהצלחה",
    thankpText: "יעודכן ב-2 ימי העבודה הקרובים, אין צורך לשלוח שוב.",
  },

  //external Link
  question: {
    title: "שאלון הכוון",
    btnName: "מעבר לשאלון",
    filesType: "",
    startText:
      "בלחיצה על הכפתור נעביר אותך לשאלון באתר הרשות לשירות לאומי אזרחי.",
    startTextP: "שאלון שנשלח בהצלחה יעודכן ב-2 ימי העבודה הקרובים.",
  },

  //dira question 71
  dira: {
    title: "דיווח תקלה בדירה",
    btnName: "דיווח תקלה בדירה",
    filesType: "",
    startText: "בלחיצה על הכפתור תועבר לטופס מילוי התקלה בדירה.",
    startTextP: "שאלון שנשלח בהצלחה יעודכן ב-2 ימי העבודה הקרובים.",
  },

  //OpenPDFfiles
  link101: {
    title: "טופס 101",
    btnName: "מעבר לטופס",
    //url : 'https://sherut-leumi.wdev.co.il/api/assets/files/tofes-101.pdf',
    formId: "101",
  },

  //OpenPDFfiles
  link102: {
    title: "אישור תקופת שירות",
    btnName: "מעבר לטופס",
    //url : '',
    formId: "102",
  },

  // אישור הצבה
  link103: {
    title: "אישור הצבה",
    btnName: "מעבר לטופס",
    //url : '',
    formId: "103",
  },

  //דוח תקבולים
  link104: {
    title: "דוח תקבולים",
    btnName: "מעבר לטופס",
    //url : '',
    formId: "104",
  },

  linkLimudim: {
    title: "אישור לימודים",
    btnName: "מעבר לטופס",
    //url : 'https://www.sherut-leumi.co.il/datiot/album/FRM%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%90%D7%99%D7%A9%D7%95%D7%A8_%D7%9C%D7%99%D7%9E%D7%95%D7%93%D7%99%D7%9D.pdf',
    formId: "119",
  },

  linkAvoda: {
    title: "אישור עבודה",
    btnName: "מעבר לטופס",
    //url : 'https://www.sherut-leumi.co.il/datiot/album/FRM%D7%90%D7%99%D7%A9%D7%95%D7%A8_%D7%A2%D7%91%D7%95%D7%93%D7%94.pdf',
    formId: "118",
  },

  //ReadAndSign
  generalCharge: {
    title: "הרשאת חיוב כללי",
  },

  gius: {
    title: "הצהרת פטור מגיוס",
  },

  ipuiCoach: {
    title: "יפוי כח",
  },

  takanon: {
    title: "תקנון",
  },

  dira: {
    title: "הסכם דירה",
  },
};
