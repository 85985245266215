import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; //npm install react-router-dom

import MekomotSherutIndex from '../../Pages/MekomotSherut/MekomotSherutIndex'


export default class PagesMekomotSherut extends Component {
    render() {
        return (
            <React.Fragment>

          <Router>

            <Switch>

                {/* MORE PAGES HERE!! */}
                <Route path="/" component={() =>  < MekomotSherutIndex {...this.props} page='sherutPlacesIndex' /> } />

            </Switch>

          </Router>
          
      </React.Fragment>
        )
    }
}
